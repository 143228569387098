import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LtIcon } from '../../../../components/Icon';
import { Tooltip } from '../../../../components/Tooltip';
import { MEDIA_QUERIES } from '../../../../constants';

type EstimatedProgressProps = {
  shiftRight?: boolean;
};

const tooltipContent = `Your progress is based on how close you are to meeting your term. Any missed or modified payments will not be reflected in your progress and could impact your eligibility for additional funds. Reach out to your Concierge if you have any questions.`;

export default function EstimatedProgress({ shiftRight }: EstimatedProgressProps): JSX.Element {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const labelText = useMemo(
    () => (isSmallDown ? `Estimated months of progress` : `Est. Progress`),
    [isSmallDown]
  );

  return (
    <div
      className="renewals__status--ep-icon"
      style={shiftRight ? { right: '30px', left: 'auto' } : {}}
    >
      <Tooltip content={tooltipContent} offset={isSmallDown ? [60, 10] : [0, 10]} placement={'top'}>
        <span>
          <LtIcon name="Info-Icon" />
        </span>
      </Tooltip>
      <div className="renewals__status--ep-icon-label">{labelText}</div>
    </div>
  );
}
