import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import Spinner from '../../Signup/images/thankyou-spinner.png';
import './ThankYou.scss';

interface ThankYouPageProps {
  isLoading: boolean;
  isError: boolean;
}

const ThankYouSignup = ({ isLoading, isError }: ThankYouPageProps): JSX.Element => {
  const progressBarText = '100% Complete';
  const progressBarWidth = 100;
  const stepTitle = 'SMALL BUSINESS DASHBOARD SIGNUP';
  const signUpText = 'Thank you for signing up!';

  return (
    <>
      <div className="progress-block">
        <div className="progress-block__text">{progressBarText}</div>
        <ProgressBar percent={progressBarWidth} />
      </div>
      <div className="signup__title">{stepTitle}</div>
      <h1 className="signup__header">{signUpText}</h1>
      <div className="thankyou">
        {isLoading && (
          <>
            <div>
              We&#39;re creating your account and will redirect you to your dashboard shortly.
            </div>
            <br />
            <img className="thankyou__spinner-animation" src={Spinner} />
          </>
        )}
        {isError && (
          <span className="pt-5 text-center text-danger">
            We are sorry, we encountered an error while creating your account. Please try again
            later.
          </span>
        )}
      </div>
    </>
  );
};

export default ThankYouSignup;
