import { ReactNode } from 'react';
import { Navigate, To } from 'react-router-dom';
import useCheckAuthStatus from '../../hooks/useCheckAuthStatus';

interface CheckAuthProps {
  children: ReactNode;
  to?: To;
}
const RedirectOnAuth = ({ children, to = '/home' }: CheckAuthProps): JSX.Element => {
  const [isUserLoggedIn, isCheckingAuthStatus] = useCheckAuthStatus();

  if (isCheckingAuthStatus) {
    return <></>;
  }

  if (isUserLoggedIn) {
    return <Navigate to={to} />;
  }

  return <>{children}</>;
};

export default RedirectOnAuth;
