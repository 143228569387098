import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { dashboardApi } from '../../../app/services/dashboard';
import { RootState } from '../../../app/store';
import ExchangeRequest from './types/ExchangeRequest';

const exchangeRequestsAdapter = createEntityAdapter<ExchangeRequest>({
  sortComparer: (a, b) => new Date(b?.eventDate).getTime() - new Date(a?.eventDate).getTime(),
});

export type ExchangeRequestState = EntityState<ExchangeRequest>;

const reducers = {
  setExchangeRequests: (state: ExchangeRequestState, action: PayloadAction<ExchangeRequest[]>) => {
    exchangeRequestsAdapter.setAll(state, action.payload);
  },
};

export const exchangeRequestsSlice = createSlice({
  name: 'exchangeRequests',
  initialState: exchangeRequestsAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getExchangeRequests.matchFulfilled,
      reducers.setExchangeRequests
    );
  },
});

export default exchangeRequestsSlice.reducer;

// Actions
export const { setExchangeRequests } = exchangeRequestsSlice.actions;

// Selectors
const exchangeRequestsSelectors = exchangeRequestsAdapter.getSelectors<RootState>(
  (state) => state.dashboard.exchangeRequests
);

export const selectExchangeRequests = (state: RootState): ExchangeRequest[] =>
  exchangeRequestsSelectors.selectAll(state);
