import { useEffect } from 'react';
import { ModalProps } from '../components/Modal';

export default function useModalAnalytics(modalProps: ModalProps, eventPrefix: string): () => void {
  const handleModalClose = () => {
    if (modalProps.onClose instanceof Function) {
      modalProps.onClose();
    }

    window.ltanalytics.track(`${eventPrefix} Closed`);
  };

  useEffect(() => {
    if (modalProps.show === true) {
      window.ltanalytics.track(`${eventPrefix} Opened`);
    }
  }, [eventPrefix, modalProps.show]);

  return handleModalClose;
}
