import { useCallback, useEffect, useRef } from 'react';
import { useIsFirstRender } from 'usehooks-ts';

type AdditionalData = Record<string, newrelic.SimpleType>;

type ComponentLifeCycleLabel = 'mount' | 'unmount' | 'change';

type PrintConfig<T> = {
  name?: string;
  value: T | null;
  prevValue: T | null;
  type: ComponentLifeCycleLabel;
  componentName: string;
  additionalData?: AdditionalData;
};

const print = <T>({
  value,
  prevValue,
  type,
  componentName,
  name,
  additionalData,
}: PrintConfig<T>): void => {
  const title = `${componentName}:${name}`;
  const action = `on${type[0].toUpperCase()}${type.slice(1)}`;

  if (type === 'change') {
    window.newrelic.addPageAction(`${title}:${action}`, {
      ...(additionalData ?? {}),
      prevValue: JSON.stringify(prevValue, null, 2),
      value: JSON.stringify(value, null, 2),
    });
  } else {
    window.newrelic.addPageAction(`${title}:${action}`, {
      ...(additionalData ?? {}),
      value: JSON.stringify(value, null, 2),
    });
  }
};

const useOnChange = <T>(value: T, effect: (prev: T, next: T) => () => void) => {
  const latestValue = useRef(value);
  const callback = useRef(effect);

  useEffect(() => {
    const unsub = callback.current(latestValue.current, value);

    latestValue.current = value;

    return unsub;
  }, [value]);
};

/**
 * A simple hook that logs whenever a specific variable
 * changes value. Includes the previous value if logging an
 * updated value.
 * @param componentName - The name of the component this
 * hook resides in. Will be used as the title in logs.
 * @param variableName - The name of the variable we're
 * tracking.
 * @param variableValue - The value of the variable we're
 * tracking.
 * @param additionalData - Additional data to log.
 */
export default function useLogUpdate<T>(
  componentName: string,
  variableName: string,
  variableValue: T,
  additionalData?: AdditionalData
): void {
  const isFirstRender = useIsFirstRender();
  const callback = useCallback(
    (prev, next) => {
      const props = {
        additionalData,
        componentName,
        prevValue: prev,
        value: next,
        name: variableName,
      };

      if (isFirstRender) {
        print({
          ...props,
          type: 'mount',
        });
      } else {
        print({
          ...props,
          type: 'change',
        });
      }

      return () => {
        print({
          ...props,
          type: 'unmount',
        });
      };
    },
    [componentName, isFirstRender, variableName, additionalData]
  );

  useOnChange(variableValue, callback);
}
