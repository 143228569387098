import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

export interface FlexProps {
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  children: ReactNode;
  className?: string;
  container?: boolean;
  display?: 'flex' | 'block';
  flexDirection?: 'row' | 'column';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number | string;
  flex?: string;
  height?: string;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit';
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
  padding?: string;
  style?: CSSProperties;
  width?: string;
}

const Flex = ({
  alignItems,
  className,
  children,
  display,
  flex,
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  flexWrap,
  height,
  justifyContent,
  margin,
  maxHeight,
  maxWidth,
  padding,
  style,
  width,
}: FlexProps): JSX.Element => (
  <div
    className={classNames('flex', className)}
    style={{
      ...(alignItems && { alignItems }),
      ...(display && { display }),
      ...(flex && { flex }),
      ...(flexBasis && { flexBasis }),
      ...(flexDirection && { flexDirection }),
      ...(flexGrow && { flexGrow }),
      ...(flexShrink && { flexShrink }),
      ...(flexWrap && { flexWrap }),
      ...(height && { height }),
      ...(justifyContent && { justifyContent }),
      ...(margin && { margin }),
      ...(maxHeight && { maxHeight }),
      ...(maxWidth && { maxWidth }),
      ...(padding && { padding }),
      ...(width && { width }),
      ...style,
    }}
  >
    {children}
  </div>
);

export default Flex;
