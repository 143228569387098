import classNames from 'classnames';
import Flex, { FlexProps } from './Flex';
import './FlexColumn.scss';

type ColumnRange = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ColumnSizes = 'default' | 'small' | 'medium' | 'large';

interface FlexColumnProps extends FlexProps {
  columns?: ColumnRange;
  columnsXs?: ColumnRange;
  columnsSmall?: ColumnRange;
  columnsMedium?: ColumnRange;
  columnsLarge?: ColumnRange;
  size?: ColumnSizes;
}

const FlexColumn = ({
  children,
  className,
  columns = 0,
  columnsXs = 0,
  columnsSmall = 0,
  columnsMedium = 0,
  columnsLarge = 0,
  size = 'default',
  ...otherProps
}: FlexColumnProps): JSX.Element => (
  <Flex
    className={classNames(
      'flex-column',
      { [`flex-column-${columns}`]: columns > 0 },
      { [`flex-column--xs-${columnsXs}`]: columnsXs > 0 },
      { [`flex-column--small-${columnsSmall}`]: columnsSmall > 0 },
      { [`flex-column--medium-${columnsMedium}`]: columnsMedium > 0 },
      { [`flex-column--large-${columnsLarge}`]: columnsLarge > 0 },
      {
        [`flex-column--${size}`]: size !== 'default',
      },
      className
    )}
    {...otherProps}
  >
    {children}
  </Flex>
);

export default FlexColumn;
