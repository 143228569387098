import { useMediaQuery } from 'react-responsive';
import { FlexColumn, FlexRow } from '../../../../components/Flex';
import { MEDIA_QUERIES } from '../../../../constants';
import FundedLoan from '../../../../features/History/FundedLoans/types/FundedLoan';
import { convertLoanTermToString, convertStringToCurrency, dateFormat } from '../../../../utils';
import './LoanFunded.scss';

interface LoanFundedProps {
  fundedLoan: FundedLoan;
}

const LoanFunded = ({ fundedLoan }: LoanFundedProps): JSX.Element => {
  const isXsDown = useMediaQuery({ query: MEDIA_QUERIES.xsDown });

  return (
    <>
      <div className="loan-funded">
        {fundedLoan && (
          <FlexRow key={fundedLoan?.opportunityId}>
            {!isXsDown && (
              <FlexColumn
                className="loan-funded__record"
                columns={4}
                columnsSmall={3}
                columnsXs={2}
              >
                <div className="loan-funded__record--label-name">Lender</div>
                <div className="loan-funded__record--label-val">{fundedLoan?.lender}</div>
              </FlexColumn>
            )}
            <FlexColumn className="loan-funded__record" columns={4} columnsSmall={3} columnsXs={2}>
              <div className="loan-funded__record--label-name">Loan Amount</div>
              <div className="loan-funded__record--label-val">
                {convertStringToCurrency(fundedLoan?.loanAmount)}
              </div>
            </FlexColumn>
            <FlexColumn className="loan-funded__record" columns={4} columnsSmall={3} columnsXs={2}>
              <div className="loan-funded__record--label-name">Date Funded</div>
              <div className="loan-funded__record--label-val">
                {dateFormat({ date: fundedLoan?.dateFunded, includeTime: false })}
              </div>
            </FlexColumn>
            <FlexColumn className="loan-funded__record" columns={4} columnsSmall={3} columnsXs={2}>
              <div className="loan-funded__record--label-name">Term</div>
              <div className="loan-funded__record--label-val">
                {convertLoanTermToString({ term: fundedLoan?.loanTerm })}
              </div>
            </FlexColumn>
          </FlexRow>
        )}
        <FlexRow>
          <FlexColumn className="loan-funded__content" columns={12}>
            {!isXsDown && <div className="loan-funded__content--label">LOAN FUNDED</div>}
            <div className="loan-funded__content--text">
              Congratulations on securing funding for your business!
            </div>
          </FlexColumn>
        </FlexRow>
      </div>
    </>
  );
};

export default LoanFunded;
