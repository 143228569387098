import { useEffect } from 'react';
import { useIsFirstRender } from 'usehooks-ts';

/**
 * A simple hook that logs when a compenent mounts, and
 * unmounts. If the second parameter, `additionalData` is
 * used, will also log updates to that data, in addition to
 * sending it along with the mount and unmount events.
 * @param componentName - The name of the component this hook
 * resides in. Will be used as the title in logs.
 * @param additionalData - Additional data to log.
 */
export default function useLogLifecycle(
  componentName: string,
  additionalData?: Record<string, newrelic.SimpleType>
): void {
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    const pageAction = isFirstRender ? `${componentName} mounted` : `${componentName} updated`;

    window.newrelic.addPageAction(pageAction, additionalData);

    return () => {
      window.newrelic.addPageAction(`${componentName} unmounted`, additionalData);
    };
  }, [additionalData, componentName, isFirstRender]);
}
