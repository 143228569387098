import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../../../components/Button';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { LtIcon } from '../../../components/Icon';
import { InputGroup, InputLabel, TextInput } from '../../../components/Input';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { useLTAnalytics, useToggle } from '../../../hooks';
import './PasswordSignupForm.scss';

interface PasswordStepProps {
  stepTitle: string;
  signUpText: string;
  progressBarText: string;
  progressBarWidth: number;
  signUpSubTitle: string;
  onSubmit?: (password: string) => void;
  onStepLoad: () => void;
}
interface PasswordStepInput {
  password: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .label('Account Password')
    .required('Please enter account password.')
    .min(8)
    .max(20)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
      'Account Password does not match to criteria.'
    ),
  confirmPassword: yup
    .string()
    .label('Confirm Password')
    .required('Please enter confirm password.')
    .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
});

const PasswordSignupForm = ({
  stepTitle,
  signUpText,
  progressBarText,
  progressBarWidth,
  signUpSubTitle,
  onSubmit = () => undefined,
  onStepLoad = () => undefined,
}: PasswordStepProps): JSX.Element => {
  const [showPassword, toggleShowPassword] = useToggle();
  const [showConfirmPassword, toggleShowConfirmPassword] = useToggle();
  const [progressText, setProgressText] = useState('Weak');
  const [isNumberMatch, setIsNumberMatch] = useState(false);
  const [isCapitalMatch, setIsCapitalMatch] = useState(false);
  const [isLowercaseMatch, setIsLowercaseMatch] = useState(false);
  const [isLengthCharMatch, setisLengthCharMatch] = useState(false);
  const analyticsSignup = useLTAnalytics('Next Step-Create Your Account');
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<PasswordStepInput>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = handleSubmit(({ password }: PasswordStepInput) => {
    onSubmit(password);
  });

  const handlePasswordCriteriaMatch = (value?: string): void => {
    hasPasswordCriteriaMatch(value);
  };

  const hasPasswordCriteriaMatch = (valueOfInput?: string): boolean => {
    let valid = false;
    let capitalFlag = false;
    let lowerCaseFlag = false;
    let numberFlag = false;
    let specialCharFlag = false;
    let lengthOfAccountPassword = 0;

    numberFlag = hasNumber(valueOfInput);
    capitalFlag = hasCapitalCase(valueOfInput);
    lowerCaseFlag = hasLowerCase(valueOfInput);
    lengthOfAccountPassword = valueOfInput?.length ?? 0;
    specialCharFlag = hasSpecialChar(valueOfInput);

    if (numberFlag) {
      setIsNumberMatch(true);
    } else {
      setIsNumberMatch(false);
    }

    if (capitalFlag) {
      setIsCapitalMatch(true);
    } else {
      setIsCapitalMatch(false);
    }

    if (lowerCaseFlag) {
      setIsLowercaseMatch(true);
    } else {
      setIsLowercaseMatch(false);
    }

    if (lengthOfAccountPassword >= 8) {
      setisLengthCharMatch(true);
    } else {
      setisLengthCharMatch(false);
    }

    if (lengthOfAccountPassword < 8) {
      setProgressText('Weak');
    } else if (
      numberFlag &&
      capitalFlag &&
      lowerCaseFlag &&
      specialCharFlag &&
      lengthOfAccountPassword >= 8
    ) {
      setProgressText('Strong');
    } else if (numberFlag && capitalFlag && lowerCaseFlag && lengthOfAccountPassword >= 8) {
      setProgressText('Medium');
    }

    if (numberFlag && capitalFlag && lowerCaseFlag && lengthOfAccountPassword >= 8) {
      valid = true;
    }

    return valid;
  };

  const hasNumber = (value?: string) => {
    if (value) return /[0-9]/.test(value);
    else return false;
  };

  const hasLowerCase = (value?: string) => {
    if (value) return /[a-z]/.test(value);
    else return false;
  };

  const hasCapitalCase = (value?: string) => {
    if (value) return /[A-Z]/.test(value);
    else return false;
  };

  const hasSpecialChar = (value?: string) => {
    if (value) return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);
    else return false;
  };
  useEffect(() => {
    onStepLoad();
  }, [onStepLoad]);

  return (
    <>
      <div className="progress-block">
        <div className="progress-block__text">{progressBarText}</div>
        <ProgressBar percent={progressBarWidth} />
      </div>
      <div className="signup__title">{stepTitle}</div>
      <h1 className="signup__header">{signUpText}</h1>
      <div className="signup__subtitle"> {signUpSubTitle}</div>
      <div className="password-signup-form">
        <form onSubmit={handleFormSubmit}>
          <FlexRow>
            <FlexColumn columnsMedium={12}>
              <InputGroup error={errors.password?.message}>
                <InputLabel htmlFor="password" size={'large'}>
                  Account Password
                </InputLabel>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange, ref, ...fieldProps } }) => (
                    <>
                      <TextInput
                        {...fieldProps}
                        ref={ref}
                        heightSize={'large'}
                        inputType={showPassword ? 'text' : 'password'}
                        name={'password'}
                        placeholder={'Password'}
                        onChange={(value: string) => {
                          handlePasswordCriteriaMatch(value);
                          onChange(value);
                        }}
                      />
                      <span className="show-text" onClick={() => toggleShowPassword()}>
                        {showPassword ? 'HIDE' : 'SHOW'}
                      </span>
                    </>
                  )}
                />
              </InputGroup>
              <div className="error-progress-block">
                <div className="error-progress-block__box">
                  <div
                    className={`error-progress-block__box--bar  ${
                      progressText === 'Weak'
                        ? 'error-progress-block__box--red-bar'
                        : progressText === 'Medium'
                        ? 'error-progress-block__box--blue-bar'
                        : progressText === 'Strong'
                        ? 'error-progress-block__box--green-bar'
                        : ''
                    }`}
                  ></div>
                  <div
                    className={`error-progress-block__box--bar  ${
                      progressText === 'Medium'
                        ? 'error-progress-block__box--blue-bar'
                        : progressText === 'Strong'
                        ? 'error-progress-block__box--green-bar'
                        : ''
                    }`}
                  ></div>
                  <div
                    className={`error-progress-block__box--bar  ${
                      progressText === 'Strong' ? 'error-progress-block__box--green-bar' : ''
                    }`}
                  ></div>
                </div>
                <div className="error-progress-block__box">
                  <span
                    className={`${
                      progressText === 'Weak'
                        ? 'error-progress-block__box--red-text'
                        : progressText === 'Medium'
                        ? 'error-progress-block__box--blue-text'
                        : progressText === 'Strong'
                        ? 'error-progress-block__box--green-text'
                        : ''
                    }`}
                  >
                    {progressText}
                  </span>
                </div>
                <FlexRow className="error-progress-block__list">
                  <FlexColumn
                    className="error-progress-block__list--item"
                    columnsSmall={5}
                    columnsXs={12}
                  >
                    <LtIcon
                      className="error-progress-block__bl-lt-icon"
                      name={isNumberMatch ? 'Check' : 'Ex'}
                    />
                    At least one number
                  </FlexColumn>
                  <FlexColumn
                    className="error-progress-block__list--item"
                    columnsSmall={5}
                    columnsXs={12}
                  >
                    <LtIcon
                      className="error-progress-block__bl-lt-icon"
                      name={isCapitalMatch ? 'Check' : 'Ex'}
                    />
                    At least one uppercase letter
                  </FlexColumn>
                  <FlexColumn
                    className="error-progress-block__list--item"
                    columnsSmall={5}
                    columnsXs={12}
                  >
                    <LtIcon
                      className="error-progress-block__bl-lt-icon"
                      name={isLowercaseMatch ? 'Check' : 'Ex'}
                    />
                    At least one lowercase letter
                  </FlexColumn>
                  <FlexColumn
                    className="error-progress-block__list--item"
                    columnsSmall={5}
                    columnsXs={12}
                  >
                    <LtIcon
                      className="error-progress-block__bl-lt-icon"
                      name={isLengthCharMatch ? 'Check' : 'Ex'}
                    />
                    At least 8 characters
                  </FlexColumn>
                </FlexRow>
              </div>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={12}>
              <InputGroup error={errors.confirmPassword?.message}>
                <InputLabel htmlFor="confirmPassword" size={'large'}>
                  Confirm Password
                </InputLabel>
                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <>
                      <TextInput
                        {...fieldProps}
                        ref={ref}
                        heightSize={'large'}
                        inputType={showConfirmPassword ? 'text' : 'password'}
                        name={'confirmPassword'}
                        placeholder={'Confirm Password'}
                      />
                      <span className="show-text" onClick={() => toggleShowConfirmPassword()}>
                        {showConfirmPassword ? 'HIDE' : 'SHOW'}
                      </span>
                    </>
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <Button
            block={true}
            className="password-step-form__next"
            color={'blue'}
            size={'large'}
            {...analyticsSignup}
          >
            Create Your Account
          </Button>
          <div className="signup__footer">
            Already have an account? <a href="/app/dashboard">Sign in here.</a>
          </div>
        </form>
      </div>
    </>
  );
};

export default PasswordSignupForm;
