const SIZE_DIMENSIONS = {
  small: {
    height: '50px',
    width: '50px',
  },
  medium: {
    height: '75px',
    width: '75px',
  },
  large: {
    height: '100px',
    width: '100px',
  },
};

type LoaderSizes = 'small' | 'medium' | 'large';

interface LoaderProps {
  centered?: boolean;
  height?: string;
  size?: LoaderSizes;
  width?: string;
}
const Loader = ({ centered = false, height, size = 'medium', width }: LoaderProps): JSX.Element => {
  const dimensions = SIZE_DIMENSIONS[size];

  width = width ?? (centered ? '100%' : dimensions.width);
  height = height ?? dimensions.height;

  return (
    <div className="loader">
      <svg
        baseProfile="basic"
        height={height}
        id="loader-leaf"
        imageRendering="auto"
        version="1.1"
        viewBox="0 0 207 209"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="Gradient_1"
            spreadMethod="pad"
            x1="5.662"
            x2="198.837"
            y1="5.849"
            y2="201.55"
          >
            <stop offset="5.098%" stopColor="#078181" />
            <stop offset="21.176%" stopColor="#07997D" />
            <stop offset="41.176%" stopColor="#08AF7A" />
            <stop offset="60%" stopColor="#08BC78" />
            <stop offset="76.862%" stopColor="#08C177" />
          </linearGradient>
        </defs>
        <g id="Scene-1" overflow="visible">
          <g mask="url(#mask_)">
            <g id="Logo" transform="translate(3.45 3.05)">
              <animate
                attributeName="opacity"
                dur="1.333s"
                keyTimes="0;.81699;.984;1"
                repeatCount="indefinite"
                values="1;1;0;0"
              />
              <path
                d="M194.25 35.45Q197.75 9.5 200 1.2 200.1 .55 199.85 .25 199.6 -.1 199.15 0 174.35 3 149.7 6.6 101.65 13.55 70.35 21.3 24.2 32.8 8.35 69.65 2.7 82.7 .9 99.1 0 107.55 0 122.25L0 202Q0 202.7 .55 202.9 1.15 203.1 1.55 202.45 32.5 161.35 70.75 123.35 89.85 104.4 102.75 93.6 103 93.35 102.5 93.45 96.15 94.95 81.75 100.75 61.2 108.95 41 119.9L41 109.8Q40.85 82.15 56.3 67.85 67.45 57.5 93.25 50.35 107.8 46.3 135.35 42.8 149.15 41.1 160 40.15 159.1 46.5 157.65 59.25 154.7 84.8 151.8 116.9 149.45 143.3 130.15 154.1 114.25 163 82.1 163L60.25 163Q49.25 178.65 43.8 192.4 41.05 199.3 40.5 203.05L83.15 203.05Q139.9 203.05 163.75 178.6 174.5 167.65 180 150.65 184.75 136.1 186.35 114.55 189.55 70.6 194.25 35.45Z"
                fill="url(#Gradient_1)"
                stroke="none"
              />
            </g>
          </g>
          <mask id="mask_">
            <g>
              <g display="none">
                <g>
                  <g id="Layer37_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -.9 209 2.3 206.1Q2.390 206.251 2.5 206.4 3.35 207.55 4.55 207.55 5.75 207.55 6.6 206.4 7.45 205.25 7.45 203.6 7.45 203.209 7.4 202.85 9.167 202.797 10.5 203.05 11.886 203.307 11.85 201.6 11.811 199.891 10.95 198.15L44.75 167.35 6 209.05 206.5 209.05 206.5 .05M95.5 87.05Q100.741 87.541 104.6 91.4 106.168 92.968 107.15 94.75 103.008 91.834 82.15 101.1L70 106.55Q71.075 103.724 73.4 101.4 76.166 98.633 79.65 97.6 80.646 94.153 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05M66.65 108.05L57.8 112Q61.589 108.468 66.65 108.05M78.6 133.6Q75.992 136.207 72.75 137.25L83.95 126.95Q86.068 124.724 96 116.15 108.101 102.241 108.2 97.1 109 99.386 109 102 109 108.2 104.6 112.6 101.845 115.354 98.35 116.35 97.356 119.843 94.6 122.6 90.75 126.45 85.5 126.95 85.265 126.965 85 126.95 80.334 131.865 78.6 133.6Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.6;1"
                  repeatCount="indefinite"
                  values="none;inline;inline"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer36_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -.9 209 2.3 206.1Q2.390 206.251 2.5 206.4 3.35 207.55 4.55 207.55 5.75 207.55 6.6 206.4 7.45 205.25 7.45 203.6 7.45 203.209 7.4 202.85 9.167 202.797 10.5 203.05 11.886 203.307 11.85 201.6 11.811 199.891 10.95 198.15L44.75 167.35 6 209.05 206.5 209.05 206.5 .05M95.5 87.05Q100.741 87.541 104.6 91.4 109 95.8 109 102 109 108.2 104.6 112.6 101.845 115.354 98.35 116.35 97.356 119.843 94.6 122.6 90.75 126.45 85.5 126.95 85.265 126.965 85 126.95 80.334 131.865 78.6 133.6 75.992 136.207 72.75 137.25L83.95 126.95Q86.068 124.724 96 116.15L82.15 101.1 70 106.55Q71.075 103.724 73.4 101.4 76.166 98.633 79.65 97.6 80.646 94.153 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05M66.65 108.05L57.8 112Q61.589 108.468 66.65 108.05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.583;.6;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer35_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -.9 209 2.3 206.1Q2.390 206.251 2.5 206.4 3.35 207.55 4.55 207.55 5.75 207.55 6.6 206.4 7.45 205.25 7.45 203.6 7.45 203.209 7.4 202.85 9.167 202.797 10.5 203.05 11.886 203.307 11.85 201.6 11.811 199.891 10.95 198.15L44.75 167.35 6 209.05 206.5 209.05 206.5 .05M95.5 87.05Q100.741 87.541 104.6 91.4 109 95.8 109 102 109 108.2 104.6 112.6 101.845 115.354 98.35 116.35 97.356 119.843 94.6 122.6 90.75 126.45 85.5 126.95 84.75 127 84 127 83.226 127 82.5 126.95 81.528 130.671 78.6 133.6 75.992 136.207 72.75 137.25L82.5 126.95 64.15 110.8 57.8 112Q62.088 108 68 108 68.75 108 69.5 108.1 70.451 104.348 73.4 101.4 76.166 98.633 79.65 97.6 80.646 94.153 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.567;.583;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer34_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -.9 209 2.3 206.1Q2.390 206.251 2.5 206.4 3.35 207.55 4.55 207.55 5.75 207.55 6.6 206.4 7.45 205.25 7.45 203.6 7.45 203.209 7.4 202.85 9.167 202.797 10.5 203.05 11.886 203.307 11.85 201.6 11.811 199.891 10.95 198.15L44.75 167.35 6 209.05 206.5 209.05 206.5 .05M95.5 87.05Q100.741 87.541 104.6 91.4 109 95.8 109 102 109 108.2 104.6 112.6 101.845 115.354 98.35 116.35 97.356 119.843 94.6 122.6 90.75 126.45 85.5 126.95 84.75 127 84 127 83.226 127 82.5 126.95 81.528 130.671 78.6 133.6 75.992 136.207 72.75 137.25L67.35 143.55 54.55 112 57.8 112Q62.088 108 68 108 68.75 108 69.5 108.1 70.451 104.348 73.4 101.4 76.166 98.633 79.65 97.6 80.646 94.153 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.55;.567;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer33_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -.9 209 2.3 206.1Q2.390 206.251 2.5 206.4 3.35 207.55 4.55 207.55 5.75 207.55 6.6 206.4 7.45 205.25 7.45 203.6 7.45 203.209 7.4 202.85 9.167 202.797 10.5 203.05 11.886 203.307 11.85 201.6 11.811 199.891 10.95 198.15L44.75 167.35 6 209.05 206.5 209.05 206.5 .05M95.5 87.05Q100.741 87.541 104.6 91.4 109 95.8 109 102 109 108.2 104.6 112.6 101.845 115.354 98.35 116.35 97.356 119.843 94.6 122.6 90.75 126.45 85.5 126.95 84.75 127 84 127 83.226 127 82.5 126.95 81.528 130.671 78.6 133.6 75.992 136.207 72.75 137.25L44.8 167.3 44.8 112 57.8 112Q62.088 108 68 108 68.75 108 69.5 108.1 70.451 104.348 73.4 101.4 76.166 98.633 79.65 97.6 80.646 94.153 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.533;.55;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer32_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -1 175.25 43.6 143.45 44.8 112 57.8 112Q62.088 108 68 108 68.75 108 69.5 108.1 70.451 104.348 73.4 101.4 76.166 98.633 79.65 97.6 80.646 94.153 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05 100.741 87.541 104.6 91.4 109 95.8 109 102 109 108.2 104.6 112.6 101.845 115.354 98.35 116.35 97.356 119.843 94.6 122.6 90.75 126.45 85.5 126.95 84.75 127 84 127 83.226 127 82.5 126.95 81.528 130.671 78.6 133.6 75.992 136.207 72.75 137.25L6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.517;.533;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer31_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -1 164.95 44.55 122.7 47.05 112 57.8 112Q62.088 108 68 108 68.75 108 69.5 108.1 70.451 104.348 73.4 101.4 76.166 98.633 79.65 97.6 80.646 94.153 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05 100.741 87.541 104.6 91.4 109 95.8 109 102 109 108.2 104.6 112.6 101.845 115.354 98.35 116.35 97.356 119.843 94.6 122.6 90.75 126.45 85.5 126.95 84.75 127 84 127 83.226 127 82.5 126.95 81.528 130.671 78.6 133.6 75.992 136.207 72.75 137.25L6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.5;.517;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer30_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -1 132.05 52.05 117.4 52.05 114 57.05 114 57.05 111 62 111Q62 110.503 62 110 62 108.65 62.25 107.4 62.95 102.85 66.4 99.4 70.8 95 77 95 77.75 95 78.5 95.1 79.524 95.187 80.5 95.4 81.554 93.245 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05 100.741 87.541 104.6 91.4 109 95.8 109 102 109 108.2 104.6 112.6 100.741 116.458 95.5 116.9 94.762 117 94 117 92.974 117 92 116.85 91.235 116.784 90.5 116.6 90.209 117.207 89.85 117.75L91.25 117.55 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.483;.5;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer29_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -1 104.55 79.2 104.55Q79 103.315 79 102 79 100.65 79.25 99.4 79.95 94.85 83.4 91.4 87.8 87 94 87 94.763 87 95.5 87.05 96.236 87 97 87 103.2 87 107.6 91.4 112 95.8 112 102 112 108.2 107.6 112.6 103.2 117 97 117 96.235 117 95.5 116.9 94.762 117 94 117 92.974 117 92 116.85L6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.467;.483;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer28_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -1 79.05 82.2 99.35Q82.966 94.833 86.4 91.4 90.8 87 97 87 103.2 87 107.6 91.4 112 95.8 112 102 112 108.2 107.6 112.6 103.2 117 97 117 94.610 117 92.5 116.35L6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.45;.467;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer27_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -1 53.55 75.7 91 110.05 91 110.05 107 101.2 107 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.433;.45;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer26_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 -1 27.05 79.8 87 114.05 87 114.05 106 102.15 106 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.417;.433;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer25_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 -1 .55 86.85 88 113.05 88 113.05 110 98.4 110 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.4;.417;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer24_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 34 .55 96.45 94 112.05 94 112.05 107 101.2 107 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.383;.4;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer23_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 60.5 .55 97.9 91 112.05 91 112.05 109 99.35 109 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.367;.383;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer22_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 82 .55 100.5 90 110.05 90 110.05 105 103.05 105 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.35;.367;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer21_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 103.5 .55 103.5 88 112.05 88 112.05 107 101.2 107 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.333;.35;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer20_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 130 .55 108.05 86.7 108.05 105 103.05 105 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.317;.333;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer19_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 156.5 .55 103.5 104.55 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.3;.317;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer18_0_FILL">
                    <path
                      d="M206.5 .05L206 .55 184 .55 103.5 104.55 6 209.05 206.5 209.05 206.5 .05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.283;.3;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer17_0_FILL">
                    <path
                      d="M103.5 104.55L6 209.05 206.5 209.05 206.5 .05 103.5 104.55Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.267;.283;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer16_0_FILL">
                    <path
                      d="M103.5 104.55L6 209.05 206.5 209.05 206.5 26.2 103.5 104.55Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.25;.267;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer15_0_FILL">
                    <path
                      d="M103.5 104.6L6 209.1 206.5 209.1 206.5 52.35 103.5 104.6Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.233;.25;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer14_0_FILL">
                    <path
                      d="M103.5 104.55L6 209.05 206.5 209.05 206.5 78.45 103.5 104.55Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.217;.233;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer13_0_FILL">
                    <path
                      d="M103.5 104.6L6 209.1 206.5 209.1 206.5 104.6 103.5 104.6Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.2;.217;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer12_0_FILL">
                    <path
                      d="M103.5 104.6L6 209.1 206.5 209.1 206.5 130.85 103.5 104.6Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.183;.2;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer11_0_FILL">
                    <path
                      d="M103.5 104.6L6 209.1 206.5 209.1 206.5 157.1 103.5 104.6Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.167;.183;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer10_0_FILL">
                    <path
                      d="M106.2 105.95L101.5 106.7 6 209.05 202.4 209.05 206.5 204.75 191.85 168.2 106.2 105.95Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.15;.167;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer9_0_FILL">
                    <path
                      d="M103.5 104.6L6 209.1 206.5 209.1 103.5 104.6Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.133;.15;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer8_0_FILL">
                    <path
                      d="M103.45 104.6L5.95 209.1 180.7 209.1 103.45 104.6Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.117;.133;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer7_0_FILL">
                    <path
                      d="M6 209.1L155 209.1 103.5 104.6 6 209.1Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.1;.117;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer6_0_FILL">
                    <path
                      d="M6 209.1L129.25 209.1 103.5 104.6 6 209.1Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.083;.1;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer5_0_FILL">
                    <path
                      d="M6 209.1L103.5 209.1 103.5 104.6 6 209.1Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.067;.083;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer4_0_FILL">
                    <path
                      d="M6 209.1L83.5 209.1 103.5 104.6 6 209.1Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.05;.067;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer3_0_FILL">
                    <path
                      d="M6 209.1L63.5 209.1 103.5 104.6 6 209.1Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.033;.05;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g display="none">
                <g>
                  <g id="Layer2_0_FILL">
                    <path
                      d="M6 209.1L43.5 209.1 103.5 104.6 6 209.1Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.017;.033;1"
                  repeatCount="indefinite"
                  values="none;inline;none;none"
                />
              </g>
              <g>
                <g>
                  <g id="Layer1_0_FILL">
                    <path
                      d="M6 209.05L23.5 209.05 103.5 104.55 6 209.05Z"
                      fill="#FFF"
                      fillOpacity="1"
                      stroke="none"
                    />
                  </g>
                </g>
                <animate
                  attributeName="display"
                  dur="1.333s"
                  keyTimes="0;.017;1"
                  repeatCount="indefinite"
                  values="inline;none;none"
                />
              </g>
            </g>
          </mask>
        </g>
      </svg>
    </div>
  );
};

export default Loader;
