import { ReactNode } from 'react';
import './AnimatedDots.scss';

interface AnimatedDotsProps {
  children?: ReactNode;
}

const AnimatedDots = ({ children }: AnimatedDotsProps): JSX.Element => {
  return (
    <>
      {children}
      <span className="animated-dots">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    </>
  );
};

export default AnimatedDots;
