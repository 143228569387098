// See: https://redux.js.org/tutorials/typescript-quick-start

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  app,
  auth,
  creditCards,
  dashboard,
  documents,
  history,
  insurance,
  offers,
  profile,
  userAgent,
} from './reducers';
import { borrowerPortalApi } from './services';

const store = configureStore({
  reducer: {
    app,
    auth,
    creditCards,
    insurance,
    profile,
    history,
    userAgent,
    documents,
    dashboard,
    offers,
    [borrowerPortalApi.reducerPath]: borrowerPortalApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(borrowerPortalApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
