import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from '../../../app/hooks';
import {
  useGetBusinessCreditFAQUrlQuery,
  useGetDisputesFAQUrlQuery,
} from '../../../app/services/appSetting';
import {
  useGetBusinessCreditWheelQuery,
  useGetCreditHistoryGraphQuery,
  useHasSeenUserCreditGuideQuery,
  useUpdateHasSeenGuideMutation,
} from '../../../app/services/dashboard';
import { LtIcon } from '../../../components/Icon';
import { Modal, Mode } from '../../../components/Modal';
import { Tooltip } from '../../../components/Tooltip';
import { CREDIT_REASON_CODES, MEDIA_QUERIES } from '../../../constants';
import { useLTAnalytics } from '../../../hooks';
import AtomsLeftArrowImage from '../images/atoms-left-arrow.png';
import AtomsRightArrowImage from '../images/atoms-right-arrow.png';
import Group1Image from '../images/group-1.png';
import Group2Image from '../images/group-2.png';
import Group3Image from '../images/group-3.png';
import Group4Image from '../images/group-4.png';
import ManOnCellphoneLImage from '../images/man-on-cellphone-l.png';
import ManOnCellphoneSImage from '../images/man-on-cellphone-s.png';
import BusinessCreditGraph from './BusinessCreditGraph';
import './BusinessCreditScore.scss';
import { selectBusinessCreditGraphPoints } from './businessCreditSlice';
import { selectBusinessCreditWheel } from './businessCreditWheelSlice';
import { CreditScoreGauge } from './CreditScoreGauge';
import BusinessCreditWheel from './types/BusinessCreditWheel';
import { selectBusinessCreditUserGuide } from './userGuideSlice';

interface BlurImageSectionProps {
  isSmallDown: boolean;
}
const CuriousBox: React.FC = () => {
  return (
    <div className="curious-box">
      <img alt="credit-score-loader" src="/images/home/credit-score-loader.png" />
      <div className="curious-text">Curious about your personal credit score?</div>
      <a
        className="lt-login-link"
        data-eventname="BL Portal CTA"
        data-ltanalytics-call="Log in to MyLendingTree"
        href={process.env.MYLT_BASE_URL}
        rel="noopener noreferrer"
        target="_blank"
      >
        Log in to MyLendingTree
      </a>
    </div>
  );
};
const NotScoredBox = (): JSX.Element => {
  return (
    <div className="notscored-box">
      <div className="text-danger">
        Sorry, we&#39;re unable to retrieve a business credit score at this time as Equifax does not
        yet have a credit score for your business. If your business has a Doing Business As (DBA)
        name, you can change your profile information and try again.
      </div>
      <div className="text-danger">
        <strong> Please note: </strong>This does not prevent your business from getting funding from
        our financing partners.
      </div>
      <div className="notscored-help">
        <b>
          Here&#39;s how you can improve your business&#39;s chances to have a credit file and
          business credit score:
        </b>
        <div>
          <ul className="notscored-help-list">
            <li>Register your business with your appropriate Secretary of State</li>
            <li>Have a business credit card in your business&#39;s name</li>
            <li>
              Have a mobile phone account with one of the major carriers in your business&#39;s name
            </li>
          </ul>
        </div>
      </div>
      <div className="notscored-help">
        <b>
          Where does Equifax get business-related data to build a business credit file and credit
          score?
        </b>
        <div>
          <span>
            Equifax acquires data from a variety of trusted third-party sources. It obtains
            financial payment information via direct relationships with large, regional, and local
            small business lenders in the U.S. It also gets payment performance information from a
            number of government agencies and industry suppliers providing goods and services to
            businesses on an invoice basis. Finally, Equifax compiles public record information from
            many government and legal sources.
          </span>
        </div>
      </div>
    </div>
  );
};
const EquifaxCommunicationFailedSection = (): JSX.Element => {
  return (
    <>
      <div className="text-danger pt-2 pl-2">
        Sorry, we are unable to retrieve your business credit score at this time due to an error
        with Equifax. Please check again later.
      </div>
      <div className="text-danger pt-2 pl-2">
        <strong>Please note: </strong>This does not prevent your business from getting funding from
        our financing partners.
      </div>
    </>
  );
};
const EquifaxBankruptcySection = (): JSX.Element => {
  return (
    <>
      <div className="content-title">Factors Influencing Your Score</div>
      <ul className="credit-score-factors">
        <li className="score-factors-list notscore-factors-list">
          Your score shows evidence of a Bankruptcy Filing.{' '}
          <b>This may not prevent your business from getting funding from our financing partners</b>{' '}
          if you&#39;re being proactive and have a plan in place.
        </li>
      </ul>
    </>
  );
};
const BlurImageSection = ({ isSmallDown }: BlurImageSectionProps): JSX.Element => {
  return (
    <>
      <div className="equifax-communication-fails-bg-blur-img">
        <img
          alt="equifax-communication-fails"
          className="equifax-communication-fails-bg-blur-img-desktop"
          src="/images/home/chart_blurred_l.png"
        />
        {isSmallDown && (
          <img
            alt="equifax-communication-fails"
            className="equifax-communication-fails-bg-blur-img-mobile"
            src="/images/home/chart_blurred_s.png"
          />
        )}
      </div>
      <div className="equifax-communication-fails-bg-text">
        <div>Business credit score is unavailable at this time.</div>
        <div className="pt-2">
          <strong>Please note: </strong>This does not prevent your business from getting funding
          from our financing partners.
        </div>
      </div>
    </>
  );
};
const Accordion: React.FC<{ businessCreditWheel: BusinessCreditWheel }> = ({
  businessCreditWheel,
}) => {
  const [isNoticeProblemModalOpen, setIsNoticeProblemModalOpen] = useState(false);
  const [creditScoreFactors, setCreditScoreFactors] = useState(Array<string>());
  const [showCreditScoreFactors, setShowCreditScoreFactors] = useState(false);
  const [showBankruptcy, setShowBankruptcy] = useState(false);
  const [showNotScored, setshowNotScored] = useState(false);
  const [showNoFactors, setshowNoFactors] = useState(false);
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const analyticsDisputesFAQSection = useLTAnalytics('Disputes FAQ Section');
  const analyticsEmail = useLTAnalytics('Email');
  const analyticsPhone = useLTAnalytics('Phone');
  const analyticsBusinessCreditSection = useLTAnalytics('Business Credit FAQ Section');
  const { data: businessCreditFAQData } = useGetBusinessCreditFAQUrlQuery();
  const { data: disputesFAQUrlData } = useGetDisputesFAQUrlQuery();
  useEffect(() => {
    const factors = Array<string>();
    if (businessCreditWheel.reasonCodes !== null && businessCreditWheel.reasonCodes.length > 0) {
      businessCreditWheel.reasonCodes.forEach((code) => {
        const reason = CREDIT_REASON_CODES.find((x) => x.key === code);
        if (reason) factors.push(reason.value);
      });
    }
    setCreditScoreFactors(factors);

    const bankrupt = businessCreditWheel.status === 'Success' && businessCreditWheel.score === 0;
    setShowBankruptcy(bankrupt && !businessCreditWheel.hasEquifaxCommunicationFailed);
    const notScored =
      businessCreditWheel.status === 'NotScored' ||
      businessCreditWheel.status === 'InsufficientCredit';
    setshowNotScored(notScored && !businessCreditWheel.hasEquifaxCommunicationFailed);
    setShowCreditScoreFactors(
      factors.length > 0 &&
        !businessCreditWheel.hasEquifaxCommunicationFailed &&
        !notScored &&
        !bankrupt
    );
    setshowNoFactors(
      !businessCreditWheel.hasEquifaxCommunicationFailed && !notScored && factors.length <= 0
    );
  }, [businessCreditWheel]);
  return (
    <div className="business-profile__details">
      {showBankruptcy && <EquifaxBankruptcySection />}
      {showCreditScoreFactors && (
        <>
          <div className="business-profile__content-title">Your Credit Score Factors</div>
          <ul className="business-profile__credit-factors">
            {creditScoreFactors.map((reason, i) => (
              <li key={i} className="business-profile__score-factors">
                {reason}
              </li>
            ))}
          </ul>
        </>
      )}
      {showNoFactors && (
        <>
          <span className="text-danger">
            Equifax is not able to provide your business credit score factors right now. Please
            check again later.
          </span>
        </>
      )}

      {showNotScored && <NotScoredBox />}
      {businessCreditWheel.hasEquifaxCommunicationFailed && <EquifaxCommunicationFailedSection />}
      <a
        className="business-profile__link"
        href="#"
        onClick={() => setIsNoticeProblemModalOpen(true)}
      >
        Notice a problem with your score?
      </a>
      <Modal
        center={true}
        className="business-profile__details"
        show={isNoticeProblemModalOpen}
        size={!isSmallDown ? 'x-large' : 'small'}
        title=""
        onClose={() => setIsNoticeProblemModalOpen(false)}
      >
        <div className="dispute-information">
          <div className="dispute-information__img-block">
            <img
              alt="man-on-cellphone"
              className={!isSmallDown ? 'dispute-information__small-img-block' : ''}
              src={!isSmallDown ? ManOnCellphoneLImage : ManOnCellphoneSImage}
            />
          </div>
          <div className="dispute-information__content-block">
            <h2 className="dispute-information__content-block--title">
              Dispute information on your Equifax Business credit report
            </h2>
            <p className="dispute-information__content-block--text">
              Here are some helpful links when noticing inaccurate data on your business credit
              report.
            </p>
            <div className="dispute-information__content-block--question-box">
              <div className="question-box">
                <LtIcon className="question-box__bl-lt-icon" name="BalanceScale" />
                <p className="question-box__text">
                  How can I dispute my Equifax Business Delinquency Score?
                </p>
              </div>
              <div className="question-box__answer">
                If you believe there is any inaccuracy with the report&#39;s information, please
                review the{' '}
                <a
                  href={disputesFAQUrlData?.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  {...analyticsDisputesFAQSection}
                >
                  Disputes FAQ Section
                </a>{' '}
                for detailed instructions on how to file a dispute with Equifax.
              </div>
              <div className="dispute-information__content-block--question-box">
                <div className="question-box">
                  <LtIcon className="question-box__bl-lt-icon" name="SpeakBubbles" />
                  <p className="question-box__text">Need to email or talk to someone?</p>
                </div>
                <div className="question-box__answer">
                  <a
                    href="mailto:commercialdisclosures@equifax.com"
                    rel="noopener noreferrer"
                    {...analyticsEmail}
                  >
                    commercialdisclosures@equifax.com
                  </a>{' '}
                  or call{' '}
                  <a href="tel:+800-727-8495" {...analyticsPhone}>
                    800-727-8495
                  </a>
                </div>
              </div>
              <div className="dispute-information__content-block--question-box">
                <div className="question-box">
                  <LtIcon className="question-box__bl-lt-icon" name="Document" />
                  <p className="question-box__text">Have credit report questions?</p>
                </div>
                <div className="question-box__answer">
                  Browse the Equifax{' '}
                  <a
                    className="question-box__answer--link"
                    href={businessCreditFAQData?.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    {...analyticsBusinessCreditSection}
                  >
                    Business Credit FAQ Section
                  </a>{' '}
                  or visit the business credit report product pages on our site.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export type BusinessCreditScoreProps = {
  score: number;
  status: string;
  lastUpdated: Date;
};

export const BusinessCreditScore: React.FC = () => {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const [isCreditScoresModalOpen, setIsCreditScoresModalOpen] = useState(false);
  const [isUserGuidesModalOpen, setUserGuideModalOpen] = useState(false);
  const [activeSlides, setActiveSlides] = useState(0);
  const [showBlurImage, setShowBlurImage] = useState(false);
  const [businessCreditScoreStatus, setBusinessCreditScoreStatus] = useState('');
  const [updateHasSeenGuide, { isSuccess: useGuidedUpdated }] = useUpdateHasSeenGuideMutation();
  const { isLoading: isBusinessCreditWheelFetching } = useGetBusinessCreditWheelQuery();
  const businessCreditWheel = useAppSelector(selectBusinessCreditWheel);
  const { isLoading: isLoadingHasSeenUserCreditGuide, refetch } = useHasSeenUserCreditGuideQuery();
  const seenUserCreditData = useAppSelector(selectBusinessCreditUserGuide);
  const { isLoading: isCreditHistoryGraphFetching } = useGetCreditHistoryGraphQuery();
  const creditGraphPoints = useAppSelector(selectBusinessCreditGraphPoints);

  const lastUpdated = new Date();

  const businessCreditScoreToolTipText =
    'Your Equifax business credit score predicts the likelihood your business will become more than 90 days late on repaying debt or incur a charge-off over the next 12 months. It ranges from 224 to 580. The higher the score, the lower the risk.';

  useEffect(() => {
    if (
      businessCreditWheel?.status?.toLocaleLowerCase() === 'success' &&
      !isLoadingHasSeenUserCreditGuide
    ) {
      setUserGuideModalOpen(!seenUserCreditData?.hasSeenGuide);
      const element = document.getElementById('business-credit-section');
      element &&
        window.scrollTo({
          top: element?.offsetHeight,
          behavior: 'smooth',
        });
    }
  }, [isLoadingHasSeenUserCreditGuide, seenUserCreditData, businessCreditWheel?.status]);
  useEffect(() => {
    if (useGuidedUpdated) {
      refetch();
    }
  }, [refetch, useGuidedUpdated]);
  useEffect(() => {
    let status = '';
    if (!isBusinessCreditWheelFetching && businessCreditWheel.score) {
      if (businessCreditWheel.score > 419) {
        status = 'Excellent';
      } else if (businessCreditWheel.score > 389) {
        status = 'Good';
      } else if (businessCreditWheel.score > 359) {
        status = 'Fair';
      } else {
        status = 'Poor';
      }
    }
    setBusinessCreditScoreStatus(status);
    setShowBlurImage(
      (businessCreditWheel.hasEquifaxCommunicationFailed ||
        businessCreditWheel.status === 'NotScored' ||
        businessCreditWheel.status === 'InsufficientCredit') &&
        businessCreditWheel.score === null
    );
  }, [businessCreditWheel, isBusinessCreditWheelFetching]);

  const onClickUserGuidModelClosed = () => {
    setUserGuideModalOpen(false);
    updateHasSeenGuide();
  };

  return (
    <div className="business-profile" id="business-credit-section">
      <div className="business-profile__left-content">
        <div className="business-profile__left-div">
          <div className="business-profile__content-title">
            {isCreditHistoryGraphFetching ||
              (isBusinessCreditWheelFetching && (
                <>
                  <div>Loading...</div>
                </>
              ))}
            {!isCreditHistoryGraphFetching && !isBusinessCreditWheelFetching && (
              <>
                Business Credit Score&nbsp;
                <Tooltip
                  content={businessCreditScoreToolTipText}
                  offset={isSmallDown ? [60, 10] : [0, 10]}
                  placement={'top'}
                >
                  <span>
                    <LtIcon name="Question" />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        {!showBlurImage && !isBusinessCreditWheelFetching && (
          <>
            <div className="chart-container">
              <div className="speedometer">
                <CreditScoreGauge
                  score={businessCreditWheel.score ? businessCreditWheel.score : 0}
                />
              </div>
            </div>

            <div className="business-credit__score">{businessCreditWheel.score}</div>
            <div className="business-credit__status">{businessCreditScoreStatus}</div>
            <div className="business-credit__date">
              Last Updated {new Intl.DateTimeFormat('en-US').format(lastUpdated)}
            </div>
            <div className="business-credit__logo">
              <img alt="Equifax" src="/images/home/equifax.png" />
            </div>
          </>
        )}
        {!showBlurImage && !isCreditHistoryGraphFetching && !isBusinessCreditWheelFetching && (
          <div className="graph-container">
            <BusinessCreditGraph graphPoints={creditGraphPoints} />
          </div>
        )}
        {showBlurImage && <BlurImageSection isSmallDown={isSmallDown} />}
        <a
          className="business-profile__link"
          href="#"
          onClick={() => setIsCreditScoresModalOpen(true)}
        >
          What is the difference between your personal and your business scores?
        </a>
      </div>
      {!isBusinessCreditWheelFetching && (
        <div className="business-profile__right-content">
          <Accordion businessCreditWheel={businessCreditWheel} />
          <CuriousBox />
        </div>
      )}
      <Modal
        center={true}
        centerContent={true}
        show={isCreditScoresModalOpen}
        size="large"
        title=""
        onClose={() => setIsCreditScoresModalOpen(false)}
      >
        <div className="credit-scores">
          <div className="credit-scores__header">
            <h4 className="credit-scores__header--title">
              What is the difference between your personal and your business credit scores?
            </h4>
          </div>
          <div className="credit-scores__info">
            Your <b>personal credit score</b> measures your creditworthiness - your personal ability
            to pay back a debt. On the other hand, a <b>business credit score</b> measures the
            ability of your business to meet its own financial obligations. These two scores are
            often independent of each other and they measure different things.
          </div>
        </div>
      </Modal>
      <Modal
        center={true}
        centerContent={true}
        mode={Mode.Modal}
        show={isUserGuidesModalOpen}
        size="large"
        title=""
        onClose={() => onClickUserGuidModelClosed()}
      >
        <div className="user-guide">
          {activeSlides === 0 && (
            <>
              <div className="user-guide__img-section">
                <img alt="Business Credit Score" src={Group1Image} />
              </div>
              <h3 className="user-guide__title">Get to know your Equifax Business Credit Score!</h3>
              <p className="user-guide__text">
                Welcome to your quick start guide to understanding your Equifax Business Credit
                Score. Here, you&#39;ll learn how to navigate your dashboard so you can find the
                most useful information about your score, the factors that impact it and your
                business credit history.
              </p>
              <div className="user-guide__next-step">
                <button className="user-guide__next-step--cta" onClick={() => setActiveSlides(1)}>
                  Continue
                </button>
              </div>
            </>
          )}
          {activeSlides === 1 && (
            <>
              <div className="user-guide__img-section">
                <img alt="Business Credit Score" src={Group2Image} />
                <img
                  alt="right-arrow"
                  className="user-guide__img-section--right-arrow"
                  src={AtomsRightArrowImage}
                />
              </div>
              <h3 className="user-guide__title">Your Business Credit Score</h3>
              <p className="user-guide__text">
                Your Equifax Business Credit Score demonstrates the likelihood your company will
                make its payments ontime. It ranges from{' '}
                <span className="user-guide__text--bold">224 to 580</span>, and the higher, the
                better. Equifax collects information from your vendors, suppliers and public records
                to determine your score.
              </p>
              <div className="user-guide__next-step">
                <button className="user-guide__next-step--cta" onClick={() => setActiveSlides(2)}>
                  Continue
                </button>
              </div>
            </>
          )}
          {activeSlides === 2 && (
            <>
              <div className="user-guide__img-section">
                <img alt="Business Credit Score" src={Group3Image} />
                <img
                  alt="right-arrow"
                  className="user-guide__img-section--left-arrow"
                  src={AtomsLeftArrowImage}
                />
              </div>
              <h3 className="user-guide__title">Factors Affecting Your Score</h3>
              <p className="user-guide__text">
                This section displays the top two to four reasons for your current business credit
                score. You can always improve your score by continuing to pay your vendors and
                suppliers on time.
              </p>
              <div className="user-guide__next-step">
                <button className="user-guide__next-step--cta" onClick={() => setActiveSlides(3)}>
                  Continue
                </button>
              </div>
            </>
          )}
          {activeSlides === 3 && (
            <>
              <div className="user-guide__img-section">
                <img alt="Business Credit Score" src={Group4Image} />
                <img
                  alt="right-arrow"
                  className="user-guide__img-section--right-arrow user-guide__img-section--right-arrow-bottom"
                  src={AtomsRightArrowImage}
                />
              </div>
              <h3 className="user-guide__title">Business Credit Score History</h3>
              <p className="user-guide__text">
                This chart displays your business credit history based on your most recent
                inquiries. Over time as you keep coming back, you&#39;ll be able to review your
                <span className="user-guide__text--bold">last six</span> credit scores.
              </p>
              <div className="user-guide__next-step">
                <button
                  className="user-guide__next-step--cta"
                  onClick={() => onClickUserGuidModelClosed()}
                >
                  See your Business Credit Score
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
