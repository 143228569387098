import classNames from 'classnames';
import { LabelHTMLAttributes, ReactNode } from 'react';
import './InputLabel.scss';

type InputLabelSizes = 'default' | 'large';

interface InputLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  children: ReactNode;
  size?: InputLabelSizes;
}

const InputLabel = ({
  children,
  size = 'default',
  ...otherProps
}: InputLabelProps): JSX.Element => {
  return (
    <label
      className={classNames('input-label', { [`input-label--${size}`]: size !== 'default' })}
      htmlFor="businessName"
      {...otherProps}
    >
      {children}
    </label>
  );
};

export default InputLabel;
