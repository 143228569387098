import { LtIcon } from '../../../../components/Icon';
import { dateFormat } from '../../../../utils';
import ExchangeRequest from '../types/ExchangeRequest';
import './MatcheOffer.scss';

interface MatcheOfferProps {
  exchangeRequest: ExchangeRequest;
}
const MatcheOffer = ({ exchangeRequest }: MatcheOfferProps): JSX.Element => {
  return (
    <div className="matche-offer">
      <LtIcon name="Briefcase" />
      <div className="matche-offer__box">
        <p className="matche-offer__box--requested">
          {exchangeRequest?.amountRequested} requested
          {exchangeRequest?.referralCount > 0 && (
            <>
              <span className="matche-offer__box--and"> and </span>
              {exchangeRequest?.referralCount} <span>funding partner matches received</span>
            </>
          )}
        </p>
        <span className="matche-offer__box--event-date">
          Requested on {dateFormat({ date: exchangeRequest?.eventDate, includeTime: false })}
        </span>
      </div>
    </div>
  );
};

export default MatcheOffer;
