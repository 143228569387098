const CREDIT_REASON_CODES = [
  { key: 'H0001', value: 'Your company may have filed for bankruptcy within the last 12 months.' },
  {
    key: 'C1324',
    value:
      "Business owners who work in your company's region tend to experience high credit risks.",
  },
  {
    key: 'G1324',
    value: "We couldn't find geographic information for the region where your company is located.",
  },
  {
    key: 'C1209',
    value: 'Businesses in your industry are more likely to experience high credit risks.',
  },
  {
    key: 'G1209',
    value: "We couldn't find information about your company's industry.",
  },
  {
    key: 'C1206',
    value:
      "Your company's credit reporting history may be too recent. Similar to your personal credit score, the longer you have business credit, the better your business credit score.",
  },
  {
    key: 'G1206',
    value: "We couldn't find information showing your company's credit reporting history.",
  },
  {
    key: 'H1195',
    value:
      'Your company may have unresolved liens or judgments listed on your business credit report or in public records.',
  },
  {
    key: 'C1198',
    value:
      'A lien or judgment against your company may have recently appeared on your business credit report or in public records.',
  },
  {
    key: 'C0189',
    value:
      'Business owners in your industry historically carry high balances on their loans and credit lines.',
  },
  {
    key: 'C0068',
    value:
      'Your company may be carrying too many balances on non-financial accounts, such as utility bills, cell phone bills and invoices each month.',
  },
  {
    key: 'C0085',
    value:
      'Too many suppliers or vendors for your company may have been listed on your business credit report within the last 12 months.',
  },
  {
    key: 'C1375',
    value:
      "Your company's creditors or suppliers may have reported a stretch of missed or late payments to Equifax over the last three months.",
  },
  {
    key: 'C1389',
    value:
      "Your company's creditors or suppliers may have reported a stretch of missed or late payments to Equifax over the last three months.",
  },
  {
    key: 'C1395',
    value:
      'Companies in your industry have shown a high likelihood of missed or late payments to their creditors and suppliers.',
  },
  {
    key: 'C1175',
    value:
      'Your company may have had a lower percentage of credit accounts reported in good standing over the last three months.',
  },
  {
    key: 'C1081',
    value:
      "Your company's creditors or suppliers may have indicated that payments on your accounts are at least four months late, or that your company may soon receive a charge-off.",
  },
  {
    key: 'C1330',
    value: 'Your company may have a poor history of repaying suppliers on time.',
  },
  {
    key: 'G1330',
    value: "We couldn't find information showing your company's history of repaying suppliers.",
  },
  {
    key: 'C0388',
    value: 'Your company may have owed too many creditors or suppliers over the last three months.',
  },
  {
    key: 'C0389',
    value: 'Your company may have owed too many creditors or suppliers over the last 12 months.',
  },
  {
    key: 'C3001',
    value:
      "Your company's creditors or suppliers may have reported more late or missed payments over the last 12 months in comparison to your company's total available credit.",
  },
  {
    key: 'G4000',
    value:
      "There may not be enough information about your company's credit accounts listed on your business credit report.",
  },
  {
    key: 'G4001',
    value:
      'There may not be enough information available about credit accounts for companies in your industry.',
  },
  {
    key: 'G4003',
    value:
      "There may not be enough information about payments to your company's creditors or suppliers listed on your business credit report.",
  },
  {
    key: 'G4005',
    value: 'There may not be enough information about your company found in public records.',
  },
  {
    key: 'G0073',
    value:
      "There may not be enough information about your company's qualified credit accounts listed on your business credit report.",
  },
  {
    key: 'R1 V1',
    value: "We couldn't find any recent business account information for your company.",
  },
  {
    key: 'R1 V2',
    value:
      'Your company may not have any recent account information on your business credit report.',
  },
  {
    key: 'R2 V1',
    value: 'Credit information about your company does not meet minimum scoring criteria.',
  },
  {
    key: 'R2 V2',
    value: "Your company's credit information does not meet minimum scoring criteria.",
  },
  {
    key: 'R2 V3',
    value:
      "Unfortunately, we couldn't provide you with a business credit risk score because information available about your company's credit accounts does not meet minimum scoring criteria.",
  },
];

export default CREDIT_REASON_CODES;
