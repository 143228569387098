import classNames from 'classnames';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useUploadDocumentMutation } from '../../../../app/services/documents';
import { Button } from '../../../../components/Button';
import LtIcon from '../../../../components/Icon/LtIcon';
import Select from '../../../../components/Input/Select/SelectInput';
import { Modal } from '../../../../components/Modal';
import { ProgressBar } from '../../../../components/ProgressBar';
import { MEDIA_QUERIES } from '../../../../constants';
import { useIsMounted } from '../../../../hooks';
import DocumentsTable from '../../DocumentsTable/DocumentsTable';
import Stip from '../../types/Stip';
import UploadedDocument from '../../types/UploadedDocument';
import documentTypes from './documentTypes';
import './Upload.scss';
import UploadButton from './UploadButton';

export interface UploadProps {
  applicationId?: string;
  externalVisitorId?: string;
  isStipsRequired?: boolean;
  opportunityId?: string;
  showUploadIcon?: boolean;
  stipsList?: Stip[];
  selectedStip?: string;
  onUploadDocuments?: (documents: UploadedDocument[]) => void;
}

type FileMeta = {
  id: string;
  size: string;
  type: string;
  isSettled: boolean;
  isValid: boolean;
  documentType?: string;
  uploadPercent: number;
  errorMessages?: string[];
};

type FileState = FileMeta & {
  data: File;
};

type FileStateRecord = Record<string, FileState>;

const validFileTypes = ['image/jpg', 'image/png', 'image/jpeg', 'application/pdf'];
const MAXIMUM_FILE_SIZE =
  process.env.MAXIMUM_FILE_SIZE === undefined ? 9437000 : parseInt(process.env.MAXIMUM_FILE_SIZE);

const validateFile = (file: File) => {
  if (validFileTypes.indexOf(file.type) === -1) {
    return {
      isValid: false,
      errorMessage: 'Incorrect file format. Only PDF, PNG and JPG files can be accepted.',
    };
  }

  if (file.size > MAXIMUM_FILE_SIZE) {
    return {
      isValid: false,
      errorMessage: 'This file exceeds the 9 MB maximum size. Please try uploading a smaller file.',
    };
  }

  return { isValid: true };
};

const calculateFileSize = (size: number) => {
  if (size === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const getFileType = (fileName: string) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
};

const getDocumentType = (
  existingFile?: FileState,
  isStipsRequired?: boolean,
  isFileStateEmpty?: boolean,
  selectedStip?: string
) => {
  if (existingFile) return existingFile.documentType;
  if (!isStipsRequired) return 'Bank Statement';
  if (isFileStateEmpty && selectedStip) return selectedStip;

  return '';
};

const getIsSettled = (
  existingFile?: FileState,
  isStipsRequired?: boolean,
  stipsList?: Stip[],
  isValid?: boolean,
  isFileStateEmpty?: boolean,
  selectedStip?: string
) => {
  if (existingFile) return existingFile.isSettled;
  if (!isStipsRequired && stipsList && stipsList?.length > 0) return true;
  if (isValid && isFileStateEmpty && selectedStip) return true;

  return false;
};

const doesFileHaveErrors = (file: FileState) => {
  return file.errorMessages != null && file.errorMessages.length > 0;
};

const createFileStateRecord = (
  files: FileList,
  fileState: FileStateRecord,
  isStipsRequired?: boolean,
  selectedStip?: string,
  stipsList?: Stip[]
) => {
  const isFileStateEmpty = Object.values(fileState).length === 0;

  return Array.from(files).reduce((result, file) => {
    const id = `${file.name}-${file.size}`;
    const size = calculateFileSize(file.size);
    const type = getFileType(file.name);
    const existingFile = Object.values(fileState).find((f) => f.id === id);
    const documentType = getDocumentType(
      existingFile,
      isStipsRequired,
      isFileStateEmpty,
      selectedStip
    );
    const fileValidation = validateFile(file);
    const isSettled = getIsSettled(
      existingFile,
      isStipsRequired,
      stipsList,
      fileValidation?.isValid,
      isFileStateEmpty,
      selectedStip
    );

    result[id] = {
      id,
      data: file,
      size,
      type,
      documentType,
      isSettled,
      isValid: fileValidation.isValid,
      uploadPercent: 0,
      errorMessages:
        fileValidation.isValid === false && fileValidation.errorMessage
          ? [fileValidation.errorMessage]
          : undefined,
    };

    return result;
  }, {} as FileStateRecord);
};

const Upload: React.FC<UploadProps> = ({
  applicationId,
  externalVisitorId,
  isStipsRequired,
  opportunityId,
  showUploadIcon,
  stipsList,
  selectedStip,
  onUploadDocuments,
}) => {
  const isMounted = useIsMounted();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletedDocumentId, setDeletedDocumentId] = useState('');
  const [fileState, setFileState] = useState<FileStateRecord>({});
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const [uploadFile] = useUploadDocumentMutation();

  const handleResetState = () => {
    setFileState({});
    setIsUploading(false);
    setIsUploadModalOpen(false);
  };

  const isSaveButtonEnabled = () => {
    const validFiles = Object.values(fileState).filter(({ isValid }) => isValid);

    return (
      validFiles.length > 0 &&
      validFiles.every((file) => file.isSettled === true) &&
      isUploading === false &&
      Object.values(fileState).length > 0
    );
  };

  const handleFiles = (files: FileList) => {
    const fsr = createFileStateRecord(files, fileState, isStipsRequired, selectedStip, stipsList);

    setFileState((prev) => ({
      ...prev,
      ...fsr,
    }));

    if (Object.values(fsr).length > 0) {
      setIsUploadModalOpen(true);
    }
  };

  const handleDocumentTypeChange = (fileId: string) => (selectedIndex: number) => {
    setFileState((prev) => {
      const selected = documentTypes.find((dt) => dt.value === selectedIndex);
      const newState = {
        ...prev,
      };

      newState[fileId].isSettled = selected != null;
      newState[fileId].documentType = selected?.label;

      return newState;
    });
  };

  const updateFileUploadState = (fileId: string, progress: number, errorMessages?: string[]) => {
    setFileState((prev) => {
      const newState = {
        ...prev,
      };

      if (newState[fileId] != null) {
        if (errorMessages != null && errorMessages.length > 0) {
          newState[fileId].errorMessages = errorMessages;
          newState[fileId].isSettled = false;
        }

        newState[fileId].uploadPercent = progress;

        if (progress === 100) {
          newState[fileId].isValid = false;
        }
      }

      return newState;
    });
  };

  const saveDocument = async (fileState: FileState) => {
    updateFileUploadState(fileState.id, 20);

    const formData = new FormData();

    if (stipsList && stipsList.length > 0) {
      const stip = stipsList.find((s) => s.name === fileState.documentType);
      if (stip) {
        formData.append('stipId', stip?.id as string);
      }
    }

    formData.append('applicationId', applicationId as string);
    formData.append('externalVisitorId', externalVisitorId as string);
    formData.append('opportunityId', opportunityId as string);
    formData.append('documentType', fileState?.documentType ?? '');
    formData.append('file', fileState.data);

    const uploadDocResponse = await uploadFile(formData)
      .unwrap()
      .then((response) => {
        updateFileUploadState(fileState.id, 100);
        return response.data;
      })
      .catch(() => {
        updateFileUploadState(fileState.id, 100, ['There was an error uploading the document.']);
      });

    if (uploadDocResponse != null) {
      return uploadDocResponse;
    }

    return undefined;
  };

  const createUploadDocument = async (fileState: FileState): Promise<UploadedDocument | void> => {
    const result = await saveDocument(fileState);
    const documentType = documentTypes.find((dt) => dt.label === fileState.documentType);

    if (result != null) {
      const document: UploadedDocument = {
        id: result?.createdRecordId,
        documentId: result?.createdRecordId,
        documentType: documentType?.label ?? 'Bank Statement',
        documentName: fileState.data.name,
        externalDocumentName: result?.externalDocumentName,
        uploadedDateTime: new Date(),
      };

      return document;
    }
  };

  const handleSaveDocuments = async () => {
    setIsUploading(true);

    if (Object.values(fileState || {}).length === 0) {
      return;
    }

    const validFiles = Object.values(fileState).filter(({ isValid }) => isValid);
    const documents: UploadedDocument[] = [];

    for await (const file of validFiles) {
      if (file.isSettled === true && isMounted()) {
        const document = await createUploadDocument(file);

        if (document != null) {
          documents.push(document);
        }
      }
    }

    if (documents.length > 0) {
      TrackDocumentUploads(documents);
      setTimeout(() => {
        if (onUploadDocuments) {
          onUploadDocuments(documents);
        }

        if (documents.length === validFiles.length) {
          handleResetState();
        }
      }, 1000);
    } else {
      setIsUploading(false);
    }
  };

  const deleteFile = () => {
    setFileState((prev) => {
      const newState = {
        ...prev,
      };

      delete newState[deletedDocumentId];
      return newState;
    });
    setIsDeleteModalOpen(false);
  };

  const handleDeleteFile = (fileId: string) => {
    setDeletedDocumentId(fileId);
    setIsDeleteModalOpen(true);
  };

  const TrackDocumentUploads = (uploadedDocuments: UploadedDocument[]) => {
    let trackProperties = '';
    const today = new Date().toISOString().slice(0, 10);
    uploadedDocuments.forEach((uploadedDocument) => {
      if (uploadedDocument?.documentId !== null) {
        trackProperties += `${uploadedDocument.documentName}:${uploadedDocument.documentType}:${today}`;
        if (uploadedDocuments.length > 1) {
          trackProperties += `|`;
        }
      }
    });
    window.ltanalytics.track('BL Document Uploaded', { bl_doc_upload_info: `${trackProperties}` });
  };

  return (
    <>
      {showUploadIcon ? (
        <UploadButton showUploadIcon={true} onSelectFiles={handleFiles} />
      ) : (
        <UploadButton onSelectFiles={handleFiles} />
      )}
      <Modal
        center={true}
        className="upload__modal-container"
        isDisabled={isUploading}
        show={isUploadModalOpen}
        size="large"
        title="Upload Documents"
        onClose={() => handleResetState()}
      >
        <div className="upload__modal-wrapper scrollbar">
          <p className="sub-title-text mb-2">
            Once your document is uploaded, select the document type you&apos;re submitting, then
            save.
          </p>
          <DocumentsTable columns={['Select Document Type', 'File Name', '', '']}>
            {(Row, Cell) => {
              return Object.values(fileState).map((file) => {
                return (
                  <Row key={`${file.id}`}>
                    {!isMobile ? (
                      <Cell>
                        {doesFileHaveErrors(file) ? (
                          <>
                            {file.data.name}
                            <span className="error">
                              {(file.errorMessages as string[]).map((message, i) => (
                                <div key={i} className="error__message">
                                  {message}
                                </div>
                              ))}
                            </span>
                          </>
                        ) : (
                          <>
                            {file.uploadPercent > 0 ? (
                              <>
                                {file.data.name}
                                <ProgressBar percent={file.uploadPercent} />
                              </>
                            ) : (
                              <Select
                                defaultValue={
                                  stipsList === undefined || isStipsRequired
                                    ? Object.values(fileState).length === 1 && selectedStip
                                      ? { label: selectedStip, value: selectedStip }
                                      : ''
                                    : {
                                        label: 'Bank Statement',
                                        value: 'Bank Statement',
                                      }
                                }
                                isDisabled={
                                  !isStipsRequired ? stipsList !== undefined : !isStipsRequired
                                }
                                options={documentTypes}
                                placeholder="Select Type of Document"
                                onChange={handleDocumentTypeChange(file.id)}
                              />
                            )}
                          </>
                        )}
                      </Cell>
                    ) : (
                      <Cell>
                        {doesFileHaveErrors(file) ? (
                          <>
                            <span
                              className="upload-document__td-delete-icon"
                              onClick={() => handleDeleteFile(file.id)}
                            ></span>
                          </>
                        ) : (
                          <>
                            {file.uploadPercent === 0 && (
                              <LtIcon
                                className={classNames({
                                  isFulfilled: file.isSettled === true,
                                })}
                                name="Check"
                              />
                            )}
                          </>
                        )}
                        {file.data.name}
                        <div className="doc-size">{file.size}</div>
                      </Cell>
                    )}
                    <Cell>
                      {!isMobile && !doesFileHaveErrors(file) && file.uploadPercent === 0 && (
                        <>{file.data.name}</>
                      )}
                      <Modal
                        center={true}
                        centerContent={true}
                        closable={true}
                        isDisabled={false}
                        show={deletedDocumentId === file.id && isDeleteModalOpen}
                        size="medium"
                        onClose={() => setIsDeleteModalOpen(false)}
                      >
                        <div className="delete-modal">
                          <div className="delete-modal__text">
                            Are you sure you want to delete this file?
                          </div>
                          <div className="delete-modal__cta-block">
                            <input
                              className="delete-modal__cta-block--cancel"
                              type="button"
                              value="Cancel"
                              onClick={() => setIsDeleteModalOpen(false)}
                            />
                            <input
                              className="delete-modal__cta-block--delete"
                              data-btn-yes-delete="Yes, delete"
                              type="button"
                              value="Yes, delete"
                              onClick={() => deleteFile()}
                            />
                          </div>
                        </div>
                      </Modal>
                    </Cell>
                    <Cell>{!isMobile && <div className="doc-size">{file.size}</div>}</Cell>
                    {!isMobile ? (
                      <Cell>
                        {doesFileHaveErrors(file) ? (
                          <span
                            className="upload-document__td-delete-icon"
                            onClick={() => handleDeleteFile(file.id)}
                          ></span>
                        ) : (
                          <>
                            {file.uploadPercent === 0 && (
                              <LtIcon
                                className={classNames({
                                  isFulfilled: file.isSettled === true,
                                })}
                                name="Check"
                              />
                            )}
                          </>
                        )}
                      </Cell>
                    ) : (
                      <Cell>
                        {doesFileHaveErrors(file) ? (
                          <span className="error">
                            <LtIcon
                              className={classNames({
                                isFulfilled: file.isSettled === false,
                              })}
                              name="Ex"
                            />
                            {(file.errorMessages as string[]).map((message, i) => (
                              <div key={i} className="error__message">
                                {message}
                              </div>
                            ))}
                          </span>
                        ) : (
                          <>
                            {file.uploadPercent > 0 ? (
                              <ProgressBar percent={file.uploadPercent} />
                            ) : (
                              <Select
                                defaultValue={
                                  stipsList === undefined || isStipsRequired
                                    ? Object.values(fileState).length === 1 && selectedStip
                                      ? { label: selectedStip, value: selectedStip }
                                      : ''
                                    : {
                                        label: 'Bank Statement',
                                        value: 'Bank Statement',
                                      }
                                }
                                isDisabled={
                                  !isStipsRequired ? stipsList !== undefined : !isStipsRequired
                                }
                                options={documentTypes}
                                placeholder="Select Type of Document"
                                onChange={handleDocumentTypeChange(file.id)}
                              />
                            )}
                          </>
                        )}
                      </Cell>
                    )}
                  </Row>
                );
              });
            }}
          </DocumentsTable>
          <UploadButton onSelectFiles={handleFiles} />
          <div className="cta-action-block">
            <Button className="upload__cta" color={'blue-outline'} onClick={handleResetState}>
              Cancel
            </Button>
            <Button
              className="upload__cta"
              color={isSaveButtonEnabled() ? 'blue' : 'gray'}
              disabled={!isSaveButtonEnabled()}
              onClick={handleSaveDocuments}
            >
              Save Documents
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Upload;
