import ExchangeTokenRequest from '~/features/Plaid/types/ExchangeTokenRequest';
import ExchangeTokenResponse from '~/features/Plaid/types/ExchangeTokenResponse';
import LinkTokenResponse from '~/features/Plaid/types/LinkTokenResponse';
import { borrowerPortalApi } from './borrowerPortalBase';

export const plaidApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getLinkToken: builder.query<LinkTokenResponse, string>({
      query: (qFormGuid) => 'api/Plaid/getLinkToken?guid=' + qFormGuid,
    }),
    getItemId: builder.mutation<
      ExchangeTokenResponse,
      { qFormGuid: string | null; exchangeTokenRequest: ExchangeTokenRequest }
    >({
      query: ({ qFormGuid, ...body }) => ({
        url: 'api/Plaid/exchangeTokenAndGetItemId?guid=' + qFormGuid,
        method: 'POST',
        body: body?.exchangeTokenRequest,
      }),
    }),
    getNotificationRedirect: builder.query<string, string>({
      query: (queryParams) => ({
        url: 'api/Plaid/getNotificationRedirectUrl' + queryParams,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLinkTokenQuery,
  useGetItemIdMutation,
  useLazyGetNotificationRedirectQuery,
} = plaidApi;
