import { default as CreditCard } from '../types/CreditCard';

const handleSetDetail = (
  id: string,
  cards: CreditCard[],
  cardsDetailStatus: Record<string, boolean>,
  gridRef: React.RefObject<HTMLDivElement>,
  gridColumnMinWidth: number,
  gridRowGap: number,
  setCardsDetailStatus: (value: React.SetStateAction<Record<string, boolean>>) => void
) => {
  const getCardRect = (index: number) => {
    return gridRef.current?.children[index]?.getBoundingClientRect() ?? ({} as DOMRect);
  };

  // If we are closing the details on this card, we need
  // to check all other card details in the current
  // row, and if they are closed, scroll the
  // page to the top of the card
  if (cardsDetailStatus[id] === true) {
    const idx = cards.findIndex((c) => c.id === id);
    const cardsPerRow = Math.floor((gridRef.current?.clientWidth ?? 0) / gridColumnMinWidth);
    const rowIndex = Math.floor(idx / cardsPerRow);
    const areAllOtherCardsInRowClosed =
      Object.values(cardsDetailStatus).filter((status, index) => {
        const rowIdx = Math.floor(index / cardsPerRow);
        if (rowIdx === rowIndex && idx !== index) {
          return status;
        }

        return false;
      }).length === 0;

    if (areAllOtherCardsInRowClosed) {
      const { top = 0 } = getCardRect(idx);

      window.scrollTo(0, window.scrollY + top - gridRowGap);
    }
  }

  setCardsDetailStatus((prev: Record<string, boolean>) => ({
    ...prev,
    [id]: !prev[id],
  }));
};

export default function useHandleSetDetail(
  cards: CreditCard[],
  cardsDetailStatus: Record<string, boolean>,
  gridRef: React.RefObject<HTMLDivElement>,
  gridColumnMinWidth: number,
  gridRowGap: number,
  setCardsDetailStatus: (value: React.SetStateAction<Record<string, boolean>>) => void
): (id: string) => () => void {
  return (id: string) => () =>
    handleSetDetail(
      id,
      cards,
      cardsDetailStatus,
      gridRef,
      gridColumnMinWidth,
      gridRowGap,
      setCardsDetailStatus
    );
}
