import { Modal } from '../../../components/Modal';
import { useLTAnalytics } from '../../../hooks/useLTAnalytics';
import './CallOrTextModal.scss';
import Photo from './Photo';

interface CallOrTextModalProps {
  name?: string;
  phone?: string;
  photo?: string;
  show: boolean;
  onClickSetShowModal: (showModal: boolean) => void;
}

const CallOrTextModal = ({
  name,
  phone,
  photo,
  show,
  onClickSetShowModal,
}: CallOrTextModalProps): JSX.Element => {
  const analyticsCall = useLTAnalytics('Call');
  const analyticsText = useLTAnalytics('Text');

  return (
    <Modal
      center={true}
      centerContent={true}
      show={show}
      size="medium"
      onClose={() => onClickSetShowModal(false)}
    >
      <div>
        <p className="call-or-text-modal__company-name">YOUR CONCIERGE</p>
        <Photo profilePhotoAsBase64String={photo} />
        <p className="call-or-text-modal__user-name">{name}</p>
        <span>Please select a contact method below.</span>
        <p className="call-or-text-modal__phone--bold">{phone}</p>
      </div>
      <div className="call-or-text-modal__block">
        <a
          className="call-or-text-modal__cta"
          href={`tel:${phone}`}
          onClick={() => onClickSetShowModal(false)}
          {...analyticsCall}
        >
          Call
        </a>
        <a
          className="call-or-text-modal__cta"
          href={`sms:${phone}`}
          onClick={() => onClickSetShowModal(false)}
          {...analyticsText}
        >
          Text
        </a>
      </div>
    </Modal>
  );
};

export default CallOrTextModal;
