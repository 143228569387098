import CheckmarkImage from '../images/checkmark.png';
import './LenderDetails.scss';

type InputType = 'text' | 'bool';

interface LenderDetailsRowProps {
  name?: string;
  value?: string;
  opportunityId?: string;
  inputType?: InputType;
}

const LenderDetailsRow = ({
  inputType = 'text',
  name,
  value,
  opportunityId,
}: LenderDetailsRowProps): JSX.Element => {
  return (
    <div className="lender-details__row">
      <div className="lender-details__label-name">{name}</div>
      {opportunityId != '' && (
        <div className="lender-details__label-value" data-opportunity-id={opportunityId}>
          {value}
        </div>
      )}
      {opportunityId == '' && inputType == 'text' && (
        <div className="lender-details__label-value">{value}</div>
      )}
      {inputType == 'bool' && value?.toLowerCase() == 'true' && (
        <div className="lender-details__label-value">
          <img alt={CheckmarkImage} src={CheckmarkImage} />
        </div>
      )}
      {inputType == 'bool' && value?.toLowerCase() != 'true' && (
        <div className="lender-details__label-value">{value}</div>
      )}
    </div>
  );
};

export default LenderDetailsRow;
