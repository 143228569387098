import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '~/components/Button';
import { LtIcon } from '~/components/Icon';
import './PlaidNotification.scss';

enum NotificationStatus {
  Red,
  Yellow,
  Green,
}

interface PlaidNotificationProps {
  state: NotificationStatus;
  handleBankStatementUpload?: () => void;
}

const PlaidNotification = ({
  state,
  handleBankStatementUpload,
}: PlaidNotificationProps): JSX.Element => {
  const navigate = useNavigate();
  const [show, showNotification] = useState(true);
  const handleOnClick = () => {
    showNotification(false);
  };

  const handleBankStatementUploadDefault = function () {
    navigate('/documents');
  };

  const element = () => {
    switch (state) {
      case NotificationStatus.Red:
        return (
          <div className="notification_container">
            <div className="notification_state_icon_container">
              <span>
                <LtIcon name="Info-Icon"></LtIcon>
              </span>
            </div>
            <div className="notification_body_container">
              <div className="notification_body_container_flex_direction">
                <div className="notification_body_centered_container">
                  <div className="notification_body_centered_message_container">
                    <div className="notification_body_centered_message_toprow_container">
                      <p className="notification_body_centered_message_toprow_title">
                        Missing Bank Statements
                      </p>
                      <div className="notification_body_centered_message_toprow_action_mobile">
                        <span className="button__close" onClick={handleOnClick}>
                          &times;
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="notification_message">
                        Please upload your three most recent bank statements to process your funding
                        request.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="notification_body_ctaContainer notification_body_cta-mobile">
                  <Button
                    color={'nocolor'}
                    compact={true}
                    onClick={
                      handleBankStatementUpload
                        ? handleBankStatementUpload
                        : handleBankStatementUploadDefault
                    }
                  >
                    Upload Bank Statements
                  </Button>
                </div>
              </div>
            </div>
            <div className="notification_body_action_container">
              <span className="button__close" onClick={handleOnClick}>
                &times;
              </span>
            </div>
          </div>
        );
      case NotificationStatus.Yellow:
        return <div>Plaid warning</div>;
      case NotificationStatus.Green:
        return (
          <div className="notification_container_green">
            <div className="notification_state_icon_container_green">
              <span>
                <LtIcon name="Check"></LtIcon>
              </span>
            </div>
            <div className="notification_body_container">
              <div className="notification_body_container_flex_direction">
                <div className="notification_body_centered_container">
                  <div className="notification_body_centered_message_container_green">
                    <div className="notification_body_centered_message_toprow_container">
                      <p className="notification_body_centered_message_toprow_title">
                        Linking Completed
                      </p>
                      <div className="notification_body_centered_message_toprow_action_mobile">
                        <span className="button__close" onClick={handleOnClick}>
                          &times;
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="notification_message">
                        We have everything we need to process your loan request. We’ll be in touch
                        soon!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="notification_body_action_container">
              <span className="button__close" onClick={handleOnClick}>
                &times;
              </span>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  return show ? <div className="notification_parent_container">{element()}</div> : <></>;
};

export { PlaidNotification, NotificationStatus };
