import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { OptionTypeBase } from 'react-select';
import { Button } from '../../../components/Button';
import { IconNames, LtIcon } from '../../../components/Icon';
import NavbarItem from '../../../components/Navigation/NavbarItem';
import { useLTAnalytics, useToggle } from '../../../hooks';
import './ResourcesMenuMobile.scss';

interface ResourcesMenuMobileProps {
  options: ResourcesMenuOptionType[];
}

export interface ResourcesMenuOptionType extends OptionTypeBase {
  label: string;
  icon: IconNames;
  path: string;
}

interface ResourceItemProps {
  resource: ResourcesMenuOptionType;
  toggle: () => void;
}

const ResourcesMenuMobileItem = ({ resource, toggle }: ResourceItemProps) => (
  <NavbarItem
    className="resources-menu-mobile__item"
    icon={resource.icon}
    name={resource.label}
    path={resource.path}
    onClick={toggle}
    {...useLTAnalytics(resource.label)}
  />
);

const ResourcesMenuMobile = ({ options }: ResourcesMenuMobileProps): JSX.Element => {
  const [showDrawer, toggleDrawer] = useToggle();
  const navbarCollapseRef = useRef<HTMLDivElement>(null);

  const outerClick = useCallback(
    (event: Event) => {
      if (!navbarCollapseRef?.current?.contains((event?.target as Node) || null)) {
        showDrawer && toggleDrawer();
      }
    },
    [showDrawer, toggleDrawer]
  );

  useEffect(() => {
    document.addEventListener('mousedown', outerClick);
    document.addEventListener('touchstart', outerClick);

    return () => {
      document.removeEventListener('mousedown', outerClick);
      document.removeEventListener('touchstart', outerClick);
    };
  }, [outerClick]);

  return (
    <nav>
      <Button block={true} color="lt-blue-outline" compact={true} onClick={toggleDrawer}>
        Explore Resources
      </Button>
      <div
        ref={navbarCollapseRef}
        className={classNames('resources-menu-mobile__collapse', {
          'resources-menu-mobile__collapse--show': showDrawer,
        })}
      >
        <ul className="resources-menu-mobile__nav">
          <div className="resources-menu-mobile__text">
            Choose a resource
            <span className="resources-menu-mobile__close" onClick={toggleDrawer}>
              <LtIcon name="Ex" />
            </span>
          </div>
          {options.map((resource) => (
            <ResourcesMenuMobileItem
              key={resource.label}
              resource={resource}
              toggle={toggleDrawer}
            />
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default ResourcesMenuMobile;
