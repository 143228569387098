import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../src/app/store';
import { profileApi } from '../../app/services';

export type BusinessTypesState = string[];

const initialState: BusinessTypesState = [];

const reducers = {
  setBusinessTypes: (state: BusinessTypesState, action: PayloadAction<string[]>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const businessTypesSlice = createSlice({
  name: 'businessTypes',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.getBusinessTypes.matchFulfilled,
      reducers.setBusinessTypes
    );
  },
});

export default businessTypesSlice.reducer;

// Actions
export const { setBusinessTypes } = businessTypesSlice.actions;

// Selectors
export const selectBusinessTypes = (state: RootState): string[] => state.profile.businessTypes;
