import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { useGetCardsQuery } from '../../../app/services';
import { Container } from '../../../components/Container';
import { FeatureFlagComponent, FeatureFlagNames } from '../../../components/FeatureFlag';
import { Grid } from '../../../components/Grid';
import { useWindowResize } from '../../../hooks';
import CreditCard from './CreditCard';
import CreditCardClickout from './CreditCardClickout';
import './CreditCards.scss';
import { selectCreditCards } from './creditCardsSlice';
import useHandleSetDetail from './utils/useHandleSetDetail';

const CreditCards = (): JSX.Element => {
  useGetCardsQuery();

  const cards = useAppSelector(selectCreditCards);
  const [cardsDetailStatus, setCardsDetailStatus] = useState(() => {
    const initial: Record<string, boolean> = {};

    return cards.reduce((result, card) => {
      result[card.id] = false;

      return result;
    }, initial);
  });

  const gridRef = useRef<HTMLDivElement>(null);
  const gridColumnMinWidth = 255;
  const gridRowGap = 20;

  const { width } = useWindowResize();

  // Everytime the window width changes we need to close all
  // card details
  useEffect(() => {
    setCardsDetailStatus((prev) => {
      const newCardDetailsStatusState: Record<string, boolean> = {};

      return Object.keys(prev).reduce((result, key) => {
        result[key] = false;

        return result;
      }, newCardDetailsStatusState);
    });
  }, [width]);

  const handleSetDetail = useHandleSetDetail(
    cards,
    cardsDetailStatus,
    gridRef,
    gridColumnMinWidth,
    gridRowGap,
    setCardsDetailStatus
  );

  return (
    <div className="credit-cards">
      <Container>
        <FeatureFlagComponent fallback={<CreditCardClickout />} name={FeatureFlagNames.CreditCards}>
          <Grid
            ref={gridRef}
            className="credit-cards__grid"
            columnGap={'30px'}
            columnMinWidth={`${gridColumnMinWidth}px`}
            rowGap={`${gridRowGap}px`}
          >
            {cards.map((card) => (
              <CreditCard
                key={card.id}
                {...card}
                areDetailsOpen={cardsDetailStatus[card.id]}
                toggleDetails={handleSetDetail(card.id)}
              />
            ))}
          </Grid>
        </FeatureFlagComponent>
      </Container>
    </div>
  );
};

export default CreditCards;
