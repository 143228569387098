import CoverdashQuotes from '@coverdash/quotes';
import { Helmet } from 'react-helmet-async';

const CoverDashLanding = (): JSX.Element => {
  const coverdashApplicationSubmissionID = process.env.COVERDASH_APPLICATION_SUBMISSION_ID;
  const coverdashLicence = process.env.COVERDASH_LICENCE;
  return (
    <>
      <Helmet>
        <title>Coverdash - Business.LendingTree.com</title>
      </Helmet>
      <div>
        <CoverdashQuotes
          applicationSubmissionId={coverdashApplicationSubmissionID}
          license={coverdashLicence}
        />
      </div>
    </>
  );
};

export default CoverDashLanding;
