import './LtIcon.scss';
import { IconNames } from './types/IconNames';

interface LtIconProps {
  name: IconNames;
  className?: string;
}

const LtIcon = ({ name, className = '' }: LtIconProps): JSX.Element => {
  const classNames = [`bl-lt-icon--${name}`, ...className.split(' ')];

  return <i className={classNames.join(' ')} />;
};

export default LtIcon;
