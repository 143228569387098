import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useCreateAccountMutation } from '../../app/services';
import { Container } from '../../components/Container';
import { ErrorMessage } from '../../components/Errors';
import Account from '../Profile/types/Account';
import AccountSignupForm from './AccountSignupForm';
import './TreeAuthSignup.scss';

const TreeAuthSignup = (): JSX.Element => {
  const [createAccount, { data, isLoading, isError, isSuccess }] = useCreateAccountMutation();

  useEffect(() => {
    if (isSuccess && data?.redirectUrl) {
      window.location.href = data.redirectUrl;
    }
  }, [data, isSuccess]);

  const handleAccountFormSubmit = (account: Account) => {
    createAccount(account);
  };

  return (
    <div className="tree-auth-signup">
      <Helmet>
        <title>Small Business Financing and Resources - Business.LendingTree.com</title>
      </Helmet>
      <Container>
        <div className="tree-auth-signup__title">Small Business Dashboard Signup</div>
        <h1 className="tree-auth-signup__header">Enter your business information below</h1>
        <div className="tree-auth-signup__container">
          <div className="tree-auth-signup__why">
            <span className="tree-auth-signup__why--strong">Why do we need this?</span> With this
            information, we&apos;re going to create your business account and provide you access to
            tools and resources to help your business succeed.
          </div>
          {isError && (
            <div className="tree-auth-signup__error-message">
              <ErrorMessage>
                We are sorry, we encountered an error while creating your account. Please try again
                later.
              </ErrorMessage>
            </div>
          )}
          <AccountSignupForm
            account={undefined}
            isSubmitting={isLoading}
            onSubmit={handleAccountFormSubmit}
          />
        </div>
      </Container>
    </div>
  );
};

export default TreeAuthSignup;
