import { ReactNode, useEffect } from 'react';
import { LtIcon } from '../Icon';
import './SuccessMessage.scss';

interface SuccessMessageProps {
  children: ReactNode;
  scrollIntoView?: boolean;
}

const SuccessMessage = ({ children, scrollIntoView = false }: SuccessMessageProps): JSX.Element => {
  useEffect(() => {
    if (scrollIntoView) {
      const successMessage = document.getElementsByClassName('success-message');
      successMessage[0].scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <div className="success-message">
      <LtIcon name="Check" />
      <span>{children}</span>
    </div>
  );
};

export default SuccessMessage;
