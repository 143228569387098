import { ReactNode } from 'react';
import './Container.scss';

interface AppContainerProps {
  children: ReactNode;
}

const Container = ({ children }: AppContainerProps): JSX.Element => {
  return <div className="app__container">{children}</div>;
};

export default Container;
