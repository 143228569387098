import OfferAgent from '../OfferAgent/OfferAgent';
import './ExchangeRequests.scss';
import MatcheContent from './MatcheContent/MatcheContent';
import ExchangeRequest from './types/ExchangeRequest';

interface ExchangeRequestsProps {
  exchangeRequest: ExchangeRequest;
  fvoffersValue?: string;
}
const ExchangeRequests = ({
  exchangeRequest,
  fvoffersValue,
}: ExchangeRequestsProps): JSX.Element => {
  return (
    <div className="exchange-request">
      <OfferAgent requestType="SELF-GUIDED" />
      <div className="exchange-request__block">
        <MatcheContent exchangeRequest={exchangeRequest} fvoffersValue={fvoffersValue} />
      </div>
    </div>
  );
};

export default ExchangeRequests;
