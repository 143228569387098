import ExpiredOffer from '../../features/History/ExpiredOffers/types/ExpiredOffer';
import FundedLoan from '../../features/History/FundedLoans/types/FundedLoan';
import { borrowerPortalApi } from './borrowerPortalBase';

export const historyApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getFundedLoans: builder.query<FundedLoan[], void>({
      query: () => 'history/funded-loans',
    }),
    getExpiredOffers: builder.query<ExpiredOffer[], void>({
      query: () => 'history/expired-offers',
    }),
  }),
  overrideExisting: false,
});

export const { useGetFundedLoansQuery, useGetExpiredOffersQuery } = historyApi;
