import { Helmet } from 'react-helmet-async';
import { useAppSelector } from '../../app/hooks';
import { useUpdateProfileMutation } from '../../app/services';
import { Container } from '../../components/Container';
import { ErrorMessage } from '../../components/Errors';
import { SuccessMessage } from '../../components/Success';
import { selectSnapshot } from '../../slices/snapshotSlice';
import { UserAgent } from '../UserAgent';
import './Profile.scss';
import ProfileForm from './ProfileForm/ProfileForm';

const Profile = (): JSX.Element => {
  const [updateProfile, { isLoading, isError, isSuccess }] = useUpdateProfileMutation();
  const snapshot = useAppSelector(selectSnapshot);

  return (
    <>
      <Helmet>
        <title>Profile - Business.LendingTree.com</title>
      </Helmet>
      <UserAgent
        displayUserAgent={snapshot.conciergeFundingRequests > 0}
        pageTitle="PROFILE"
        subHeader=""
      />
      <Container>
        {isSuccess && (
          <SuccessMessage scrollIntoView={true}>Your profile has been updated.</SuccessMessage>
        )}
        {isError && (
          <ErrorMessage scrollIntoView={true}>
            We are sorry, we encountered an error while updating your profile. Please try again
            later.
          </ErrorMessage>
        )}
        <ProfileForm isSubmitting={isLoading} onSubmit={updateProfile} />
      </Container>
    </>
  );
};

export default Profile;
