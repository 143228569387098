import PublicNavbar from '../Navigation/PublicNavbar';
import MainLayout from './MainLayout';
import './MainLayout.scss';

interface PublicLayoutProps {
  showBackArrow?: boolean;
  showSignIn?: boolean;
  showFooter?: boolean;
}

const PublicLayout = ({
  showBackArrow = false,
  showSignIn = false,
  showFooter = false,
}: PublicLayoutProps): JSX.Element => {
  return (
    <MainLayout
      header={<PublicNavbar showBackArrow={showBackArrow} showSignIn={showSignIn} />}
      showFooter={showFooter}
    />
  );
};

export default PublicLayout;
