import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../../constants';
import CallOrTextModal from '../../../features/UserAgent/SalesRepInfo/CallOrTextModal';
import { useLTAnalytics } from '../../../hooks';
import UserAgent from '../../UserAgent/types/UserAgent';
import './OfferAgent.scss';

type RequestType = 'CONCIERGE' | 'SELF-GUIDED';

interface OfferAgentProps {
  requestType: RequestType;
  userAgent?: UserAgent;
}

const OfferAgent = ({ requestType = 'SELF-GUIDED', userAgent }: OfferAgentProps): JSX.Element => {
  const analyticsPhone = useLTAnalytics('Phone');
  const analyticsEmail = useLTAnalytics('Email');

  const [showCallOrTextModal, setShowCallOrTextModal] = useState(false);

  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });

  return (
    <>
      <div className="offer-agent">
        <div className="offer-agent__right">
          <div className="offer-agent__left-box">
            <div className="offer-agent__left-box--header">
              {requestType == 'CONCIERGE' ? 'CONCIERGE' : 'SELF-GUIDED'}
            </div>
          </div>
          {requestType == 'CONCIERGE' && (
            <div className="offer-agent__right-box">
              <div className="offer-agent__right-box--name">{userAgent?.name}</div>
              <span className="offer-agent__right-box--phone">
                {!isMediumDown && <span className="offer-agent__right-box--dot">.</span>}
                <span
                  className="offer-agent__phone"
                  onClick={() => setShowCallOrTextModal(true)}
                  {...analyticsPhone}
                >
                  {userAgent?.phone}
                </span>
                <CallOrTextModal
                  name={userAgent?.name}
                  phone={userAgent?.phone}
                  photo={userAgent?.profilePhotoAsBase64String}
                  show={showCallOrTextModal}
                  onClickSetShowModal={(showModal) => setShowCallOrTextModal(showModal)}
                />
              </span>
              <span className="offer-agent__right-box--email">
                {!isMediumDown && <span className="offer-agent__right-box--dot">.</span>}
                <a href={`mailto:${userAgent?.email}`} {...analyticsEmail}>
                  {userAgent?.email}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OfferAgent;
