import { HTMLAttributes } from 'react';
import './CreditScoreGauge.scss';

export type CreditScoreGaugeProps = HTMLAttributes<SVGSVGElement> & {
  score: number;
};

type Band = {
  minScore: number;
  maxScore: number;
  minPercentValue: number;
  maxPercentValue: number;
};

const getFillWidth = (fillPercent: number, creditScore: number) => {
  const maxWidth = 437;
  const fillWidth = maxWidth * fillPercent;

  if (creditScore <= 285) return fillWidth + 5;

  if (creditScore >= 535) return fillWidth - 5;

  return fillWidth;
};

const getPointerClass = (creditScore: number) => {
  if (creditScore <= 359) {
    return 'poor';
  } else if (creditScore <= 389) {
    return 'fair';
  } else if (creditScore <= 419) {
    return 'good';
  } else {
    return 'excellent';
  }
};

const getFillPercentValue = (creditScore: number) => {
  if (creditScore < 224) return 0;
  if (creditScore > 580) return 1;

  const band = {} as Band;

  if (creditScore <= 359) {
    band.minScore = 224;
    band.maxScore = 359;
    band.minPercentValue = 0;
    band.maxPercentValue = 0.2;
  } else if (creditScore <= 389) {
    band.minScore = 360;
    band.maxScore = 389;
    band.minPercentValue = 0.26;
    band.maxPercentValue = 0.47;
  } else if (creditScore <= 419) {
    band.minScore = 390;
    band.maxScore = 419;
    band.minPercentValue = 0.53;
    band.maxPercentValue = 0.74;
  } else if (creditScore <= 580) {
    band.minScore = 420;
    band.maxScore = 580;
    band.minPercentValue = 0.8;
    band.maxPercentValue = 1;
  }

  const bandPercentRange = band.maxPercentValue - band.minPercentValue;
  const bandScoreRange = band.maxScore - band.minScore;
  const scoreRangeWithinBand = creditScore - band.minScore;

  return band.minPercentValue + (bandPercentRange * scoreRangeWithinBand) / bandScoreRange;
};

const getRotationAngle = (fillPercent: number) => {
  const maxRotationAngle = 43;
  return 2 * maxRotationAngle * fillPercent - maxRotationAngle;
};

export const CreditScoreGauge: React.FC<CreditScoreGaugeProps> = ({ score, ...props }) => {
  const fillPercent = getFillPercentValue(score);
  const fillWidth = getFillWidth(fillPercent, score);
  const rotationAngle = getRotationAngle(fillPercent);
  const pointerClass = getPointerClass(score);

  return (
    <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 506 260" {...props}>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="linear-gradient"
          x1="0%"
          x2="0%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" style={{ stopColor: 'rgb(8, 193, 119)', stopOpacity: 1 }}></stop>
          <stop offset="50%" style={{ stopColor: 'rgb(7, 160, 124)', stopOpacity: 1 }}></stop>
          <stop offset="100%" style={{ stopColor: 'rgb(5, 118, 118)', stopOpacity: 1 }}></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#666666" id="group-muted" transform="translate(60, -30)">
          <g fillRule="nonzero" id="group-muted-bands" opacity="0.199423147" transform="rotate(9)">
            <g transform="rotate(2)">
              <path
                d="M73.9600256,59.7566339 C45.7386121,80.2606912 21.5071411,104.942458 1.87053498,132.914568 C0.212671346,135.276175 0.783167936,138.5346 3.14477479,140.192463 C5.50638164,141.850327 8.76480627,141.279831 10.4226699,138.918223 C29.3987338,111.887048 52.8166775,88.033926 80.1018299,68.2101024 C82.4361908,66.51409 82.9536744,63.2468269 81.257662,60.912466 C79.5616496,58.5781051 76.2943865,58.0606215 73.9600256,59.7566339 Z"
                fillRule="nonzero"
                id="Poor--Muted"
              ></path>
              <path
                d="M187.224423,8.13907428 C153.234901,15.9861737 121.28578,29.2378701 92.2806149,47.3136994 C89.83179,48.839791 89.083767,52.0620976 90.6098586,54.5109223 C92.1359502,56.9597472 95.3582568,57.7077703 97.8070816,56.1816787 C125.836562,38.7138896 156.71305,25.9070947 189.574951,18.3203271 C192.386426,17.6712469 194.139393,14.8659124 193.490313,12.054437 C192.841233,9.24296165 190.035898,7.48999405 187.224423,8.13907428 Z"
                fillRule="nonzero"
                id="Fair--Muted"
              ></path>
              <path
                d="M311.653438,4.88078586 C277.299813,-1.17668521 242.71264,-1.46195059 208.950456,3.84371718 C206.10001,4.29165985 204.152395,6.96553269 204.600338,9.81597935 C205.04828,12.666426 207.722153,14.6140402 210.5726,14.1660976 C243.199082,9.03890356 276.625053,9.31459167 309.838977,15.1711028 C312.68057,15.6721522 315.390316,13.7747672 315.891366,10.9331746 C316.392415,8.09158202 314.49503,5.38183529 311.653438,4.88078586 Z"
                id="Good---Muted"
              ></path>
              <path
                d="M427.464065,50.499805 C398.208228,31.5008425 366.482031,17.7239698 333.330686,9.41592626 C330.531811,8.71450142 327.694258,10.4148208 326.992833,13.2136959 C326.291408,16.0125711 327.991727,18.8501248 330.790602,19.5515497 C362.826791,27.5801247 393.48784,40.8944641 421.773097,59.2631254 C424.193021,60.8346424 427.428724,60.1468727 429.000242,57.7269486 C430.571758,55.3070244 429.883989,52.071322 427.464065,50.499805 Z"
                id="Excellent----Muted"
              ></path>
            </g>
          </g>
          <g
            fontFamily="Lato-Bold, Lato"
            fontSize="12"
            fontWeight="bold"
            id="group-muted-labels"
            opacity="0.302176339"
          >
            <g id="Poor" transform="rotate(-44) translate(-79, 90)">
              <text id="POOR-(224-359)" transform="rotate(8)">
                <tspan x="5" y="14.5">
                  POOR
                </tspan>
                <tspan x="-4" y="27.5">
                  (224-359)
                </tspan>
              </text>
            </g>
            <g id="fair" transform="rotate(-19) translate(82, 97)">
              <text id="FAIR-(360-389)" transform="rotate(8)">
                <tspan x="11" y="14.5">
                  FAIR
                </tspan>
                <tspan x="-1.5" y="27.5">
                  (360-389)
                </tspan>
              </text>
            </g>
            <g id="good" transform="rotate(3) translate(237, 45)">
              <text id="GOOD-(380-419)" transform="rotate(8)">
                <tspan x="2" y="14.5">
                  GOOD
                </tspan>
                <tspan x="-6" y="27.5">
                  (390-419)
                </tspan>
              </text>
            </g>
            <g id="excellent" transform="rotate(27) translate(342, -75)">
              <text id="EXCELLENT-(420-580)" transform="rotate(8)">
                <tspan x="1" y="16">
                  EXCELLENT
                </tspan>
                <tspan x="5" y="30">
                  (420-580)
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g id="group-clipped" transform="translate(35, -30)">
          <mask fill="white" id="fill-mask">
            <rect height="160" id="fill-mask-rect" width={fillWidth} x="0" y="0"></rect>
          </mask>
          <g fill="url(#linear-gradient)" id="group-clipped-bands" mask="url(#fill-mask)">
            <g transform="translate(25, 0)">
              <g transform="rotate(11)">
                <path
                  d="M73.9600256,59.7566339 C45.7386121,80.2606912 21.5071411,104.942458 1.87053498,132.914568 C0.212671346,135.276175 0.783167936,138.5346 3.14477479,140.192463 C5.50638164,141.850327 8.76480627,141.279831 10.4226699,138.918223 C29.3987338,111.887048 52.8166775,88.033926 80.1018299,68.2101024 C82.4361908,66.51409 82.9536744,63.2468269 81.257662,60.912466 C79.5616496,58.5781051 76.2943865,58.0606215 73.9600256,59.7566339 Z"
                  fillRule="nonzero"
                  id="Poor"
                ></path>
                <path
                  d="M187.224423,8.13907428 C153.234901,15.9861737 121.28578,29.2378701 92.2806149,47.3136994 C89.83179,48.839791 89.083767,52.0620976 90.6098586,54.5109223 C92.1359502,56.9597472 95.3582568,57.7077703 97.8070816,56.1816787 C125.836562,38.7138896 156.71305,25.9070947 189.574951,18.3203271 C192.386426,17.6712469 194.139393,14.8659124 193.490313,12.054437 C192.841233,9.24296165 190.035898,7.48999405 187.224423,8.13907428 Z"
                  fillRule="nonzero"
                  id="Fair"
                ></path>
                <path
                  d="M311.653438,4.88078586 C277.299813,-1.17668521 242.71264,-1.46195059 208.950456,3.84371718 C206.10001,4.29165985 204.152395,6.96553269 204.600338,9.81597935 C205.04828,12.666426 207.722153,14.6140402 210.5726,14.1660976 C243.199082,9.03890356 276.625053,9.31459167 309.838977,15.1711028 C312.68057,15.6721522 315.390316,13.7747672 315.891366,10.9331746 C316.392415,8.09158202 314.49503,5.38183529 311.653438,4.88078586 Z"
                  fillRule="nonzero"
                  id="Good"
                ></path>
                <path
                  d="M427.464065,50.499805 C398.208228,31.5008425 366.482031,17.7239698 333.330686,9.41592626 C330.531811,8.71450142 327.694258,10.4148208 326.992833,13.2136959 C326.291408,16.0125711 327.991727,18.8501248 330.790602,19.5515497 C362.826791,27.5801247 393.48784,40.8944641 421.773097,59.2631254 C424.193021,60.8346424 427.428724,60.1468727 429.000242,57.7269486 C430.571758,55.3070244 429.883989,52.071322 427.464065,50.499805 Z"
                  fillRule="nonzero"
                  id="Very-Good"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g
          className={`speedometer__pointer--${pointerClass}`}
          fillRule="nonzero"
          id="speedometer-pointer-wrapper"
          transform="translate(253, 326)"
        >
          <g id="speedometer-pointer" transform={`rotate(${rotationAngle} 0 0)`}>
            <circle
              cx="0"
              cy="-308"
              fill="#08C177"
              fillOpacity="0.3"
              fillRule="nonzero"
              id="speedometer-pointer-outside"
              mask="url(#pointer-mask)"
              r="18"
            ></circle>
            <circle
              cx="0"
              cy="-308"
              fillRule="nonzero"
              id="speedometer-pointer-inside"
              r="10"
              stroke="white"
              strokeWidth="1.25"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};
