import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dashboardApi } from '../../../app/services/dashboard';
import { RootState } from '../../../app/store';
import BusinessCreditWheel from './types/BusinessCreditWheel';

export type BusinessCreditWheelState = BusinessCreditWheel;

const initialState: BusinessCreditWheelState = {
  id: '',
  hasEquifaxCommunicationFailed: false,
  status: '',
  score: 0,
  reasonCodes: [],
};

const reducers = {
  setBusinessCreditWheel: (
    state: BusinessCreditWheelState,
    action: PayloadAction<BusinessCreditWheel>
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const businessCreditWheelSlice = createSlice({
  name: 'businessCreditWheel',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getBusinessCreditWheel.matchFulfilled,
      reducers.setBusinessCreditWheel
    );
  },
});

export default businessCreditWheelSlice.reducer;

// Actions
export const { setBusinessCreditWheel } = businessCreditWheelSlice.actions;

// Selectors
export const selectBusinessCreditWheel = (state: RootState): BusinessCreditWheel =>
  state.dashboard.businessCreditWheel;
