import classNames from 'classnames';
import { ColumnProps, HeaderProps } from '.';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { uniqueKey } from '../../../utils';
import CheckmarkImage from '../images/checkmark.png';
import './Tabular.scss';

interface TableProps {
  className?: string;
  columnData: ColumnProps[];
  headerData: HeaderProps;
}

const Tabular = ({ className, columnData, headerData }: TableProps): JSX.Element => {
  const headerItems = (
    <FlexRow key={uniqueKey.generateKey('tr')} className={classNames('tr', className)}>
      {headerData?.data?.map((currElement) => (
        <FlexColumn
          key={uniqueKey.generateKey('th')}
          className={classNames('tabular-init__th', className)}
          columnsMedium={currElement.colsize}
        >
          {currElement.text}
        </FlexColumn>
      ))}
    </FlexRow>
  );

  const columnItems = columnData?.map((rowElement) => (
    <FlexRow
      key={uniqueKey.generateKey('tr')}
      className={classNames('tabular-init__tr', className)}
    >
      {rowElement?.data?.map((currElement) => (
        <FlexColumn
          key={uniqueKey.generateKey('td')}
          className={classNames('tabular-init__td', className)}
          columnsMedium={currElement.colsize}
        >
          <span data-opportunity-id={currElement.opportunityId}>
            {currElement.inputType == 'text' && currElement.text}
            {currElement.inputType == 'bool' && currElement.text?.toLowerCase() == 'true' && (
              <img alt="check-mark" src={CheckmarkImage} />
            )}
            {currElement.inputType == 'bool' &&
              currElement.text?.toLowerCase() != 'true' &&
              currElement.text}
          </span>
        </FlexColumn>
      ))}
    </FlexRow>
  ));

  return (
    <FlexRow className="tabular-init">
      <FlexColumn className={classNames('tabular-init__thead', className)}>
        {headerItems}
      </FlexColumn>
      <FlexColumn className={classNames('tabular-init__tbody', className)}>
        {columnItems}
      </FlexColumn>
    </FlexRow>
  );
};

export default Tabular;
