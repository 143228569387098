import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { documentsApi } from '../../app/services/documents';
import { RootState } from '../../app/store';
import DocumentRequest from './types/DocumentRequest';
import Stip from './types/Stip';
import UploadedDocument from './types/UploadedDocument';

const documentRequestsAdapter = createEntityAdapter<DocumentRequest>();
export type DocumentRequestState = EntityState<DocumentRequest>;

type AddUploadedDocumentAction = {
  payload: { requestId?: string; documents?: UploadedDocument[] | null };
};

type RemoveUploadedDocumentAction = {
  payload: { requestId?: string; documentId?: string };
};

const findMatchingStip = (document?: UploadedDocument, request?: DocumentRequest) => {
  if (document == null) return -1;
  return request?.stips?.findIndex((stip) => stip.name === document.documentType) ?? -1;
};

const reducers = {
  addUploadedDocuments: (state: DocumentRequestState, action: AddUploadedDocumentAction) => {
    const { requestId, documents } = action.payload;
    const { entities } = state;

    if (requestId == null || documents == null) {
      return;
    }

    const request = entities[requestId] ?? ({} as DocumentRequest);
    const prev = request.uploadedDocuments ?? ([] as UploadedDocument[]);
    const updates = [...prev, ...documents];

    for (const document of documents) {
      const stipIndex = findMatchingStip(document, entities[requestId]);

      if (request?.stips?.[stipIndex] != null) {
        ((entities[requestId] as DocumentRequest).stips as Stip[])[
          stipIndex
        ].uploaded_to_Borrower_Portal__c = true;
      }
    }

    (entities[requestId] as DocumentRequest).uploadedDocuments = updates;
  },
  removeUploadedDocument: (state: DocumentRequestState, action: RemoveUploadedDocumentAction) => {
    const { requestId, documentId } = action.payload;
    const { entities } = state;

    if (requestId == null || documentId == null) {
      return;
    }

    const request = entities[requestId] ?? ({} as DocumentRequest);
    const updates = request.uploadedDocuments?.filter((doc) => doc.id !== documentId);
    const document = request.uploadedDocuments?.find((doc) => doc.id === documentId);

    const stipIndex = findMatchingStip(document, entities[requestId]);

    if (request?.stips?.[stipIndex] != null) {
      ((entities[requestId] as DocumentRequest).stips as Stip[])[
        stipIndex
      ].uploaded_to_Borrower_Portal__c = false;
    }

    (entities[requestId] as DocumentRequest).uploadedDocuments = updates;
  },
  setDocumentRequests: (state: DocumentRequestState, action: PayloadAction<DocumentRequest[]>) => {
    documentRequestsAdapter.setAll(state, action.payload);
  },
};

export const documentRequestsSlice = createSlice({
  name: 'documentRequests',
  initialState: documentRequestsAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      documentsApi.endpoints.getDocumentRequests.matchFulfilled,
      reducers.setDocumentRequests
    );
  },
});

export default documentRequestsSlice.reducer;

// Actions
export const {
  setDocumentRequests,
  addUploadedDocuments,
  removeUploadedDocument,
} = documentRequestsSlice.actions;

// Selectors
const documentRequestsSelectors = documentRequestsAdapter.getSelectors<RootState>(
  (state) => state.documents.documentRequests
);

export const selectDocumentApplications = (state: RootState): DocumentRequest[] =>
  documentRequestsSelectors.selectAll(state);
