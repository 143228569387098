import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import './LogoutCallback.scss';
import { useAuth } from './useAuth';

const LogoutCallback = (): JSX.Element => {
  const auth = useAuth();
  const { logoutCallback } = auth;
  const navigate = useNavigate();

  useEffect(() => {
    logoutCallback().finally(() => {
      window.sessionStorage.removeItem('BL_Applications_Event_Tracked');
      window.sessionStorage.removeItem('hasSeenIncompleteApplicationModal');
      window.sessionStorage.removeItem('BL_Renewals_Dashboard_Event_Tracked');
      navigate('/');
    });
  }, [logoutCallback, navigate]);

  return (
    <div className="logout-callback">
      <h2>Logging Out...</h2>
      <Loader centered={true} />
    </div>
  );
};

export default LogoutCallback;
