import { Fragment, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { useGetUserAgentQuery } from '../../../app/services/dashboard';
import { Modal, ModalProps, Mode } from '../../../components/Modal';
import { useModalAnalytics } from '../../../hooks';
import CallOrTextModal from '../../UserAgent/SalesRepInfo/CallOrTextModal';
import UserAgent from '../../UserAgent/types/UserAgent';
import { selectUserAgent } from '../../UserAgent/userAgentSlice';
import './OpenOpportunityModal.scss';

type OpenOpportunityModalProps = Pick<ModalProps, 'show' | 'onClose'>;

const ConciergeContact: React.FC<{ userAgent: UserAgent }> = ({ userAgent }) => {
  const [showCallOrTextModal, setShowCallOrTextModal] = useState(false);

  const handleContactNumberClick = () => {
    window.ltanalytics.track('BL Portal CTA', { bl_cta_text: 'Phone' });
    setShowCallOrTextModal(true);
  };

  return (
    <div className="openOpportunity__concierge">
      <div className="openOpportunity__concierge-left">
        Your
        <br />
        Concierge
      </div>
      <div className="openOpportunity__concierge-right">
        <strong>{userAgent.name}</strong>
        <br />
        <span className="openOpportunity__concierge-number" onClick={handleContactNumberClick}>
          {userAgent.phone}
        </span>
      </div>
      <CallOrTextModal
        name={userAgent?.name}
        phone={userAgent?.phone}
        photo={userAgent?.profilePhotoAsBase64String}
        show={showCallOrTextModal}
        onClickSetShowModal={(showModal) => setShowCallOrTextModal(showModal)}
      />
    </div>
  );
};

export default function OpenOpportunityModal(props: OpenOpportunityModalProps): JSX.Element | null {
  const [step, setStep] = useState(0);
  const { isFetching } = useGetUserAgentQuery();
  const userAgent = useAppSelector(selectUserAgent);
  const handleModalClose = useModalAnalytics(props, 'BL Open Opportunity Modal');

  const handleClose = () => {
    handleModalClose();
    setStep(0);
  };

  return !isFetching ? (
    <Modal
      center={true}
      mode={Mode.Modal}
      show={props.show}
      size="large"
      title=""
      onClose={handleClose}
    >
      <div className="openOpportunity__modal">
        {step === 0 && (
          <Fragment>
            <p className="openOpportunity__copy">
              We see that you&apos;re trying to submit an additional application and you have an
              active funding request. To move forward with your active funding request or to ask any
              questions, <strong>please reach out to your Concierge:</strong>
            </p>
            <ConciergeContact userAgent={userAgent} />
          </Fragment>
        )}
        {step === 1 && (
          <Fragment>
            <p className="openOpportunity__copy">
              We see that you already have an active funding request. Your active application will
              allow you to request funding for multiple needs.{' '}
              <strong>
                Just let your Concierge know that you need funding for multiple purposes.
              </strong>
            </p>
            <ConciergeContact userAgent={userAgent} />
          </Fragment>
        )}
      </div>
    </Modal>
  ) : null;
}
