import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../src/app/store';
import { profileApi } from '../../app/services';

export type BusinessIndustriesState = [];

const initialState: BusinessIndustriesState = [];

const reducers = {
  setBusinessIndustries: (state: BusinessIndustriesState, action: PayloadAction<[]>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const businessIndustriesSlice = createSlice({
  name: 'businessIndustries',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.getBusinessIndustries.matchFulfilled,
      reducers.setBusinessIndustries
    );
  },
});

export default businessIndustriesSlice.reducer;

// Actions
export const { setBusinessIndustries } = businessIndustriesSlice.actions;

// Selectors
export const selectBusinessIndustries = (state: RootState): [] => state.profile.businessIndustries;
