import { ReactNode, useEffect } from 'react';
import useCheckAuthStatus from '../../hooks/useCheckAuthStatus';
import { useAuth } from './useAuth';

interface RequireAuthProps {
  children: ReactNode;
}
const RequireAuth = ({ children }: RequireAuthProps): JSX.Element => {
  const { login } = useAuth();
  const [isUserLoggedIn, isCheckingAuthStatus] = useCheckAuthStatus();

  useEffect(() => {
    if (!isCheckingAuthStatus && !isUserLoggedIn) {
      login();
    }
  }, [isCheckingAuthStatus, isUserLoggedIn, login]);

  // While checking if `isLoggedIn()` or while executing `login()`
  if (isCheckingAuthStatus || (!isCheckingAuthStatus && !isUserLoggedIn)) {
    return <></>;
  }

  return <>{children}</>;
};

export default RequireAuth;
