import { NavLink, NavLinkProps } from 'react-router-dom';
import { IconNames, LtIcon } from '../../../components/Icon';
import './ResourcesMenuLink.scss';

interface ResourcesMenuLinkProps extends NavLinkProps {
  iconName: IconNames;
}

const ResourcesMenuLink = ({
  children,
  iconName,
  to,
  ...props
}: ResourcesMenuLinkProps): JSX.Element => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? 'resources-menu-link resources-menu-link--active' : 'resources-menu-link'
      }
      to={to}
      {...props}
    >
      <LtIcon name={iconName} />
      {children}
    </NavLink>
  );
};

export default ResourcesMenuLink;
