type PersonalCreditBandNames = 'poor' | 'fair' | 'good' | 'veryGood' | 'excellent';

const personal: {
  [key in PersonalCreditBandNames]: { min: number; max: number };
} = {
  poor: { min: 300, max: 579 },
  fair: { min: 580, max: 669 },
  good: { min: 670, max: 739 },
  veryGood: { min: 740, max: 799 },
  excellent: { min: 800, max: 850 },
};

const CREDIT_BANDS = {
  personal,
};

export default CREDIT_BANDS;
