import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../../constants';
import BusinessCreditGraphPoints from './types/BusinessCreditGraphPoints';

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, Title, Legend, Tooltip);

interface BusinessCreditLineChartProps {
  creditPullDates: Date[];
  labels: string[];
  toolTipScores: number[];
  adjustedScores: number[];
}

interface BusinessCreditGraphProps {
  graphPoints: BusinessCreditGraphPoints[];
}
const MinimumCreditScore = 224;
const MaximumCreditScore = 580;

function AdjustCreditScore(score: number | undefined): number {
  if (score === undefined) return 0;
  if (score !== null && score <= MinimumCreditScore) return MinimumCreditScore;
  if (score !== null && score >= MaximumCreditScore) return MaximumCreditScore;
  return score;
}

const BusinessCreditGraph = ({ graphPoints }: BusinessCreditGraphProps): JSX.Element => {
  const creditPullDates = graphPoints.map((x) => new Date(x.creditPullDate));
  const labels = graphPoints.map((x) =>
    new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric', day: 'numeric' }).format(
      new Date(x.creditPullDate)
    )
  );
  const adjustedScores = graphPoints.map((x) => AdjustCreditScore(x.score));
  const toolTipCreditScores = graphPoints.map((x) => x.score);

  return (
    <div>
      {
        <LineChart
          adjustedScores={adjustedScores}
          creditPullDates={creditPullDates}
          labels={labels}
          toolTipScores={toolTipCreditScores}
        ></LineChart>
      }
    </div>
  );
};

const LineChart = ({
  creditPullDates,
  labels,
  toolTipScores,
  adjustedScores,
}: BusinessCreditLineChartProps): JSX.Element => {
  const isXsUp = useMediaQuery({ query: MEDIA_QUERIES.xsUp });

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Credit Scores',
        data: adjustedScores,
        borderColor: 'rgba(0, 178, 159, 1)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        pointBorderColor: '#078181',
        pointBorderWidth: 8,
        pointBackgroundColor: '#00b29f',
        pointRadius: 4,
        borderWidth: 4,
        lineTension: 0,
        clip: 10,
      },
    ],
  };
  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        caretPadding: 10,
        displayColors: false,
        enabled: true,
        bodyFont: {
          size: 10,
        },
        titleFont: {
          size: 10,
        },
        backgroundColor: 'rgba(69,69,69,1)',
        callbacks: {
          label: function (context: TooltipItem<'line'>) {
            const currentScore = toolTipScores[context.dataIndex];
            return 'Score: ' + currentScore;
          },
        },
      },
    },
    scales: {
      y: {
        min: MinimumCreditScore,
        max: MaximumCreditScore,
        ticks: {
          padding: 0.5,
          stepSize: 89,
        },
        beginAtZero: false,
        grid: {
          drawBorder: false,
        },
      },
      x: {
        offset: true,
        ticks: {
          padding: 0.5,
          callback: function (_, index) {
            const currentCreditPullDate = creditPullDates[index];
            const yDate = new Intl.DateTimeFormat('en-US', {
              month: 'short',
              year: 'numeric',
            }).format(new Date(currentCreditPullDate));
            if (isXsUp) return yDate;
            return yDate;
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };
  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};
export default BusinessCreditGraph;
