import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { MEDIA_QUERIES } from '../../../constants';
import { dateFormat, uniqueKey } from '../../../utils';
import { LenderDetails, LenderDetailsRow } from '../MobileView';
import { ColumnProps, HeaderProps, Tabular } from '../Tabular';
import './ExpiredOffers.scss';
import ExpiredOffer from './types/ExpiredOffer';

interface ExpiredOffersProps {
  expiredOffers: ExpiredOffer[];
}

const ExpiredOffers = ({ expiredOffers }: ExpiredOffersProps): JSX.Element => {
  const computeHeader = (): HeaderProps => {
    return {
      data: [
        { colsize: 2, text: 'Requested Date' },
        { colsize: 3, text: 'Request ID' },
        { colsize: 2, text: 'Amount Requested' },
        { colsize: 2, text: 'Approved' },
        { colsize: 3, text: 'Offer Ranges' },
      ],
    };
  };

  const formattedHeaderData = useMemo(() => computeHeader(), []);

  const formattedData: ColumnProps[] = expiredOffers.map((expiredOffer) => {
    return {
      data: [
        {
          colsize: 2,
          inputType: 'text',
          text: dateFormat({ date: expiredOffer?.requestDate, includeTime: false }),
        },
        {
          colsize: 3,
          inputType: 'text',
          opportunityId: expiredOffer.opportunityId,
          text: expiredOffer.applicationId,
        },
        { colsize: 2, inputType: 'text', text: expiredOffer.requestedAmount },
        { colsize: 2, inputType: 'bool', text: expiredOffer.approvedOffer },
        { colsize: 3, inputType: 'text', text: expiredOffer.offerRanges },
      ],
    };
  });

  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });

  const expiredOffersMobile = expiredOffers.map((expiredOffer, index) => (
    <LenderDetails key={index} lenderName="">
      <LenderDetailsRow
        key={uniqueKey.generateKey('Requested_Date')}
        name="Requested Date"
        opportunityId=""
        value={dateFormat({ date: expiredOffer?.requestDate, includeTime: false })}
      />
      <LenderDetailsRow
        key={uniqueKey.generateKey('Request_ID')}
        name="Request ID"
        opportunityId=""
        value={expiredOffer.applicationId}
      />
      <LenderDetailsRow
        key={uniqueKey.generateKey('Amount_Requested')}
        name="Amount Requested"
        opportunityId={expiredOffer.opportunityId}
        value={expiredOffer.requestedAmount}
      />
      <LenderDetailsRow
        key={uniqueKey.generateKey('Approved')}
        inputType="bool"
        name="Approved"
        opportunityId=""
        value={expiredOffer.approvedOffer}
      />
      <LenderDetailsRow
        key={uniqueKey.generateKey('Offer_Ranges')}
        name="Offer Ranges"
        opportunityId=""
        value={expiredOffer.offerRanges}
      />
    </LenderDetails>
  ));

  return (
    <>
      {expiredOffers.length > 0 && (
        <div className="expired-offers">
          <FlexRow>
            <FlexColumn columns={12}>
              <h3 className="expired-offers__title">Your Expired Offers</h3>
            </FlexColumn>
          </FlexRow>
          {!isSmallDown && (
            <FlexRow>
              <FlexColumn className="expired-offers__table mb-3" columns={12}>
                <Tabular columnData={formattedData} headerData={formattedHeaderData} />
              </FlexColumn>
            </FlexRow>
          )}
          {isSmallDown && (
            <FlexRow>
              <FlexColumn className="expired-offers__mobile mb-3" columns={12}>
                {expiredOffersMobile}
              </FlexColumn>
            </FlexRow>
          )}
        </div>
      )}
    </>
  );
};

export default ExpiredOffers;
