import { useMediaQuery } from 'react-responsive';
import { Button } from '../../../../components/Button';
import { MEDIA_QUERIES } from '../../../../constants';
import { useLTAnalytics } from '../../../../hooks';
import './Summary.scss';

interface SummaryProps {
  applicationId?: string;
  applicationUrl?: string;
  calendlyC?: string;
  hasExchangeRequests: boolean;
  loanNumber?: string;
  minUploadDocumentCount?: number;
  opportunityId?: string;
  status?: string;
  stipsCount?: number;
  onScheduleACallModal: () => void;
}

const Summary = ({
  applicationId,
  applicationUrl,
  calendlyC,
  hasExchangeRequests,
  loanNumber,
  minUploadDocumentCount,
  opportunityId,
  status,
  stipsCount,
  onScheduleACallModal,
}: SummaryProps): JSX.Element => {
  const statusOfRequest = status?.replace(/ /g, '').toLowerCase();
  const hasStips = stipsCount != null ? stipsCount > 0 : false;
  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });

  const ctaUrl = (): string => {
    switch (statusOfRequest) {
      case 'completeprofile':
        return applicationUrl ?? '';
      case 'uploaddocuments':
        return '/app/documents/' + applicationId;
      case 'reviewoffers':
        return hasStips ? '/app/documents/' + applicationId : calendlyC ?? '';
      default:
        return 'javascript:void(0)';
    }
  };

  const cTAUrlTarget = (): string => {
    return statusOfRequest === 'completeprofile' ||
      (statusOfRequest === 'reviewoffers' && stipsCount !== undefined && stipsCount < 0)
      ? '_blank'
      : '_self';
  };

  const buttonText = (): string => {
    switch (statusOfRequest) {
      case 'completeprofile':
        return 'Complete Profile';
      case 'uploaddocuments':
        return `Upload ${minUploadDocumentCount} statements`;
      case 'reviewoffers':
        return hasStips ? `Upload ${stipsCount} Documents` : 'Schedule a Call';
      case 'completeapplication':
        return 'Complete Application';
      default:
        return '';
    }
  };

  const requestNumber = (): string => {
    return statusOfRequest === 'loanfunded' ? loanNumber ?? '' : applicationId ?? '';
  };

  const isToDoVisible = (): boolean => {
    return (
      statusOfRequest != 'curatingoffers' &&
      statusOfRequest != 'funding' &&
      statusOfRequest != 'loanfunded' &&
      statusOfRequest != 'requestnotfunded'
    );
  };

  const toDoText = (): string => {
    switch (statusOfRequest) {
      case 'completeprofile':
        return 'Your profile is incomplete. Please finish it so your concierge can help you find your best funding offer.';
      case 'uploaddocuments':
        return `Please upload your ${minUploadDocumentCount} most recent bank statements so we can find you funding offers.`;
      case 'curatingoffers':
        return "Get ready! Your concierge is busy searching our network to find you the best funding options for your business. You'll be alerted as soon as they have offers for you to review.";
      case 'reviewoffers':
        return hasStips
          ? `Please upload the ${stipsCount} required documents so that we can get you one step closer to getting your business funded.`
          : 'Your concierge has found some offers for your review. Please schedule a call with them to get the details.';
      case 'funding':
        return 'Your concierge is finalizing the details of your funding and will be contacting you throughout the process.';
      case 'loanfunded':
        return '';
      case 'requestnotfunded':
        return '';
      case 'completeapplication':
        return 'Your application is incomplete. Please finish it so your concierge can help you find your best funding offer.';
      default:
        return '';
    }
  };

  // TODO : Refactor this one ( Remove)
  const showCTA = (): boolean => {
    if (
      statusOfRequest === 'completeprofile' ||
      statusOfRequest === 'uploaddocuments' ||
      statusOfRequest === 'completeapplication' ||
      (statusOfRequest === 'reviewoffers' && (hasStips || calendlyC))
    ) {
      return true;
    } else return false;
  };

  const analyticsButton = useLTAnalytics(buttonText());

  return (
    <>
      <div className={`summary ${hasExchangeRequests ? 'border-top-left-radius-zero' : ''}`}>
        <div className="summary__label">Request</div>
        <div className="summary__number" data-opportunity-id={opportunityId}>
          {requestNumber()}
        </div>
        <div className="summary__text">
          {isToDoVisible() && <span className="summary__text--todo-bold">TO DO: </span>}
          {toDoText()}
        </div>
        {showCTA() &&
          (statusOfRequest === 'completeprofile' || statusOfRequest === 'completeapplication') && (
            <a className="summary__action-cta" href={applicationUrl} {...analyticsButton}>
              {buttonText()}
            </a>
          )}

        {showCTA() && statusOfRequest === 'uploaddocuments' && (
          <a
            className="summary__action-cta"
            href={ctaUrl()}
            rel="noopener noreferrer"
            target={cTAUrlTarget()}
            {...analyticsButton}
          >
            {buttonText()}
          </a>
        )}
        {showCTA() && statusOfRequest === 'reviewoffers' && (
          <>
            {hasStips ? (
              <a
                className="summary__action-cta"
                href={ctaUrl()}
                rel="noopener noreferrer"
                target={cTAUrlTarget()}
                {...analyticsButton}
              >
                {buttonText()}
              </a>
            ) : !isMediumDown ? (
              <Button
                className="summary__action-cta"
                onClick={() => onScheduleACallModal()}
                {...analyticsButton}
              >
                {buttonText()}
              </Button>
            ) : (
              <a
                className="summary__action-cta"
                href={ctaUrl()}
                rel="noopener noreferrer"
                target={cTAUrlTarget()}
                {...analyticsButton}
              >
                {buttonText()}
              </a>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Summary;
