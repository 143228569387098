import { useMediaQuery } from 'react-responsive';
import { FlexColumn, FlexRow } from '../../../../components/Flex';
import { LtIcon } from '../../../../components/Icon';
import { Modal } from '../../../../components/Modal';
import { Tooltip } from '../../../../components/Tooltip';
import { MEDIA_QUERIES } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import Stips from '../../types/Stip';
import UploadedDocument from '../../types/UploadedDocument';
import Upload from '../Upload/Upload';
import './RequiredDocuments.scss';

const toolTipText =
  'Your dedicated loan advisor is requesting this document so that they can get you one step closer to getting your business funded.';

interface RequiredDocumentsProps {
  applicationId?: string;
  externalVisitorId?: string;
  hasAbilityToUploadDocument?: boolean;
  isStipsRequired?: boolean;
  opportunityId?: string;
  value?: Stips[];
  onUploadDocuments?: (documents: UploadedDocument[]) => void;
}

const RequiredDocuments = ({
  applicationId,
  externalVisitorId,
  hasAbilityToUploadDocument,
  isStipsRequired,
  opportunityId,
  value,
  onUploadDocuments,
}: RequiredDocumentsProps): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });
  const [showModal, setShowModal] = useToggle();
  return (
    <>
      {value
        ?.filter((document) => !document.uploaded_to_Borrower_Portal__c)
        .map((document) => (
          <tr key={document.name} className="required-documents__tbody-tr">
            <td className="required-documents__td">
              {!isMobile && isMediumDown && <>{document.name}</>}
              {isMobile && (
                <>
                  <p className="required-documents__td-green-bold">
                    MISSING&nbsp;
                    <span className="required-documents__tooltip" onClick={() => setShowModal()}>
                      <LtIcon name="Question" />
                    </span>
                  </p>
                  {document.name}
                  <Modal
                    center={true}
                    className="required-documents__td"
                    show={showModal}
                    title="What is this?"
                    onClose={() => setShowModal()}
                  >
                    {toolTipText}
                  </Modal>
                </>
              )}
              {!isMobile && !isMediumDown && (
                <FlexRow>
                  <FlexColumn columns={6}>{document.name}</FlexColumn>
                  <FlexColumn columns={6}>
                    <Tooltip content={toolTipText} placement="right">
                      <span className="required-documents__tooltip">What is this?</span>
                    </Tooltip>
                  </FlexColumn>
                </FlexRow>
              )}
            </td>
            <td className="required-documents__td">
              {!isMobile && isMediumDown && (
                <Tooltip content={toolTipText} placement="right">
                  <span className="required-documents__tooltip">What is this?</span>
                </Tooltip>
              )}
            </td>
            <td className="required-documents__td" />
            <td className="required-documents__td">
              {hasAbilityToUploadDocument && (
                <Upload
                  applicationId={applicationId}
                  externalVisitorId={externalVisitorId}
                  isStipsRequired={isStipsRequired}
                  opportunityId={opportunityId}
                  selectedStip={document.name}
                  showUploadIcon={true}
                  stipsList={value}
                  onUploadDocuments={onUploadDocuments}
                />
              )}
            </td>
          </tr>
        ))}
    </>
  );
};

export default RequiredDocuments;
