import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User as OidcUser } from 'oidc-client-ts';
import type { RootState } from '../../app/store';
import { User } from './types/User';

type AuthState = {
  user: User;
  token: string;
};

const initialState: AuthState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    authId: '',
  },
  token: '',
};

const reducers = {
  setCredentials: (state: AuthState, { payload: user }: PayloadAction<OidcUser | null>) => {
    state.user = {
      id: user?.profile?.name,
      firstName: user?.profile?.given_name,
      lastName: user?.profile?.family_name,
      authId: user?.profile?.sub,
    };
    state.token = user?.access_token ?? '';
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers,
});

export default authSlice.reducer;

export const { setCredentials } = authSlice.actions;

export const selectCurrentUser = (state: RootState): User | null => state.auth.user;
export const selectAccessToken = (state: RootState): string => state.auth.token;
