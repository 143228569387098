import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Container } from '../../../src/components/Container';
import { useAppSelector } from '../../app/hooks';
import { useGetMinUploadDocumentCountQuery } from '../../app/services/appSetting';
import {
  useGetDocumentRequestsQuery,
  useGetExchangeOffersQuery,
} from '../../app/services/documents';
import { FlexColumn, FlexRow } from '../../components/Flex';
import { selectSnapshot } from '../../slices/snapshotSlice';
import { FundingRequestType } from '../Dashboard/FundingRequests/types/FundingRequestType';
import HeroImage from '../HeroImage/HeroImage';
import { UserAgent } from '../UserAgent';
import DocumentRequests from './DocumentRequests/DocumentRequests';
import { selectDocumentApplications } from './documentRequestsSlice';
import './Documents.scss';

interface CollapsibleSetting {
  id?: string;
  isExpand: boolean | false;
}

const Documents = (): JSX.Element => {
  const [collapsibleSettings, setCollapsibleSettings] = useState(Array<CollapsibleSetting>());
  const [hideHeroImage, setHideHeroImage] = useState(false);
  const [subHeaderText, setSubHeaderText] = useState('');
  const [exchangeOffersText, setExchangeOffersText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const snapshot = useAppSelector(selectSnapshot);

  const { applicationId: paramApplicationId } = useParams();

  const { isLoading: isDocumentRequestsLoading } = useGetDocumentRequestsQuery();
  const {
    data: exchangeOfferData,
    isLoading: isExchangeOfferLoading,
  } = useGetExchangeOffersQuery();

  const {
    data: minUploadDocumentCountData,
    isLoading: isMinUploadDocumentCountLoading,
  } = useGetMinUploadDocumentCountQuery();

  const documentRequests = useAppSelector(selectDocumentApplications);
  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    element &&
      window.scrollTo({
        top: element?.offsetHeight,
        behavior: 'smooth',
      });
  };
  useEffect(() => {
    setHideHeroImage(
      !isDocumentRequestsLoading &&
        !isExchangeOfferLoading &&
        !isMinUploadDocumentCountLoading &&
        documentRequests.length === 0
    );
    setSubHeaderText(
      !isDocumentRequestsLoading && !isExchangeOfferLoading && !isMinUploadDocumentCountLoading
        ? 'Upload the documents below to secure your funding. PDF, PNG, and JPG files are acceptable.'
        : 'Loading...'
    );
    setExchangeOffersText(
      !isDocumentRequestsLoading &&
        !isExchangeOfferLoading &&
        !isMinUploadDocumentCountLoading &&
        documentRequests.length === 0
        ? 'You will need to upload documents directly to the lenders you are matched with.'
        : ''
    );

    setIsLoading(isDocumentRequestsLoading && isExchangeOfferLoading);

    const collapsibleSettingsData = Array<CollapsibleSetting>();

    documentRequests?.map((documentRequest, index) => {
      const collapsibleSetting = {} as CollapsibleSetting;
      collapsibleSetting.id = documentRequest?.applicationId;
      if (paramApplicationId) {
        collapsibleSetting.isExpand =
          documentRequest?.applicationId === paramApplicationId ? true : false;
      } else {
        collapsibleSetting.isExpand = index === 0 ? true : false;
      }
      collapsibleSettingsData.push(collapsibleSetting);
    });

    setCollapsibleSettings(collapsibleSettingsData);

    paramApplicationId && scrollTo('div_Scroll_To_' + paramApplicationId);
  }, [
    documentRequests,
    exchangeOfferData,
    isDocumentRequestsLoading,
    isExchangeOfferLoading,
    isMinUploadDocumentCountLoading,
    paramApplicationId,
  ]);

  const handleOnClickExpand = (applicationId: string) => {
    const updatedCollapsibleSettings = collapsibleSettings.map((item) =>
      item.id === applicationId
        ? { ...item, isExpand: !item.isExpand }
        : { ...item, isExpand: false }
    );
    setCollapsibleSettings(updatedCollapsibleSettings);
  };

  return (
    <div className="documents">
      <Container>
        <Helmet>
          <title>Document Center</title>
        </Helmet>
        <UserAgent
          displayUserAgent={snapshot.conciergeFundingRequests > 0}
          pageTitle="Document Center"
          subHeader={subHeaderText}
        />
        {!isLoading && (
          <>
            {exchangeOffersText && (
              <FlexRow>
                <FlexColumn columnsLarge={12}>
                  <div className="documents__info-alert">
                    <span className="documents__info-icon"></span>
                    {exchangeOffersText}
                  </div>
                </FlexColumn>
              </FlexRow>
            )}
            {!hideHeroImage && (
              <Container>
                {documentRequests
                  ?.filter(
                    (d) =>
                      d.fundingRequestType != FundingRequestType.BookedWonFundingRequest &&
                      d.fundingRequestType != FundingRequestType.ClosedLostFundingRequest
                  )
                  .map((documentRequest, index) => (
                    <span key={documentRequest?.opportunityId}>
                      {index == 0 && (
                        <h3 className="documents__subheader">Active Funding Requests</h3>
                      )}
                      <div
                        key={'div_key_' + documentRequest?.applicationId}
                        id={'div_Scroll_To_' + documentRequest?.applicationId}
                      >
                        <DocumentRequests
                          key={documentRequest?.applicationId}
                          documentRequest={documentRequest}
                          isExpand={
                            collapsibleSettings?.find(
                              (item) => item.id === documentRequest?.applicationId
                            )?.isExpand ?? false
                          }
                          minUploadDocumentCount={
                            minUploadDocumentCountData?.minUploadDocumentCount
                          }
                          onClickExpand={(applicationId) => handleOnClickExpand(applicationId)}
                        />
                      </div>
                    </span>
                  ))}
                {documentRequests
                  ?.filter(
                    (d) =>
                      d.fundingRequestType == FundingRequestType.BookedWonFundingRequest ||
                      d.fundingRequestType == FundingRequestType.ClosedLostFundingRequest
                  )
                  .map((documentRequest, index) => (
                    <span key={documentRequest?.opportunityId}>
                      {index == 0 && (
                        <h3 className="documents__subheader">Funding Request History</h3>
                      )}
                      <div
                        key={'div_key_' + documentRequest?.applicationId}
                        id={'div_Scroll_To_' + documentRequest?.applicationId}
                      >
                        <DocumentRequests
                          key={documentRequest?.applicationId}
                          documentRequest={documentRequest}
                          isExpand={
                            collapsibleSettings?.find(
                              (item) => item.id === documentRequest?.applicationId
                            )?.isExpand ?? false
                          }
                          minUploadDocumentCount={
                            minUploadDocumentCountData?.minUploadDocumentCount
                          }
                          onClickExpand={(applicationId) => handleOnClickExpand(applicationId)}
                        />
                      </div>
                    </span>
                  ))}
              </Container>
            )}
            {hideHeroImage && <HeroImage />}
          </>
        )}
      </Container>
    </div>
  );
};

export default Documents;
