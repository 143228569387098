import './ProgressBar.scss';

export interface ProgressBarProps {
  percent: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent }) => {
  return (
    <div className="progressbar-container">
      <div
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={percent}
        className="progressbar"
        role="progressbar"
        style={{
          width: `${percent}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
