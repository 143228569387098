import { Block } from '../../../components/Block';
import { Button } from '../../../components/Button';
import { Container } from '../../../components/Container';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { useLTAnalytics } from '../../../hooks';
import SproutSocialLogo from './images/sprout-social-logo.svg';

const marketingBlocks = [
  {
    image: SproutSocialLogo,
    link: 'https://www.anrdoezrs.net/click-8231194-14431208',
    text: 'Discover the most robust and cohesive social media business solution, built to scale.',
    title: 'Social Media Marketing',
  },
];

const Marketing = (): JSX.Element => {
  const analytics = useLTAnalytics('Sprout Social');

  return (
    <Container>
      <FlexRow rowGap={'20px'}>
        {marketingBlocks.map((marketingCard) => (
          <FlexColumn key={marketingCard.link} columnsLarge={4} columnsMedium={6}>
            <a href={marketingCard.link} rel="noopener noreferrer" target="_blank" {...analytics}>
              <Block className="resource-block">
                <div className="block__title">{marketingCard.title}</div>
                <img
                  alt={marketingCard.title}
                  className="resource-block__image"
                  src={marketingCard.image}
                />
                <div className="block__text">{marketingCard.text}</div>
                <Button block={true} className="resource-block__button" size={'large'}>
                  Sprout Social: Free Trial!
                </Button>
              </Block>
            </a>
          </FlexColumn>
        ))}
      </FlexRow>
    </Container>
  );
};

export default Marketing;
