import { useGetSnapshotQuery } from '../../app/services/dashboard';
import ProtectedNavbar from '../Navigation/Navbar';
import MainLayout from './MainLayout';
import './MainLayout.scss';

const ProtectedLayout = (): JSX.Element => {
  const { isSuccess } = useGetSnapshotQuery();

  return <>{isSuccess && <MainLayout header={<ProtectedNavbar />} />}</>;
};

export default ProtectedLayout;
