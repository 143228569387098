import BusinessCreditGraphPoints from '../../features/Dashboard/BusinessCredit/types/BusinessCreditGraphPoints';
import BusinessCreditWheel from '../../features/Dashboard/BusinessCredit/types/BusinessCreditWheel';
import ExchangeRequest from '../../features/Dashboard/ExchangeRequests/types/ExchangeRequest';
import FundingRequest from '../../features/Dashboard/FundingRequests/types/FundingRequest';
import SeenUserCredit from '../../features/Dashboard/types/SeenUserCredit';
import AppInitializationSnapshot from '../../features/Dashboard/types/Snapshot';
import UserAgent from '../../features/UserAgent/types/UserAgent';
import { borrowerPortalApi } from './borrowerPortalBase';

export const dashboardApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getSnapshot: builder.query<AppInitializationSnapshot, void>({
      query: () => 'dashboard/appInitializationSnapshot',
    }),
    getUserAgent: builder.query<UserAgent, void>({
      query: () => 'dashboard/user-agent',
    }),
    getFundingRequests: builder.query<FundingRequest[], void>({
      query: () => 'dashboard/funding-requests',
    }),
    getFundingRequestByOpportunityId: builder.query<FundingRequest, string>({
      query: (opportunityId) => 'dashboard/funding-request/' + opportunityId,
      providesTags: (result) =>
        result
          ? [{ type: 'FundingRequestData', id: result.applicationId }]
          : ['FundingRequestData'],
    }),
    getExchangeRequests: builder.query<ExchangeRequest[], void>({
      query: () => 'dashboard/exchange-requests',
    }),
    hasSeenUserCreditGuide: builder.query<SeenUserCredit, void>({
      query: () => 'dashboard/hasSeenUserCreditGuide',
    }),
    updateHasSeenGuide: builder.mutation<boolean, void>({
      query: (body) => ({
        url: 'dashboard/updateHasSeenGuide',
        method: 'POST',
        body,
      }),
    }),
    getCreditHistoryGraph: builder.query<BusinessCreditGraphPoints[], void>({
      query: () => 'dashboard/credit-history-graph',
    }),
    getBusinessCreditWheel: builder.query<BusinessCreditWheel, void>({
      query: () => 'dashboard/credit-history-wheel',
    }),
    updateLoginStatistics: builder.mutation<boolean, void>({
      query: (body) => ({
        url: 'dashboard/loginStatistics',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSnapshotQuery,
  useLazyGetSnapshotQuery,
  useGetUserAgentQuery,
  useUpdateLoginStatisticsMutation,
  useGetFundingRequestsQuery,
  useLazyGetFundingRequestsQuery,
  useGetFundingRequestByOpportunityIdQuery,
  useGetExchangeRequestsQuery,
  useHasSeenUserCreditGuideQuery,
  useUpdateHasSeenGuideMutation,
  useGetCreditHistoryGraphQuery,
  useGetBusinessCreditWheelQuery,
} = dashboardApi;
