const dateFormat = ({
  date,
  includeTime,
  shortYear,
}: {
  date?: Date;
  includeTime: boolean;
  shortYear?: boolean;
}): string => {
  if (date == null) return `${date}`;

  const inputDate = new Date(date);
  const formatedDate = new Intl.DateTimeFormat('en-US', {
    year: shortYear ? '2-digit' : 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(inputDate);

  if (!includeTime) {
    return `${formatedDate}`;
  }

  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(inputDate);

  return `${formatedDate} | ${time}`;
};

export default dateFormat;
