import { FeatureFlag } from '../../components/FeatureFlag';
import { borrowerPortalApi } from './borrowerPortalBase';

export const featuresFlagsApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<FeatureFlag[], void>({
      query: () => 'feature-flags',
    }),
  }),
  overrideExisting: false,
});

export const { useGetFeatureFlagsQuery } = featuresFlagsApi;
