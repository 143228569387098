import round from 'lodash/round';
import { CREDIT_BANDS } from '../../../constants';
import './CreditNeededChart.scss';
import calculatePercentInCreditRange from './utils/calculatePercentInCreditRange';

interface CreditNeededChartProps {
  minScore: number;
  maxScore: number;
}

const normalizeScore = (score: number, defaultScore: number): number => {
  const minScoreAllowed = CREDIT_BANDS.personal.poor.min;
  const maxScoreAllowed = CREDIT_BANDS.personal.excellent.max;

  const adjustedScore = score || defaultScore;

  if (adjustedScore < minScoreAllowed) return minScoreAllowed;
  if (adjustedScore > maxScoreAllowed) return maxScoreAllowed;

  return adjustedScore;
};

const creditBars = (
  <div className="credit-needed__bars">
    <span className="credit-needed__bar" />
    <span className="credit-needed__bar" />
    <span className="credit-needed__bar" />
    <span className="credit-needed__bar" />
    <span className="credit-needed__bar" />
  </div>
);

const CreditNeeded = ({ minScore, maxScore }: CreditNeededChartProps): JSX.Element => {
  if (minScore === 0 && maxScore === 0) {
    return <div className="credit-needed">{creditBars}</div>;
  }

  const adjustedMinScore = normalizeScore(minScore, maxScore);
  const adjustedMaxScore = normalizeScore(maxScore, minScore);

  const minScorePercent = calculatePercentInCreditRange(adjustedMinScore) * 100;
  const maxScorePercent = calculatePercentInCreditRange(adjustedMaxScore) * 100;

  return (
    <div className="credit-needed">
      <div
        className="credit-needed__scores"
        style={{
          marginLeft: `${minScorePercent}%`,
          width: `${round(maxScorePercent - minScorePercent, 2)}%`,
        }}
      >
        <span className="credit-needed__score credit-needed__score--start">
          <span className="credit-needed__label">{adjustedMinScore}</span>
          <span className="credit-needed__mark" />
        </span>
        {maxScorePercent !== minScorePercent && (
          <span className="credit-needed__score credit-needed__score--end">
            <span className="credit-needed__label">{adjustedMaxScore}</span>
            <span className="credit-needed__mark" />
          </span>
        )}
      </div>
      {creditBars}
    </div>
  );
};

export default CreditNeeded;
