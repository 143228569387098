import { ReactNode } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useQuery } from '../../hooks';
import { selectFeatureByName } from '../../slices/featureFlagsSlice';
import { FeatureFlag as FeatureFlagType, FeatureFlagNames } from './';

export const isFeatureEnabled = (feature: FeatureFlagType, query?: URLSearchParams): boolean =>
  feature.enabled || (!!query && query.has(`${feature.name}-enabled`));

interface FeatureFlagProps {
  children: ReactNode;
  fallback?: ReactNode;
  name: FeatureFlagNames;
}

/**
 * Displays children when the `name` prop can be found and enabled in the list of enabled FeatureFlags
 * Alternatively, children will be displayed if the feature if found and a querystring parameter if provided as: `{name}-enabled`
 * @param children
 * @param fallback
 * @param name
 * @constructor
 */

const FeatureFlag = ({ children, fallback, name }: FeatureFlagProps): JSX.Element => {
  const feature = useAppSelector((state) => selectFeatureByName(state)(name));
  const query = useQuery();

  if (feature && isFeatureEnabled(feature, query)) {
    return <>{children}</>;
  }

  if (fallback) return <>{fallback}</>;

  return <></>;
};

export default FeatureFlag;
