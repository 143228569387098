import { useEffect, useState } from 'react';
import { useAuth } from '../features/Auth';
import { useIsMounted } from './useIsMounted';

export default function useCheckAuthStatus(): [boolean, boolean] {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const isMounted = useIsMounted();

  const { isLoggedIn: checkIsLoggedIn } = useAuth();

  useEffect(() => {
    const checkLoggedIn = async () => {
      if (isMounted()) {
        const loggedIn = await checkIsLoggedIn();
        setIsLoggedIn(loggedIn);
        setIsChecking(false);
      }
    };

    checkLoggedIn();
  }, [isMounted, checkIsLoggedIn]);

  return [isLoggedIn, isChecking];
}
