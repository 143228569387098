import classNames from 'classnames';
import { ReactNode } from 'react';
import { ErrorMessage } from '../Errors';
import './InputGroup.scss';

interface FormGroupProps {
  children: ReactNode;
  error?: string;
}

const InputGroup = ({ children, error }: FormGroupProps): JSX.Element => (
  <div className={classNames('input-group', { 'input-group--error': error })}>
    {children}
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);

export default InputGroup;
