import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dashboardApi } from '../../../app/services/dashboard';
import { RootState } from '../../../app/store';
import SeenUserCredit from '../types/SeenUserCredit';

export type UserGuideState = SeenUserCredit;

const initialState: SeenUserCredit = {
  id: '',
  hasSeenGuide: false,
};

const reducers = {
  setBusinessCreditUserGuide: (state: UserGuideState, action: PayloadAction<SeenUserCredit>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const businessCreditUserGuideSlice = createSlice({
  name: 'businessCreditUserGuide',
  initialState: initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.hasSeenUserCreditGuide.matchFulfilled,
      reducers.setBusinessCreditUserGuide
    );
  },
});

export default businessCreditUserGuideSlice.reducer;

// Actions
export const { setBusinessCreditUserGuide } = businessCreditUserGuideSlice.actions;

// Selectors
export const selectBusinessCreditUserGuide = (state: RootState): SeenUserCredit =>
  state.dashboard.businessCreditUserGuide;
