import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../features/Auth';
import { useCheckAuthStatus, useToggle } from '../../hooks';
import { LtIcon } from '../Icon';
import LtLogoImage from './images/lt-logo.svg';
import './Navbar.scss';
import NavbarDropdown from './NavbarDropdown';
import NavbarItem from './NavbarItem';

const Navbar = (): JSX.Element => {
  const [showDrawer, toggleDrawer] = useToggle(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const navbarCollapseRef = useRef<HTMLDivElement>(null);
  const { logout } = useAuth();
  const [isUserLoggedIn] = useCheckAuthStatus();

  const outerClick = useCallback(
    (event: Event) => {
      if (!navbarCollapseRef?.current?.contains((event?.target as Node) || null)) {
        showDrawer && toggleDrawer();
      }
    },
    [showDrawer, toggleDrawer]
  );

  useEffect(() => {
    document.addEventListener('mousedown', outerClick);
    document.addEventListener('touchstart', outerClick);

    return () => {
      document.removeEventListener('mousedown', outerClick);
      document.removeEventListener('touchstart', outerClick);
    };
  }, [outerClick]);

  const handleToggleDrawer = () => {
    toggleDrawer();
  };

  const handleChangePassword = () => {
    handleToggleDrawer();
    const returnUrl = encodeURIComponent(window.location.href);
    window.location.href = `${process.env.TREEAUTH_IDENTITY_SERVER}/account/ResetPassword?isChangePassword=true&returnUrl=${returnUrl}`;
  };

  const handleLogout = () => {
    handleToggleDrawer();
    logout();
  };

  return (
    <nav className="navbar mobile-navbar">
      <div className="navbar__drawer-toggle" onClick={handleToggleDrawer}>
        <LtIcon name={'Menu-Bars'} />
      </div>
      <div className="navbar__logo">
        <img src={LtLogoImage} onClick={() => navigate('dashboard')} />
      </div>
      <div
        ref={navbarCollapseRef}
        className={classNames('navbar__collapse', { 'navbar__collapse--show': showDrawer })}
      >
        <ul className="navbar__nav">
          <NavbarItem className="navbar__close" icon={'Ex'} onClick={handleToggleDrawer} />
          <NavbarItem
            icon={'MyLT-Dashboard'}
            name={'Home'}
            path={'dashboard'}
            onClick={handleToggleDrawer}
          />

          <NavbarItem
            icon={'Document'}
            name={'Documents'}
            path={'documents'}
            onClick={handleToggleDrawer}
          />
          <NavbarItem
            icon={'Lightbulb'}
            name={'Resources'}
            path={'resources'}
            onClick={handleToggleDrawer}
          />
          <NavbarItem
            icon={'Cash'}
            name={'History'}
            path={'history'}
            onClick={handleToggleDrawer}
          />
          <NavbarDropdown
            forceClose={!showDrawer}
            icon={'Account'}
            isActive={pathname === '/profile'}
          >
            <NavbarItem
              icon={'Account'}
              name={'Profile'}
              path={'profile'}
              onClick={handleToggleDrawer}
            />
            {isUserLoggedIn && (
              <>
                <NavbarItem icon={'Key'} name={'Change Password'} onClick={handleChangePassword} />
                <NavbarItem icon={'Logout'} name={'Sign Out'} onClick={handleLogout} />
              </>
            )}
          </NavbarDropdown>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
