import { forwardRef } from 'react';
import StateManager, { GroupTypeBase } from 'react-select';
import Select from 'react-select/base';
import { useAppSelector } from '../../../app/hooks';
import { useGetBusinessIndustriesQuery } from '../../../app/services';
import { selectBusinessIndustries } from '../../../features/Profile/businessIndustriesSlice';
import SelectInput, { SelectInputProps } from './SelectInput';
import SelectOption from './types/SelectOption';

type BusinessIndustrySelectInputProps = Omit<SelectInputProps, 'options'>;

const BusinessIndustrySelectInput = forwardRef<
  StateManager<
    SelectOption,
    false,
    GroupTypeBase<SelectOption>,
    Select<SelectOption, false, GroupTypeBase<SelectOption>>
  >,
  BusinessIndustrySelectInputProps
>(
  ({ ...otherProps }: BusinessIndustrySelectInputProps, ref): JSX.Element => {
    useGetBusinessIndustriesQuery();
    const industries = useAppSelector(selectBusinessIndustries);

    // Create pipe-delimitted NAICS description and code as selection value
    const industryOptions = industries
      ? Object.entries(industries).map(([description, code]) => ({
          value: `${description}|${code}`,
          label: description,
        }))
      : '';
    return (
      <SelectInput
        ref={ref}
        options={industryOptions}
        placeholder="Business Industry"
        {...otherProps}
      />
    );
  }
);

BusinessIndustrySelectInput.displayName = 'BusinessIndustrySelectInput';

export default BusinessIndustrySelectInput;
