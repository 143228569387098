import classNames from 'classnames';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

interface GridProps {
  children: ReactNode;
  className?: string;
  columnGap?: string;
  columnMinWidth: string;
  rowGap?: string;
}

const Grid = forwardRef(
  (
    { children, className, columnGap = '0px', columnMinWidth, rowGap }: GridProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={classNames('grid', className)}
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(calc(${columnMinWidth} - ${columnGap}), 1fr))`,
          ...(columnGap && { columnGap }),
          ...(rowGap && { rowGap }),
        }}
      >
        {children}
      </div>
    );
  }
);

Grid.displayName = 'Grid';

export default Grid;
