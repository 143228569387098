import { Block } from '../../../components/Block';
import { Button } from '../../../components/Button';
import { FlexColumn } from '../../../components/Flex';
import { useLTAnalytics } from '../../../hooks/useLTAnalytics';
import LendingTreeLogo from './images/lt-logo.png';

const CreditCardsClickout = (): JSX.Element => {
  return (
    <FlexColumn columnsLarge={4} columnsMedium={6}>
      <a
        href="https://creditcards.lendingtree.com/business?source=blsignedin&campaign=resourcetab"
        rel="noopener noreferrer"
        target="_blank"
        {...useLTAnalytics('Learn More')}
      >
        <Block className="credit-cards__block">
          <div className="block__title">Business Credit Cards</div>
          <img
            alt="Business Credit Cards"
            className="credit-cards__block-image"
            src={LendingTreeLogo}
          />
          <div className="block__text">
            Having access to flexible on-demand cash is essential to running a successful business.
            A business credit card offers your business access to cash to cover short-term capital
            needs. Plus, the right business credit card can help you earn rewards, either in
            categories where your business spends frequently or with a flat rate on every purchase.
          </div>
          <Button block={true} className="credit-cards__block-button" size={'large'}>
            Learn More
          </Button>
        </Block>
      </a>
    </FlexColumn>
  );
};

export default CreditCardsClickout;
