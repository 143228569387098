import { AnimatedDots } from '../AnimatedDots';
import Button, { ButtonProps } from './Button';

type AnimatedDotsButtonProps = ButtonProps;

const AnimatedDotsButton = ({ children, ...otherProps }: AnimatedDotsButtonProps): JSX.Element => {
  return (
    <Button {...otherProps}>
      {children}
      <AnimatedDots />
    </Button>
  );
};

export default AnimatedDotsButton;
