import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetCongratulationsDataQuery } from '~/app/services/offers';
import { Container } from '~/components/Container';
import Spinner from '~/features/Signup/images/thankyou-spinner.png';
import AgentDetails from './AgentDetails';
import './Congratulations.scss';
import FundingStatus from './FundingStatus';

const Congratulations = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const qFormGuid = searchParams.get('guid');
  const { data: congratulationsData, isFetching } = useGetCongratulationsDataQuery(
    qFormGuid ?? skipToken
  );

  // Missing data, so redirect to error page
  useEffect(() => {
    if (!isFetching && congratulationsData?.merchantFirstName == null) {
      navigate(`error?guid=${qFormGuid}`);
    }
  }, [isFetching, congratulationsData, navigate, qFormGuid]);

  return (
    <>
      <Helmet>
        <title>Congratulations - Business.LendingTree.com</title>
      </Helmet>
      <Container>
        <div className="congratulations">
          <h1 className="congratulations__header">Great, we’re on it!</h1>
          <p className="congratulations__subheader">
            Good news! You qualify for our complimentary business concierge service to help guide
            you through the funding process.
          </p>
          {isFetching && (
            <div className="congratulations__spinner">
              <img className="thankyou__spinner-animation" src={Spinner} />
            </div>
          )}
          {!isFetching && congratulationsData?.merchantFirstName !== null && (
            <div className="congratulations-body">
              <AgentDetails
                agentEmail={congratulationsData?.email}
                agentName={congratulationsData?.name}
                agentPhoneNumber={congratulationsData?.phone}
                agentPhotoAsBase64String={congratulationsData?.profilePhotoAsBase64String}
                businessName={congratulationsData?.businessName}
                calendlyUrl={congratulationsData?.calendly__c}
                merchantFirstName={congratulationsData?.merchantFirstName}
              />
              <FundingStatus
                businessName={congratulationsData?.businessName}
                hasRequiredDocuments={congratulationsData?.hasRequiredDocuments}
                loanRequestAmount={congratulationsData?.loanRequestAmount}
                uploadDocumentUrl={congratulationsData?.uploadDocumentUrl}
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Congratulations;
