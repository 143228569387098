import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { dashboardApi } from '../../../app/services/dashboard';
import { RootState } from '../../../app/store';
import FundingRequest from './types/FundingRequest';

const fundingRequestsAdapter = createEntityAdapter<FundingRequest>({
  selectId: (fundingRequest) => fundingRequest?.opportunityId,
  sortComparer: (a, b) =>
    new Date(b?.dateRequested).getTime() - new Date(a?.dateRequested).getTime(),
});

export type FundingRequestState = EntityState<FundingRequest>;

const reducers = {
  setFundingRequests: (state: FundingRequestState, action: PayloadAction<FundingRequest[]>) => {
    fundingRequestsAdapter.setAll(state, action.payload);
  },
};

export const fundingRequestsSlice = createSlice({
  name: 'fundingRequests',
  initialState: fundingRequestsAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getFundingRequests.matchFulfilled,
      reducers.setFundingRequests
    );
  },
});

export default fundingRequestsSlice.reducer;

// Actions
export const { setFundingRequests } = fundingRequestsSlice.actions;

// Selectors
const fundingRequestsSelectors = fundingRequestsAdapter.getSelectors<RootState>(
  (state) => state.dashboard.fundingRequests
);

export const selectFundingRequests = (state: RootState): FundingRequest[] =>
  fundingRequestsSelectors.selectAll(state);

export const selectLatestOpenFundingRequest = (state: RootState): FundingRequest => {
  const fundingRequests = fundingRequestsSelectors.selectAll(state);
  const openFundingRequests = fundingRequests.filter((f) => f.isOpen);
  return openFundingRequests[0];
};
