import { useHandleFundingCTA, useLTAnalytics } from '../../hooks';
import InCompleteApplicationModal from '../Dashboard/InCompleteApplicationModal';
import OpenOpportunityModal from '../Dashboard/OpenOpportunityModal/OpenOpportunityModal';
import './HeroImage.scss';
import ConstructionGrowthImage from './images/construction-growth.png';

const HeroImage = (): JSX.Element => {
  const analytics = useLTAnalytics('Start a New Funding Request');
  const [
    check,
    handleInCompleteApplicationModalClose,
    showInCompleteApplicationModal,
    setShowOpenOpportunityModal,
    showOpenOpportunityModal,
  ] = useHandleFundingCTA();

  return (
    <div className="hero-image">
      <div className="hero-image__construction-box">
        <div className="hero-image__left-side">
          <h1 className="hero-image__construction-title">
            Get Small Business Financing Tailored for You
          </h1>
          <OpenOpportunityModal
            show={showOpenOpportunityModal}
            onClose={() => setShowOpenOpportunityModal(false)}
          />
          <InCompleteApplicationModal
            show={showInCompleteApplicationModal}
            onClose={handleInCompleteApplicationModalClose}
          ></InCompleteApplicationModal>
          <a
            className="hero-image__action-cta"
            rel="noopener noreferrer"
            target="_blank"
            onClick={check}
            {...analytics}
          >
            Start a New Funding Request
          </a>
          <div className="hero-image__help-title">
            We&#39;re in the business of helping your business succeed.
          </div>
          <ul className="hero-image__help-list">
            <li>Time-saving efficient process</li>
            <li>One funding request for multiple lenders</li>
            <li>Expert guidance to help you select your best option</li>
          </ul>
        </div>
        <div className="hero-image__right-imgblock">
          <img alt="construction-growth" src={ConstructionGrowthImage} />
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
