import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Block } from '../../../components/Block';
import { Button } from '../../../components/Button';
import { MEDIA_QUERIES } from '../../../constants';
import { useLTAnalytics, useWindowResize } from '../../../hooks';
import './CreditCard.scss';
import CreditNeeded from './CreditNeeded';
import { default as CreditCardType } from './types/CreditCard';

export type CreditCardProps = CreditCardType & {
  areDetailsOpen: boolean;
  toggleDetails?: () => void;
};

const showRatesAndFees = (issuerName: string): boolean => {
  const issuers = ['american-express', 'wells-fargo', 'discover', 'usbank'];
  return issuers.includes(issuerName);
};

const showTermsApply = (issuerName: string): boolean => {
  const issuers = ['american-express'];
  return issuers.includes(issuerName);
};

const CreditCard = ({
  name,
  imageUrl,
  applyUrl,
  impressionPixel,
  issuerId,
  issuerName,
  bankTermsUrl,
  introPurchaseApr,
  purchaseGoToRate,
  annualFee,
  rewardsRate,
  signUpBonus,
  highlightsHtml,
  creditScoreMin,
  creditScoreMax,
  areDetailsOpen,
  toggleDetails,
}: CreditCardProps): JSX.Element => {
  const xsDown = useMediaQuery({ query: MEDIA_QUERIES.xsDown });
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [originalHeight, setOriginalHeight] = useState<number>(0);
  const [titleHeight, setTitleHeight] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);
  const refTitle = useRef<HTMLDivElement>(null);
  const { width } = useWindowResize();

  // Handles resize card elements when window width changes
  useEffect(() => {
    if (xsDown) return;
    setMaxHeight(0);
    setTitleHeight(0);
  }, [xsDown, width]);

  // Handles resizing the height of the Card Box
  useEffect(() => {
    if (xsDown) return;
    if (areDetailsOpen) return;
    const { height = 0 } = ref.current?.getBoundingClientRect() ?? ({} as DOMRect);
    setOriginalHeight(height);
    setMaxHeight(height);
  }, [ref, maxHeight, areDetailsOpen, xsDown]);

  // Handles resizing the height of the Card Title
  useEffect(() => {
    if (xsDown) return;
    const height = refTitle?.current?.clientHeight ?? 0;
    setTitleHeight(height);
  }, [refTitle, titleHeight, xsDown]);

  const handleToggleDetails = () => {
    setMaxHeight(areDetailsOpen ? originalHeight : 0);

    toggleDetails?.();
  };

  const handleApply = () => {
    window.open(applyUrl);
  };

  return (
    <div
      ref={ref}
      className="credit-card"
      style={{
        ...(!xsDown && !areDetailsOpen && maxHeight > 0 && { maxHeight: `${maxHeight}px` }),
      }}
    >
      <Block className="credit-card credit-card__block">
        <div className="credit-card__image-container">
          <img alt={name} className="credit-card__image" src={imageUrl} />
        </div>
        {impressionPixel && <img alt="Advertisement" height="1" src={impressionPixel} width="1" />}
        <div
          ref={refTitle}
          className="credit-card__title"
          dangerouslySetInnerHTML={{ __html: name }}
          style={{
            ...(!xsDown && areDetailsOpen && titleHeight && { height: titleHeight }),
            flexGrow: !xsDown && areDetailsOpen ? 0 : 1,
          }}
        />
        <Button
          block={true}
          className="credit-card__link"
          color={'orange'}
          size={xsDown ? 'default' : 'large'}
          onClick={handleApply}
          {...useLTAnalytics('Apply Now')}
        >
          Apply Now
        </Button>
        <div className="credit-card__disclosures">
          <div className="credit-card__secure-site">on {issuerName}&apos;s secure site</div>
          {showRatesAndFees(issuerId) && (
            <div className="credit-card__rates-fees">
              <a href={bankTermsUrl} rel="noreferrer" target="_blank">
                Rates & Fees
              </a>
            </div>
          )}
          {showTermsApply(issuerId) && <div className="credit-card__terms-apply">Terms Apply</div>}
        </div>
        {areDetailsOpen && (
          <div className="credit-card-details">
            <div className="credit-card-details__container">
              <label className="credit-card-details__title">Intro Purchase APR</label>
              {introPurchaseApr ? (
                <span dangerouslySetInnerHTML={{ __html: introPurchaseApr }} />
              ) : (
                <span>N/A</span>
              )}
            </div>
            <div className="credit-card-details__container">
              <label className="credit-card-details__title">Regular Purchase APR</label>
              {purchaseGoToRate ? (
                <span dangerouslySetInnerHTML={{ __html: purchaseGoToRate }} />
              ) : (
                <span>N/A</span>
              )}
            </div>
            <div className="credit-card-details__container">
              <label className="credit-card-details__title">Annual Fee</label>
              {annualFee ? (
                <span dangerouslySetInnerHTML={{ __html: annualFee }} />
              ) : (
                <span>N/A</span>
              )}
            </div>
            <div className="credit-card-details__container">
              <label className="credit-card-details__title">Rewards Rate</label>
              {rewardsRate ? (
                <span dangerouslySetInnerHTML={{ __html: rewardsRate }} />
              ) : (
                <span>N/A</span>
              )}
            </div>
            <div className="credit-card-details__container">
              <label className="credit-card-details__title">Introductory Bonus</label>
              {signUpBonus ? (
                <span dangerouslySetInnerHTML={{ __html: signUpBonus }} />
              ) : (
                <span>N/A</span>
              )}
            </div>
            <div className="credit-card-details__container">
              <label className="credit-card-details__title">Highlights</label>
              {highlightsHtml ? (
                <div dangerouslySetInnerHTML={{ __html: highlightsHtml }} />
              ) : (
                <span>N/A</span>
              )}
            </div>
            <div className="credit-card-details__container">
              <label className="credit-card-details__title">Typical Credit Needed</label>
              {creditScoreMin > 0 || creditScoreMax > 0 ? (
                <CreditNeeded maxScore={creditScoreMax} minScore={creditScoreMin} />
              ) : (
                <span>N/A</span>
              )}
            </div>
          </div>
        )}
        <div
          className={classNames('credit-card-details__toggle', {
            'credit-card-details__toggle--opened': areDetailsOpen,
          })}
          onClick={handleToggleDetails}
        >
          {areDetailsOpen ? 'Collapse' : 'Show'} details
        </div>
      </Block>
    </div>
  );
};

export default CreditCard;
