import { Button } from '../../../../components/Button';
import { LtIcon } from '../../../../components/Icon';
import { useLTAnalytics } from '../../../../hooks';
import UploadImage from '../images/upload.png';

interface UploadButtonProps {
  showUploadIcon?: boolean;
  onSelectFiles: (files: FileList) => void;
}

const preventDefault = (e: React.DragEvent<HTMLInputElement>) => {
  e.preventDefault();
};

export default function UploadButton({
  showUploadIcon,
  onSelectFiles,
}: UploadButtonProps): JSX.Element {
  const analytics = useLTAnalytics('Upload or drag and drop your file');

  const handleFileDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files.length) {
      onSelectFiles(files);
    }
  };

  const handleSelectFile = (event: React.ChangeEvent) => {
    const currentTarget = event.currentTarget as HTMLInputElement;
    const files: FileList = currentTarget.files as FileList;

    if (files.length) {
      onSelectFiles(files);
    }
  };

  return (
    <>
      {showUploadIcon ? (
        <label>
          <input
            accept="image/jpg,image/png,application/pdf,image/jpeg"
            className="upload__hidden"
            name="files"
            size={1}
            type="file"
            onChange={handleSelectFile}
            {...analytics}
          />
          <span className="upload__icon">
            <LtIcon className="upload__icon-arrow" name="arrow2" />
            <span className="upload__icon-rectangle" />
          </span>
        </label>
      ) : (
        <div className="upload" {...analytics}>
          <input
            multiple
            accept="image/jpg,image/png,application/pdf,image/jpeg"
            className="upload__file"
            name="files"
            size={1}
            type="file"
            onChange={handleSelectFile}
            onClick={(e) => void ((e.target as HTMLInputElement).value = '')}
            onDragEnter={preventDefault}
            onDragLeave={preventDefault}
            onDragOver={preventDefault}
            onDrop={handleFileDrop}
          />
          <img alt="upload file" className="upload__img" src={UploadImage} />
          <span className="upload__text">Upload or drag and drop your file.</span>
          <Button block color={'blue'}>
            Upload Document
          </Button>
        </div>
      )}
    </>
  );
}
