import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { selectSnapshot } from '../../../src/slices/snapshotSlice';
import { useAppSelector } from '../../app/hooks';
import { Button } from '../../components/Button';
import { Modal, ModalProps, Mode } from '../../components/Modal';
import MEDIA_QUERIES from '../../constants/mediaQueries';
import { useModalAnalytics } from '../../hooks';
import CallOrTextModal from '../UserAgent/SalesRepInfo/CallOrTextModal';
import { selectUserAgent } from '../UserAgent/userAgentSlice';
import './InCompleteApplicationModal.scss';

const InCompleteApplicationModal = (props: ModalProps): JSX.Element => {
  const snapshot = useAppSelector(selectSnapshot);
  const userAgent = useAppSelector(selectUserAgent);
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const [showCallOrTextModal, setShowCallOrTextModal] = useState(false);
  const modalAnalyticsOnClose = useModalAnalytics(props, 'BL Incomplete Application Modal');

  const handleContactNumberClick = () => {
    window.ltanalytics.track('BL Portal CTA', { bl_cta_text: 'Phone' });
    setShowCallOrTextModal(true);
  };

  const handleCompleteApplicationButton = () => {
    window.sessionStorage.setItem('hasSeenIncompleteApplicationModal', JSON.stringify(true));
    window.ltanalytics.track('BL Complete Application CTA Clicked', {
      bl_cta_text: 'Complete My Application',
    });

    window.location.href = snapshot.splitterUrl;
  };

  const handleModalClose = () => {
    window.sessionStorage.setItem('hasSeenIncompleteApplicationModal', JSON.stringify(true));
    modalAnalyticsOnClose();
  };

  return (
    <>
      <Modal
        center={true}
        className="incompleteApplication__modal"
        mode={Mode.Modal}
        size="large"
        {...props}
        onClose={handleModalClose}
      >
        <div className="incompleteApplication__content">
          <p>
            We see that you already started an application for funding.&nbsp;
            {!isSmallDown && <br />}
            Please complete your application to apply for funds.
          </p>
          <div className="incompleteApplication__button">
            <a href="#" onClick={handleCompleteApplicationButton}>
              <Button compact={isSmallDown ? true : false}>Complete My Application</Button>
            </a>
          </div>
          <div className="incompleteApplication__contact">
            <p className="incompleteApplication__contact--text">
              If you need more assistance, reach out to your Concierge.
              <br />
              <strong>{userAgent.name}</strong>
              &nbsp;
              {isSmallDown && <br />}
              <span
                className="incompleteApplication__contact--number"
                onClick={handleContactNumberClick}
              >
                {userAgent.phone}
              </span>
            </p>
          </div>
        </div>
      </Modal>
      <CallOrTextModal
        name={userAgent?.name}
        phone={userAgent?.phone}
        photo={userAgent?.profilePhotoAsBase64String}
        show={showCallOrTextModal}
        onClickSetShowModal={(showModal) => setShowCallOrTextModal(showModal)}
      />
    </>
  );
};
export default InCompleteApplicationModal;
