import { v4 as uuidv4 } from 'uuid';

const uniqueKey = {
  generateKey: (pre: string): string => {
    return `${pre}_${uuidv4()}`;
  },
  generateGUID: (): string => {
    return `${uuidv4()}`;
  },
};

export default uniqueKey;
