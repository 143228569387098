import LtIcon from '../../components/Icon/LtIcon';
import { Tooltip } from '../../components/Tooltip';
import { useLTAnalytics } from '../../hooks/useLTAnalytics';

interface FundingRequestsProps {
  name?: string;
  merchantFirstName?: string;
  businessName?: string;
  loanRequestAmount?: string;
  hasRequiredDocuments?: boolean;
  uploadDocumentUrl?: string;
}

const FundingStatus = ({
  businessName,
  loanRequestAmount,
  hasRequiredDocuments,
  uploadDocumentUrl,
}: FundingRequestsProps): JSX.Element => {
  const tooltipAnalyticsCall = useLTAnalytics('Tooltip', 'CTA Clicked');

  const scheduleCallTooltipText =
    'Your funding expert will call you within 24 business hours or you can choose to schedule a call.';
  const showUploadButton = (index: number) => index === 1 && !hasRequiredDocuments;

  const showTooltip = (index: number) => index == 2 && hasRequiredDocuments;

  const isDocumentsUploaded = () => hasRequiredDocuments;

  const getConnectorClass = (index: number) => {
    if (index === 0) {
      return 'funding-status__connector request-sent';
    }
    if (index === 1 && !isDocumentsUploaded()) {
      return 'funding-status__connector file-upload';
    } else if (index === 1) {
      return 'funding-status__connector statement-reviewed';
    }
    return 'funding-status__connector';
  };

  const getIconContainerClass = (index: number) => {
    if (index === 0) {
      return 'funding-status__icon-container request-sent';
    }
    if (index === 1 && !isDocumentsUploaded()) {
      return 'funding-status__icon-container file-upload';
    } else if (index === 1) {
      return 'funding-status__icon-container  statement-reviewed';
    }
    return 'funding-status__icon-container';
  };

  const getTextContentClass = (index: number) => {
    if (index === 0) {
      return 'funding-status__content request-sent';
    }
    if (index === 1 && !isDocumentsUploaded()) {
      return 'funding-status__content file-upload';
    } else if (index === 1) {
      return 'funding-status__content  statement-reviewed';
    }
    return 'funding-status__content';
  };

  const redirectToUpload = () => {
    window.ltanalytics.track('CTA Clicked', { bl_cta_text: 'FundingStatusUpload' });
    window.open(uploadDocumentUrl ?? '', '_blank');
  };

  const getFundingRequestContents = () => {
    const messages = [
      'Request sent to your funding expert',
      'Bank Statements reviewed',
      'Call with you to review your request',
      'Follow up call to walk through options',
      'Secure funding',
    ];
    return messages.map((message, index) => {
      return (
        <div key={`message${index}`} className="funding-status__row">
          <div className="funding-status__row-body">
            <div className="funding-status__row-content">
              <div className={getIconContainerClass(index)}>
                {index < 2 && (
                  <LtIcon name={index == 1 && !isDocumentsUploaded() ? 'Warning' : 'Check'} />
                )}
              </div>
              <div className={getTextContentClass(index)}>
                {showUploadButton(index) ? 'Need Bank Statements' : message}
              </div>
            </div>
            {showUploadButton(index) ? (
              <div className="funding-status__button-container">
                <button
                  className="funding-status__upload-button"
                  type="button"
                  onClick={redirectToUpload}
                >
                  Upload
                </button>
              </div>
            ) : null}
            <div key={`tooltipContainer${index}`}>
              {showTooltip(index) ? (
                <Tooltip content={scheduleCallTooltipText} placement="auto">
                  <span className="funding-status__tooltip" {...tooltipAnalyticsCall}>
                    <LtIcon name="Question" />
                  </span>
                </Tooltip>
              ) : null}
            </div>
          </div>
          {index < messages.length - 1 && <div className={getConnectorClass(index)}></div>}
        </div>
      );
    });
  };

  return (
    <div key="fundingStatus" className="funding-status">
      <div key="fundingStatusHeaderContainer" className="funding-status__header-container">
        <div key="fundingStatusHeader" className="funding-status__header">
          Funding Status for {loanRequestAmount}
          <div key="fundingStatusSubHeader" className="funding-status__subheader">
            {businessName}
          </div>
        </div>
      </div>
      <div className="funding-status__header-divider"></div>
      <div className="funding-status__body">{getFundingRequestContents()}</div>
    </div>
  );
};

export default FundingStatus;
