import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { historyApi } from '../../../../src/app/services/history';
import { RootState } from '../../../../src/app/store';
import FundedLoan from './types/FundedLoan';

const fundedLoansAdapter = createEntityAdapter<FundedLoan>({
  selectId: (fundedLoan) => fundedLoan?.opportunityId,
});

export type FundedLoanState = EntityState<FundedLoan>;

const reducers = {
  setFundedLoans: (state: FundedLoanState, action: PayloadAction<FundedLoan[]>) => {
    fundedLoansAdapter.setAll(state, action.payload);
  },
};

export const fundedLoansSlice = createSlice({
  name: 'fundedLoans',
  initialState: fundedLoansAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(historyApi.endpoints.getFundedLoans.matchFulfilled, reducers.setFundedLoans);
  },
});

export default fundedLoansSlice.reducer;

// Actions
export const { setFundedLoans } = fundedLoansSlice.actions;

// Selectors
const fundedLoansSelectors = fundedLoansAdapter.getSelectors<RootState>(
  (state) => state.history.fundedLoans
);

export const selectFundedLoans = (state: RootState): FundedLoan[] =>
  fundedLoansSelectors.selectAll(state);
