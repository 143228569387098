/* eslint-disable */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetItemIdMutation, useGetLinkTokenQuery } from '~/app/services/plaid';
import { Loader } from '~/components/Loader';
import { Modal } from '~/components/Modal';
import ExchangeTokenRequest from './types/ExchangeTokenRequest';

const PlaidLinkClient = (): JSX.Element => {
  const [plaidLinkError, setPlaidLinkError] = useState(false);
  const [searchParams] = useSearchParams();
  const qFormGuid = searchParams.get('guid');
  const snapcapApplicationId = searchParams.get('bl_app_id');
  const isRenewalFlow = snapcapApplicationId !== null && snapcapApplicationId !== '';
  const delayedRedirect = (redirectUrl: string) => {
    const timer = setTimeout(() => {
      Redirect(redirectUrl);
    }, 5000);
    return () => clearTimeout(timer);
  };

  const {
    data: linkTokenResponse,
    isFetching: isTokenFetching,
    isUninitialized: isGetLinkQueryInitialized,
  } = useGetLinkTokenQuery(qFormGuid ?? skipToken);

  const [
    getItemId,
    { isLoading: isGetItemIdLoading, isError: isGetItemIdError, isSuccess: isGetItemIdSuccess },
  ] = useGetItemIdMutation();

  const Redirect = (destination: string) => {
    window.location.replace(destination);
  };

  // initialize plaid link script
  const InitPlaid = () => {
    const linkHandler = window.Plaid.create({
      token: linkTokenResponse?.linkToken,
      onSuccess: (_publicToken: any, metadata: any) => {
        const tokenRequest: ExchangeTokenRequest = {
          linkSessionId: metadata?.link_session_id,
          publicToken: metadata?.public_token,
          institutionId: metadata?.institution?.institution_id,
          institutionName: metadata?.institution?.name,
          accounts: metadata?.accounts,
        };

        getItemId({ qFormGuid, exchangeTokenRequest: tokenRequest });

        if (linkHandler !== undefined) linkHandler.destroy();
      },
      onLoad: () => {},
      onExit: () => {
        if (linkHandler !== undefined) linkHandler.destroy();
        setPlaidLinkError(true);
      },
      onEvent: () => {},
      // required for OAuth; if not using OAuth, set to null or omit:
      receivedRedirectUri: null,
    });

    linkHandler?.open();
  };

  const getRedirectUrlForNewSalesFlow = () => {
    return `${process.env.SPLITTER_CONGRATULATIONS_PAGE_URL}&guid=${qFormGuid}`;
  };

  useEffect(() => {
    if (qFormGuid === null) {
      setPlaidLinkError(true);
    }
  }, [qFormGuid]);

  // load plaid link script
  useEffect(() => {
    if (!isGetLinkQueryInitialized && !isTokenFetching) {
      if (linkTokenResponse?.linkToken !== undefined && linkTokenResponse?.linkToken !== null) {
        const script = document.createElement('script');
        script.src = `${process.env.PLAID_LINK_URL}`;
        script.async = true;
        script.setAttribute('id', 'plaidLink');
        script.onload = () => InitPlaid();
        document.body.appendChild(script);
      } else {
        setPlaidLinkError(true);
      }
    }
  }, [isGetLinkQueryInitialized, isTokenFetching, linkTokenResponse]);

  // call to getItemId() API is complete, so determine whether linking is a success or failure
  useEffect(() => {
    if (!isGetItemIdLoading && isGetItemIdSuccess) {
      if (isRenewalFlow) {
        Redirect(`${process.env.PLAID_SUCCESS_THANKYOU}`);
      } else {
        Redirect(getRedirectUrlForNewSalesFlow());
      }
    }

    if (isGetItemIdError) {
      setPlaidLinkError(true);
    }
  }, [isGetItemIdLoading, isGetItemIdSuccess, isGetItemIdError]);

  // handling failed linking scenarios
  useEffect(() => {
    if (plaidLinkError) {
      if (isRenewalFlow) {
        delayedRedirect(
          `${process.env.PLAID_ERROR_MANUAL_UPLOAD}?bl_app_id=${snapcapApplicationId}`
        );
      } else {
        Redirect(getRedirectUrlForNewSalesFlow());
      }
    }
  }, [plaidLinkError]);

  return (
    <>
      <Modal
        centerContent={true}
        closable={false}
        show={plaidLinkError && isRenewalFlow}
        size="medium"
        title="There was an issue linking your account."
        onClose={() => {}}
      >
        <div>
          <p>Upload your bank statements to finish your application.</p>
          <Loader centered={true} size="small" />
        </div>
      </Modal>
    </>
  );
};

export default PlaidLinkClient;
