import FvoffersValue from '~/types/Appsetting/FvoffersValue';
import MinUploadDocumentCount from '~/types/Appsetting/MinUploadDocumentCount';
import Url from '~/types/Appsetting/Url';
import { borrowerPortalApi } from './borrowerPortalBase';

export const appSettingApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getMinUploadDocumentCount: builder.query<MinUploadDocumentCount, void>({
      query: () => 'app-setting/min-upload-document-count',
    }),
    getFvoffersValue: builder.query<FvoffersValue, void>({
      query: () => 'app-setting/fvoffersValue',
    }),
    getBusinessCreditFAQUrl: builder.query<Url, void>({
      query: () => 'app-setting/business-credit-faq-url',
    }),
    getDisputesFAQUrl: builder.query<Url, void>({
      query: () => 'app-setting/disputes-faq-url',
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMinUploadDocumentCountQuery,
  useGetFvoffersValueQuery,
  useLazyGetFvoffersValueQuery,
  useGetBusinessCreditFAQUrlQuery,
  useGetDisputesFAQUrlQuery,
} = appSettingApi;
