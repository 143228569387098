import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

/**
 * Returns the window size as it changes
 * @param wait - time in milliseconds to debounce the `resize` event
 */
export const useWindowResize = (wait = 0): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, wait);

    window.addEventListener('resize', debouncedHandleResize);

    // Call handler right away so state gets updated with initial window size
    debouncedHandleResize();

    return () => {
      debouncedHandleResize.cancel();
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [wait]);

  return windowSize;
};
