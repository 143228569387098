import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { AuthManagerInstance } from '../../features/Auth/AuthProvider';
import { RootState } from '../store';

const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseAuthQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    await AuthManagerInstance.login();
  }

  return result;
};

export const borrowerPortalApi = createApi({
  reducerPath: 'borrowerPortalApi',
  baseQuery: baseAuthQuery,
  endpoints: () => ({}),
  tagTypes: ['FundingRequestData'],
});
