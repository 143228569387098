import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '../Container';
import './MainLayout.scss';

interface MainLayoutProps {
  header: ReactNode;
  showFooter?: boolean;
}

const MainLayout = ({ header, showFooter = true }: MainLayoutProps): JSX.Element => {
  return (
    <div className="main-layout">
      <header className="header">{header}</header>
      <div className="main-layout__content">
        <Outlet />
      </div>
      {showFooter && (
        <div className="main-layout__footer">
          <Container>
            <eg-footer hide-link-section />
          </Container>
        </div>
      )}
    </div>
  );
};

export default MainLayout;
