import {
  SignoutResponse,
  User as OidcUser,
  UserManager,
  UserManagerSettings,
} from 'oidc-client-ts';

export default class AuthManager {
  oidc: UserManager;

  constructor() {
    const settings: UserManagerSettings = {
      authority: process.env.TREEAUTH_IDENTITY_SERVER as string,
      client_id: process.env.TREEAUTH_CLIENT_ID as string,
      redirect_uri: process.env.TREEAUTH_LOGIN_REDIRECT_URI as string,
      response_type: 'code',
      scope: 'openid profile',
      post_logout_redirect_uri: process.env.TREEAUTH_LOGOUT_REDIRECT_URI,
      response_mode: 'query',
      loadUserInfo: true,
    };

    this.oidc = new UserManager(settings);

    this.oidc.events.addAccessTokenExpired(() => {
      this.logout();
    });

    this.oidc.events.addUserSignedOut(() => {
      this.logout();
    });
  }

  getUser = async (): Promise<OidcUser | null> => {
    return await this.oidc.getUser();
  };

  login = async (): Promise<void> => {
    return this.oidc.signinRedirect();
  };

  loginCallback = async (): Promise<OidcUser | null> => {
    return this.oidc.signinRedirectCallback();
  };

  logout = async (): Promise<void> => {
    return this.oidc.signoutRedirect();
  };

  logoutCallback = async (): Promise<SignoutResponse> => {
    return this.oidc.signoutRedirectCallback();
  };
}
