import { User as OidcUser } from 'oidc-client-ts';
import { createContext } from 'react';

export interface AuthContextType {
  getUser: () => Promise<OidcUser | null>;
  getLoginRedirectUri: () => string;
  removeLoginRedirectUri: () => void;
  isLoggedIn: () => Promise<boolean>;
  login: () => Promise<void>;
  loginCallback: () => Promise<OidcUser | null>;
  logout: () => Promise<void>;
  logoutCallback: () => Promise<void>;
}

const initialContext: AuthContextType = {
  getUser: () => Promise.resolve(null),
  getLoginRedirectUri: () => '',
  removeLoginRedirectUri: () => null,
  isLoggedIn: () => Promise.resolve(false),
  login: () => Promise.resolve(),
  loginCallback: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
  logoutCallback: () => Promise.resolve(),
};

const AuthContext = createContext<AuthContextType>(initialContext);

export default AuthContext;
