import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import './MainLayout.scss';

interface MainLayoutProps {
  header: ReactNode;
}

const OfferPageLayout = ({ header }: MainLayoutProps): JSX.Element => {
  return (
    <div className="main-layout">
      <header className="header">{header}</header>
      <div className="main-layout__content">
        <Outlet />
      </div>
    </div>
  );
};

export default OfferPageLayout;
