import Modal from '../../../components/Modal/Modal';
interface scheduleACallProps {
  calendlyLink: string;
  showScheduleACallModal: boolean;
  setScheduleACallModal: () => void;
}

const ScheduleACall = ({
  showScheduleACallModal,
  setScheduleACallModal,
  calendlyLink,
}: scheduleACallProps): JSX.Element => {
  return (
    <>
      <Modal
        center={true}
        centerContent={true}
        show={showScheduleACallModal}
        size="x-large"
        onClose={() => setScheduleACallModal()}
      >
        <iframe className="dashboard__Schedule-A-Call--Modal" src={calendlyLink}></iframe>
      </Modal>
    </>
  );
};
export default ScheduleACall;
