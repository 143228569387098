import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { useGetContactQuery } from '../../app/services';
import {
  useGetFvoffersValueQuery,
  useGetMinUploadDocumentCountQuery,
} from '../../app/services/appSetting';
import {
  useGetExchangeRequestsQuery,
  useGetFundingRequestsQuery,
} from '../../app/services/dashboard';
import { useHasAccountInfoQuery } from '../../app/services/profile';
import { Container } from '../../components/Container';
import { Tab, Tabs } from '../../components/Tabs';
import { MEDIA_QUERIES } from '../../constants';
import { useToggle } from '../../hooks';
import { selectSnapshot } from '../../slices/snapshotSlice';
import CompleteProfile from '../CompleteProfile/CompleteProfile';
import HeroImage from '../HeroImage/HeroImage';
import { PlaidNotification } from '../Plaid/PlaidNotification';
import { selectContact } from '../Profile/contactSlice';
import { UserAgent } from '../UserAgent';
import { selectUserAgent } from '../UserAgent/userAgentSlice';
import AdditionalFunding from './AdditionalFunding/AdditionalFunding';
import { BusinessCreditScore } from './BusinessCredit/BusinessCreditScore';
import { selectBusinessCreditWheel } from './BusinessCredit/businessCreditWheelSlice';
import { selectBusinessCreditUserGuide } from './BusinessCredit/userGuideSlice';
import './Dashboard.scss';
import ExchangeRequests from './ExchangeRequests/ExchangeRequests';
import { selectExchangeRequests } from './ExchangeRequests/exchangeRequestsSlice';
import FundingRequests from './FundingRequests/FundingRequests';
import { selectFundingRequests } from './FundingRequests/fundingRequestsSlice';
import { FundingRequestType } from './FundingRequests/types/FundingRequestType';
import ImproveLendability from './ImproveLendability/Lendability';
import InCompleteApplicationModal from './InCompleteApplicationModal';
import OpenOpportunityModal from './OpenOpportunityModal/OpenOpportunityModal';
import Renewals from './Renewals/Renewals';
import ScheduleACall from './ScheduleACall/ScheduleACall';

const Dashboard = (): JSX.Element => {
  const snapshot = useAppSelector(selectSnapshot);
  const showHeroImage =
    snapshot.conciergeFundingRequests == 0 && snapshot.exchangeFundingRequests == 0;
  const dashboardText =
    snapshot.conciergeFundingRequests > 0 || snapshot.exchangeFundingRequests > 0
      ? 'Check the status of your funding request with our real-time dashboard.'
      : 'You don’t have any current requests for funding, or your last funding request has expired.';

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const plaidLinkStatusQueryParam = searchParams.get('linkStatus');
  const [plaidLinkStatus, setPlaidLinkStatus] = useState<number>();
  const [showScheduleACallModal, setScheduleACallModal] = useToggle();
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });

  const { isFetching: isFundingRequestsFetching } = useGetFundingRequestsQuery();
  const { isFetching: isExchangeRequestsFetching } = useGetExchangeRequestsQuery();
  const { data: minUploadDocumentCountData } = useGetMinUploadDocumentCountQuery();
  const { data: hasAccountInfo, isLoading: areHasAccountInfoLoading } = useHasAccountInfoQuery();
  const { data: fvoffersValueData } = useGetFvoffersValueQuery();
  const { isFetching: isContactDetailsFetching } = useGetContactQuery();

  const fundingRequests = useAppSelector(selectFundingRequests);
  const hasOpenFundingRequest =
    fundingRequests.length == 0 ? 1 : fundingRequests.filter((fr) => fr.isOpen).length;
  const exchangeRequests = useAppSelector(selectExchangeRequests);
  const showAdditionalFundingCTA = fundingRequests.length > 0 ? 0 : exchangeRequests.length;
  const userAgent = useAppSelector(selectUserAgent);
  const contactDetails = useAppSelector(selectContact);

  const [showOpenOpportunityModal, setShowOpenOpportunityModal] = useState(false);
  const [showInCompleteApplicationModal, setShowInCompleteApplicationModal] = useState(false);

  const seenUserCreditData = useAppSelector(selectBusinessCreditUserGuide);
  const businessCreditWheel = useAppSelector(selectBusinessCreditWheel);

  useEffect(() => {
    if (!snapshot.hasBorrowerPortalAccount) {
      navigate('/existing-treeauth', { replace: true });
    }
  }, [navigate, snapshot.hasBorrowerPortalAccount]);

  useEffect(() => {
    if (!isFundingRequestsFetching) {
      const isBLApplicationsEventTracked = window.sessionStorage.getItem(
        'BL_Applications_Event_Tracked'
      );
      if (isBLApplicationsEventTracked !== 'true') {
        const applicationIds = fundingRequests.map((f) => {
          return f.applicationId;
        });
        const trackProperties = {
          bl_total_applications: snapshot.conciergeFundingRequests,
          bl_application_ids: applicationIds.join('|'),
        };
        window.ltanalytics.track('BL Applications Submitted', trackProperties);
        window.sessionStorage.setItem('BL_Applications_Event_Tracked', 'true');
      }
    }
  }, [fundingRequests, isFundingRequestsFetching, snapshot.conciergeFundingRequests]);

  useEffect(() => {
    // User credit guide is prioritized over incomplete app and open oppty pop-ups
    const isShowingUserCreditGuideModal =
      businessCreditWheel?.status?.toLocaleLowerCase() === 'success'
        ? !seenUserCreditData?.hasSeenGuide
        : false;

    if (
      businessCreditWheel?.id?.length > 0 &&
      seenUserCreditData?.id?.length > 0 &&
      isShowingUserCreditGuideModal === false
    ) {
      // For renewal opportunities, delay incomplete app pop-up until the user has completed their term (i.e. closed fund)
      const showInCompleteApplication =
        snapshot.anyOpenFundingRequests &&
        snapshot.anyInCompleteFundingRequests &&
        userAgent != null &&
        (snapshot.anyRenewalFundingRequests ? snapshot.anyClosedFundFundingRequests : true);

      // Incomplete app pop-up is prioritized over open oppty pop-up
      if (showInCompleteApplication) {
        try {
          const initialValue = JSON.parse(
            window.sessionStorage.getItem('hasSeenIncompleteApplicationModal') ?? 'false'
          );

          window.sessionStorage.setItem(
            'hasSeenIncompleteApplicationModal',
            JSON.stringify(initialValue)
          );
          setShowInCompleteApplicationModal(!initialValue);
        } catch (error) {
          // catch error so app does not crash
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        setShowOpenOpportunityModal(urlParams.has('existingSnapCapOpenOpportunity'));
      }
    }
  }, [
    businessCreditWheel,
    seenUserCreditData,
    snapshot.anyOpenFundingRequests,
    snapshot.anyInCompleteFundingRequests,
    snapshot.anyClosedFundFundingRequests,
    snapshot.anyRenewalFundingRequests,
    userAgent,
  ]);

  useEffect(() => {
    if (plaidLinkStatusQueryParam !== null && plaidLinkStatusQueryParam !== '') {
      setPlaidLinkStatus(Number(plaidLinkStatusQueryParam));
      window.history.pushState('', '', '/app/dashboard');
    }
  }, [plaidLinkStatusQueryParam]);

  const handleInCompleteApplicationModalClose = () => {
    window.sessionStorage.setItem('hasSeenIncompleteApplicationModal', JSON.stringify(true));
    setShowInCompleteApplicationModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      {plaidLinkStatus !== undefined && <PlaidNotification state={plaidLinkStatus} />}
      {snapshot.hasBorrowerPortalAccount && !isContactDetailsFetching && (
        <UserAgent
          displayUserAgent={snapshot.conciergeFundingRequests > 0}
          pageTitle={`Welcome, ${contactDetails?.firstName}`}
          subHeader={dashboardText}
        />
      )}
      <div className="dashboard">
        <Container>
          <OpenOpportunityModal
            show={showOpenOpportunityModal}
            onClose={() => setShowOpenOpportunityModal(false)}
          />
          {exchangeRequests.length > 0 && (
            <h3 className="dashboard__fund-title">Your Funding Requests</h3>
          )}
          {!isExchangeRequestsFetching &&
            exchangeRequests?.map((exchangeRequest) => (
              <ExchangeRequests
                key={exchangeRequest?.id}
                exchangeRequest={exchangeRequest}
                fvoffersValue={fvoffersValueData?.fvoffersValue}
              />
            ))}
          {/* ClosedFund */}
          {!isFundingRequestsFetching &&
            fundingRequests
              ?.filter((fr) => fr.fundingRequestType == FundingRequestType.ClosedFund)
              .map((fundingRequest, index) => (
                <span key={fundingRequest.opportunityId}>
                  {index == 0 && <h3 className="dashboard__fund-title">Closed Funds</h3>}
                  <Renewals fundingRequest={fundingRequest} />
                </span>
              ))}
          {/* ActiveFunding */}
          {!isFundingRequestsFetching &&
            fundingRequests
              ?.filter((fr) => fr.fundingRequestType == FundingRequestType.ActiveFunding)
              .map((fundingRequest, index) => (
                <span key={fundingRequest.opportunityId}>
                  {index == 0 && <h3 className="dashboard__fund-title">Active Funding</h3>}
                  <Renewals fundingRequest={fundingRequest} />
                </span>
              ))}
          {/* FundingRequest / BookedWonFundingRequest / ClosedLostFundingRequest */}
          {!isFundingRequestsFetching &&
            fundingRequests
              ?.filter(
                (fr) =>
                  fr.fundingRequestType == FundingRequestType.FundingRequest ||
                  fr.fundingRequestType == FundingRequestType.BookedWonFundingRequest ||
                  fr.fundingRequestType == FundingRequestType.ClosedLostFundingRequest
              )
              .map((fundingRequest, index) => (
                <span key={fundingRequest.opportunityId}>
                  {exchangeRequests.length == 0 && index == 0 && (
                    <h3 className="dashboard__fund-title">Your Funding Requests</h3>
                  )}
                  <FundingRequests
                    fundingRequest={fundingRequest}
                    hasExchangeRequests={exchangeRequests.length > 0}
                    minUploadDocumentCount={minUploadDocumentCountData?.minUploadDocumentCount}
                    userAgent={userAgent}
                    onScheduleACallModal={() => setScheduleACallModal()}
                  />
                </span>
              ))}
          {!isMediumDown && fundingRequests.length > 0 && (
            <ScheduleACall
              calendlyLink={userAgent.calendlyC}
              setScheduleACallModal={setScheduleACallModal}
              showScheduleACallModal={showScheduleACallModal}
            ></ScheduleACall>
          )}
          {(showAdditionalFundingCTA > 0 || hasOpenFundingRequest < 1) && <AdditionalFunding />}
          {showHeroImage && <HeroImage />}
          {!areHasAccountInfoLoading &&
            (hasAccountInfo === true ? (
              <Tabs className="dashboard__tabs">
                <Tab
                  id="business-profile"
                  title={isSmallDown ? 'Business Credit' : 'Business Credit Score'}
                >
                  <BusinessCreditScore />
                </Tab>
                <Tab id="improve-lendability" title="Improve Lendability">
                  <ImproveLendability />
                </Tab>
              </Tabs>
            ) : (
              <CompleteProfile />
            ))}
          <InCompleteApplicationModal
            show={showInCompleteApplicationModal}
            onClose={handleInCompleteApplicationModalClose}
          ></InCompleteApplicationModal>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
