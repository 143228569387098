import './OffersCTA.scss';

interface OffersCTAProps {
  fvoffersValue?: string;
  qFormId?: string;
}
const OffersCTA = ({ fvoffersValue, qFormId }: OffersCTAProps): JSX.Element => {
  return (
    <div className="offers-cta">
      <a
        className="offers-cta__btn"
        href={`offers?app=business&lt-offers-ui=1&guid=${qFormId}&fvoffers=${fvoffersValue}`}
      >
        View Your Matches
      </a>
    </div>
  );
};

export default OffersCTA;
