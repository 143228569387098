import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { useEffect, useRef, useState } from 'react';
import { useDeleteDocumentMutation } from '../../../../app/services/documents';
import { Modal } from '../../../../components/Modal';
import { useLTAnalytics } from '../../../../hooks';
import DeleteDocumentRequest from '../../types/DeleteDocumentRequest';
import DeleteDocumentResponse from '../../types/DeleteDocumentResponse';
import './DeleteModal.scss';

interface DeleteModalProps {
  show: boolean;
  documentId?: string;
  applicationId?: string;
  externalDocumentName?: string;
  stipId?: string;
  onDeleteConfirm: (documentId: string, isDelete: boolean) => void;
}

const DeleteModal = ({
  show,
  applicationId,
  documentId,
  externalDocumentName,
  stipId,
  onDeleteConfirm,
}: DeleteModalProps): JSX.Element => {
  const analyticsYesDelete = useLTAnalytics('Yes, delete');
  const lastDeleteMutation = useRef<
    MutationActionCreatorResult<
      MutationDefinition<
        DeleteDocumentRequest,
        BaseQueryFn<
          string | FetchArgs,
          unknown,
          FetchBaseQueryError,
          Record<string, unknown>,
          Record<string, unknown>
        >,
        never,
        DeleteDocumentResponse,
        'borrowerPortalApi'
      >
    >
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteFile, { isSuccess, isError, data: deleteDocResponse }] = useDeleteDocumentMutation();

  const handleConfirmClick = (isDelete: boolean) => {
    // Remove results from last mutation, so error message does not appear
    lastDeleteMutation.current?.unsubscribe();

    if (isDelete) {
      setIsLoading(true);
      lastDeleteMutation.current = deleteFile({
        applicationId,
        documentId,
        externalDocumentName,
        stipId,
      });
    } else {
      onDeleteConfirm?.(documentId ?? '', isDelete);
    }
  };

  useEffect(() => {
    if (isSuccess && deleteDocResponse?.isDeleted) {
      // Add delay to give backend time to update document count
      setTimeout(() => {
        setIsLoading(false);
        onDeleteConfirm?.(documentId ?? '', deleteDocResponse.isDeleted);
      }, 1000);
    }

    if (isError) {
      setIsLoading(false);
    }
  }, [isSuccess, isError, deleteDocResponse, documentId, onDeleteConfirm]);

  return (
    <>
      <Modal
        center={true}
        centerContent={true}
        closable={true}
        isDisabled={isLoading}
        show={show}
        size="medium"
        onClose={() => handleConfirmClick(false)}
      >
        {isLoading === false && (
          <div className="delete-modal">
            {(isError || deleteDocResponse?.isDeleted === false) && (
              <div className="delete-modal__error-message">
                An error occurred deleting the file. Please try again.
              </div>
            )}
            <div className="delete-modal__text">Are you sure you want to delete this file?</div>
            <div className="delete-modal__cta-block">
              <input
                className="delete-modal__cta-block--cancel"
                type="button"
                value="Cancel"
                onClick={() => handleConfirmClick(false)}
              />
              <input
                className="delete-modal__cta-block--delete"
                data-btn-yes-delete="Yes, delete"
                type="button"
                value="Yes, delete"
                onClick={() => handleConfirmClick(true)}
                {...analyticsYesDelete}
              />
            </div>
          </div>
        )}
        {isLoading === true && (
          <div className="delete-modal__inprocess">
            Please wait while deleting file{' '}
            <span className="delete-modal__inprocess--blink">...</span>
          </div>
        )}
      </Modal>
    </>
  );
};

export default DeleteModal;
