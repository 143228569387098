import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Container } from '../../components/Container';
import Banking from './Banking/Banking';
import CreditCards from './CreditCards/CreditCards';
import Marketing from './Marketing/Marketing';
import ResourcesMenu from './Menu/ResourcesMenu';
import './Resources.scss';

const CreditCardsRoute = (
  <>
    <Container>
      <div className="resources-content__title">
        Business Credit Cards
        <p className="resources-content__subtext">
          Compare top credit card offers from our partners
        </p>
      </div>
    </Container>
    <CreditCards />
  </>
);

const BankingRoute = (
  <>
    <Container>
      <div className="resources-content__title">Business Banking</div>
    </Container>
    <Banking />
  </>
);

const MarketingRoute = (
  <>
    <Container>
      <div className="resources-content__title">Marketing</div>
    </Container>
    <Marketing />
  </>
);

const AllResourcesRoute = (
  <>
    {BankingRoute}
    {CreditCardsRoute}
    {MarketingRoute}
  </>
);

const Resources = (): JSX.Element => {
  return (
    <div className="resources">
      <Helmet>
        <title>Business Resources</title>
      </Helmet>
      <ResourcesMenu />
      <div className="resources-content">
        <Routes>
          <Route index element={<Navigate to={'all'} />} />
          <Route element={AllResourcesRoute} path="all" />
          <Route element={BankingRoute} path="banking" />
          <Route element={CreditCardsRoute} path="credit-cards" />
          <Route element={MarketingRoute} path="marketing" />
        </Routes>
      </div>
    </div>
  );
};

export default Resources;
