import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { dashboardApi } from '../../../app/services/dashboard';
import { RootState } from '../../../app/store';
import BusinessCreditGraphPoints from './types/BusinessCreditGraphPoints';

const businessCreditAdapter = createEntityAdapter<BusinessCreditGraphPoints>();
export type BusinessCreditGraphState = EntityState<BusinessCreditGraphPoints>;
const reducers = {
  setBusinessCreditGraphPoints: (
    state: BusinessCreditGraphState,
    action: PayloadAction<BusinessCreditGraphPoints[]>
  ) => {
    businessCreditAdapter.setAll(state, action.payload);
  },
};

export const businessCreditSlice = createSlice({
  name: 'businessCreditGraphPoints',
  initialState: businessCreditAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getCreditHistoryGraph.matchFulfilled,
      reducers.setBusinessCreditGraphPoints
    );
  },
});

export default businessCreditSlice.reducer;

// Actions
export const { setBusinessCreditGraphPoints } = businessCreditSlice.actions;

// Selectors
const businessCreditGrapshSelectors = businessCreditAdapter.getSelectors<RootState>(
  (state) => state.dashboard.businessCreditGraphPoints
);

export const selectBusinessCreditGraphPoints = (state: RootState): BusinessCreditGraphPoints[] =>
  businessCreditGrapshSelectors.selectAll(state);
