import { FlexColumn, FlexRow } from '../../../../components/Flex';
import './RequestNotFound.scss';

const RequestNotFound = (): JSX.Element => {
  return (
    <FlexRow>
      <FlexColumn className="request-not-found" columns={12}>
        <div className="request-not-found__label">REQUEST NOT FUNDED</div>
        <div className="request-not-found__text">
          We’re unable to connect you with a lender that can meet your particular funding needs at
          this time. Try again later to see if we’re able to find a match.
        </div>
      </FlexColumn>
    </FlexRow>
  );
};

export default RequestNotFound;
