import TermBucket from '../../FundingRequests/types/TermBucket';

/**
 * Because our progress bar contains uneven bucket sizes,
 * we scale the percentage as part of a given number
 * @param pct decimal from 0 to 1
 * @param termLengthInMonths total number of months
 * @param termBuckets months as sections
 * @returns decimal from 0 to 100
 */

export default function getScaledPercentage(
  pct: number,
  termLengthInMonths: number,
  termBuckets: Array<TermBucket>
): number {
  if (pct == 1) return 100;
  if (pct == 0) return 0;

  // Map each bucket as a percent of the progress bar
  const buckets = termBuckets.map((bucket) => (100 * bucket.duration) / termLengthInMonths);
  const numBuckets = termBuckets.length;
  const percent = pct * 100;

  let scaled = 0;
  let dur = 0;

  for (let i = 0; i < numBuckets; i += 1) {
    dur += buckets[i];

    if (dur > percent) {
      const expectedBucketSize = (1 / numBuckets) * 100;
      const diff = dur - percent;
      const partialBucket = buckets[i] - diff;

      // Convert partial amount to a percentage of the expected bucket size
      const scaledBucket = (partialBucket / buckets[i]) * expectedBucketSize;

      // Calculate scaled value in relation to the progress bar
      scaled = expectedBucketSize * i + scaledBucket;

      break;
    }
  }

  return scaled;
}
