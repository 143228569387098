type DocumentType = {
  value: number;
  label: string;
};

const documentTypes: DocumentType[] = [
  {
    label: '8821/Tax Guard Report',
    value: 0,
  },
  {
    label: 'Accounts Receivable',
    value: 1,
  },
  {
    label: 'Additional Bank Statements',
    value: 2,
  },
  {
    label: 'Articles of Incorporation/Organization',
    value: 3,
  },
  {
    label: 'Balance Sheet (Previous Year)',
    value: 4,
  },
  {
    label: 'Balance Sheet (Year to Date)',
    value: 5,
  },
  {
    label: 'Bank Letter',
    value: 6,
  },
  {
    label: 'Business License',
    value: 7,
  },
  {
    label: 'Business Tax Return',
    value: 8,
  },
  {
    label: 'Business Utility Bill',
    value: 9,
  },
  {
    label: 'CPA Letter',
    value: 10,
  },
  {
    label: "Driver's License",
    value: 11,
  },
  {
    label: 'EIN Designation Letter',
    value: 12,
  },
  {
    label: 'Executed Business Lease',
    value: 13,
  },
  {
    label: 'Existing Loan Contract',
    value: 14,
  },
  {
    label: 'Letter of good standing with Secretary of State',
    value: 15,
  },
  {
    label: 'Operating Agreement',
    value: 16,
  },
  {
    label: 'P&L (Previous Year)',
    value: 17,
  },
  {
    label: 'P&L (Year to Date)',
    value: 18,
  },
  {
    label: 'Payoff Letter',
    value: 19,
  },
  {
    label: 'Permanent Residency Card',
    value: 20,
  },
  {
    label: 'Proof of Citizenship',
    value: 21,
  },
  {
    label: 'Purchase Agreement with Proof of Payment',
    value: 22,
  },
  {
    label: 'Site Inspection - Photos',
    value: 23,
  },
  {
    label: 'Social Security Card',
    value: 24,
  },
  {
    label: 'Tax Extension',
    value: 25,
  },
  {
    label: 'Tax Lien Documentation',
    value: 26,
  },
  {
    label: 'UCC Clarification',
    value: 27,
  },
  {
    label: 'Voided Check',
    value: 28,
  },
  {
    label: 'Zero Balance Letter',
    value: 29,
  },
];

export default documentTypes;
