import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import Collapsible from 'react-collapsible';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch } from '../../../app/hooks';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { MEDIA_QUERIES } from '../../../constants';
import { dateFormat } from '../../../utils';
import { FundingRequestType } from '../../Dashboard/FundingRequests/types/FundingRequestType';
import { addUploadedDocuments, removeUploadedDocument } from '../documentRequestsSlice';
import DocumentRequest from '../types/DocumentRequest';
import UploadedDocument from '../types/UploadedDocument';
import './DocumentRequests.scss';
import Upload from './Upload/Upload';
import UploadedDocuments from './UploadedDocuments/UploadedDocuments';

interface DocumentRequestsProps {
  documentRequest?: DocumentRequest;
  isExpand: boolean | false;
  minUploadDocumentCount?: number;
  onClickExpand: (applicationId: string) => void;
}

const DocumentRequests = ({
  documentRequest,
  isExpand = false,
  minUploadDocumentCount = 0,
  onClickExpand,
}: DocumentRequestsProps): JSX.Element => {
  const hasStips = (documentRequest?.stips?.length ?? 0) > 0;
  const stipsCount = !hasStips
    ? 0
    : documentRequest?.stips?.filter((s) => !s.uploaded_to_Borrower_Portal__c).length ?? 0;

  const dispatch = useAppDispatch();

  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });

  const applicationStatusText = useMemo(() => {
    if (
      documentRequest?.fundingRequestType == FundingRequestType.BookedWonFundingRequest ||
      documentRequest?.fundingRequestType == FundingRequestType.ClosedLostFundingRequest
    ) {
      return 'CLOSED ' + dateFormat({ date: documentRequest?.dateClosed, includeTime: false });
    }

    return 'IN PROGRESS';
  }, [documentRequest?.fundingRequestType, documentRequest?.dateClosed]);

  const requiredBankStatements = useMemo(() => {
    const bankStatements = documentRequest?.uploadedDocuments?.filter(
      (doc) => doc?.documentType?.replaceAll(' ', '').toUpperCase() === 'BANKSTATEMENT'
    );

    const remainingBankStatements = minUploadDocumentCount - (bankStatements?.length ?? 0);

    return Array(remainingBankStatements > -1 ? remainingBankStatements : 0)
      .fill(false)
      .map((_, i) => {
        return {
          name: `Bank Statement ${minUploadDocumentCount - i}`,
        };
      })
      .reverse();
  }, [documentRequest?.uploadedDocuments, minUploadDocumentCount]);

  const updateDocumentStore = useCallback(
    async (documents: UploadedDocument[]) => {
      dispatch(
        addUploadedDocuments({
          requestId: documentRequest?.id,
          documents: JSON.parse(JSON.stringify(documents)),
        })
      );

      return Promise.resolve();
    },
    [dispatch, documentRequest?.id]
  );

  const handleOnDocumentDeleteConfirm = (documentId: string) => {
    dispatch(
      removeUploadedDocument({
        requestId: documentRequest?.id,
        documentId,
      })
    );
  };

  return (
    <FlexRow>
      <FlexColumn columnsLarge={12}>
        <div
          className={
            documentRequest?.fundingRequestType == FundingRequestType.BookedWonFundingRequest ||
            documentRequest?.fundingRequestType == FundingRequestType.ClosedLostFundingRequest
              ? 'document-request--disabled'
              : 'document-request'
          }
        >
          <div
            className="document-request__header"
            onClick={() => onClickExpand?.(documentRequest?.applicationId ?? '0')}
          >
            <h5>
              <button className="document-request__header--cta" type="button">
                <div
                  className={classNames(
                    isExpand ? 'bl-lt-icon--Minus' : 'bl-lt-icon--Plus',
                    `document-request__header--plus-minus-icons`
                  )}
                ></div>
              </button>
            </h5>
            {!isSmallDown && (
              <div className="document-request__header--content">
                <div
                  className="document-request__header--content--application-number"
                  data-opportunityid={documentRequest?.opportunityId}
                >
                  Application {documentRequest?.applicationId}
                  {isMediumDown && <br />}
                  <span className="document-request__header--content--application-status">
                    {applicationStatusText}
                  </span>
                </div>
                <div className="document-request__header--content--req-amount">
                  <span className="document-request__header--content--amount-text">
                    Requested Amount:
                  </span>
                  {isMediumDown && <br />}
                  <span className="document-request__header--content--amount-value">
                    {documentRequest?.requestedAmount}
                  </span>
                </div>
              </div>
            )}
            {isSmallDown && (
              <div className="document-request__header--content">
                <div
                  className="document-request__header--content--application-number"
                  data-opportunityid={documentRequest?.opportunityId}
                >
                  Application {documentRequest?.applicationId}
                </div>
                <span>
                  Requested Amount: {documentRequest?.requestedAmount}
                  <p className="document-request__header--content--application-status">
                    {applicationStatusText}
                  </p>
                </span>
              </div>
            )}
          </div>
          <Collapsible open={isExpand} trigger="">
            <div className="document-request__body">
              {((!hasStips && requiredBankStatements.length > 0) || stipsCount > 0) &&
                documentRequest?.fundingRequestType !==
                  FundingRequestType.BookedWonFundingRequest &&
                documentRequest?.fundingRequestType !==
                  FundingRequestType.ClosedLostFundingRequest && (
                  <div className="document-request__body--statement-text">
                    <span className="document-request__body--todo-text">TO DO: </span>
                    Please upload the&nbsp;
                    {!hasStips ? requiredBankStatements.length : stipsCount}
                    &nbsp;remaining required document(s).
                  </div>
                )}
              <UploadedDocuments
                applicationId={documentRequest?.applicationId}
                externalVisitorId={documentRequest?.externalVisitorId}
                hasAbilityToUploadDocument={documentRequest?.hasAbilityToUploadDocument}
                isStipsRequired={hasStips}
                opportunityId={documentRequest?.opportunityId}
                requiredDocuments={hasStips ? documentRequest?.stips : requiredBankStatements}
                uploadedDocuments={documentRequest?.uploadedDocuments}
                onDocumentDeleteConfirm={(documentId) =>
                  handleOnDocumentDeleteConfirm(documentId ?? '')
                }
                onUploadDocuments={updateDocumentStore}
              />
              {documentRequest?.hasAbilityToUploadDocument &&
                documentRequest.applicationId != null && (
                  <Upload
                    applicationId={documentRequest?.applicationId}
                    externalVisitorId={documentRequest?.externalVisitorId}
                    isStipsRequired={hasStips}
                    opportunityId={documentRequest?.opportunityId}
                    stipsList={
                      hasStips
                        ? documentRequest?.stips
                        : requiredBankStatements.length > 0
                        ? requiredBankStatements
                        : undefined
                    }
                    onUploadDocuments={updateDocumentStore}
                  />
                )}
            </div>
          </Collapsible>
        </div>
      </FlexColumn>
    </FlexRow>
  );
};

export default DocumentRequests;
