import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from '../../../app/hooks';
import { LtIcon } from '../../../components/Icon';
import { Tooltip } from '../../../components/Tooltip';
import { MEDIA_QUERIES } from '../../../constants';
import { selectUserAgent } from '../../../features/UserAgent/userAgentSlice';
import { selectSnapshot } from '../../../slices/snapshotSlice';
import { convertLoanTermToString, dateFormat } from '../../../utils';
import { selectCurrentUser } from '../../Auth/authSlice';
import CallOrTextModal from '../../UserAgent/SalesRepInfo/CallOrTextModal';
import FundingRequest from '../FundingRequests/types/FundingRequest';
import { FundingRequestType } from '../FundingRequests/types/FundingRequestType';
import EligibilityMarker from './icons/EligibilityMarker';
import EstimatedProgress from './icons/EstimatedProgress';
import ProgressBar from './ProgressBar';
import './Renewals.scss';
import getScaledPercentage from './utils/getScaledPercentage';

interface RenewalsProps {
  fundingRequest: FundingRequest;
}

interface SummaryDetails {
  heading: string;
  text: string;
  cta?: string;
  tooltipText?: string;
}

export default function Renewals({ fundingRequest }: RenewalsProps): JSX.Element {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const userAgent = useAppSelector(selectUserAgent);
  const snapshot = useAppSelector(selectSnapshot);

  const [summaryDetails, setSummaryDetails] = useState<SummaryDetails>();
  const [showCallOrTextModal, setShowCallOrTextModal] = useState(false);

  const progressBarScaled = useMemo(() => {
    if (fundingRequest?.progressBar && fundingRequest?.term) {
      return getScaledPercentage(
        fundingRequest?.progressBar / 100,
        fundingRequest?.term,
        fundingRequest?.termBuckets
      );
    }
    return 0;
  }, [fundingRequest?.progressBar, fundingRequest?.term, fundingRequest?.termBuckets]);

  const eligibilityScaled = useMemo(() => {
    if (fundingRequest?.eligibilityProgress && fundingRequest?.term) {
      return getScaledPercentage(
        fundingRequest?.eligibilityProgress / 100,
        fundingRequest?.term,
        fundingRequest?.termBuckets
      );
    }
    return 0;
  }, [fundingRequest?.eligibilityProgress, fundingRequest?.term, fundingRequest?.termBuckets]);

  const hasReachedEligibility = useMemo(() => {
    // Compare dates without taking the time into consideration
    if (fundingRequest?.eligibilityDate) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const eligibilityDate = new Date(fundingRequest?.eligibilityDate);
      eligibilityDate.setHours(0, 0, 0, 0);

      if (currentDate >= eligibilityDate) return true;

      return false;
    }
    return false;
  }, [fundingRequest?.eligibilityDate]);

  const heading = useMemo(() => {
    if (fundingRequest?.applicationId != null) {
      return `#${fundingRequest.applicationId}`;
    }

    return '';
  }, [fundingRequest.applicationId]);

  const handleRenewalsAnalytics = useCallback(
    (component: string) => {
      const isRenewalAnalyticsTracked =
        window.sessionStorage.getItem('BL_Renewals_Dashboard_Event_Tracked') ?? 'false';

      if (isRenewalAnalyticsTracked === 'false') {
        const trackProperties = { bl_auth_id: currentUser?.authId, bl_component: component };
        window.ltanalytics.track('BL Renewals Dashboard', trackProperties);
        window.sessionStorage.setItem('BL_Renewals_Dashboard_Event_Tracked', 'true');
      }
    },
    [currentUser?.authId]
  );

  const getSummaryDetails = useCallback((): SummaryDetails => {
    if (fundingRequest?.fundingRequestType == FundingRequestType.ActiveFunding) {
      // Display Reached Eligibility UI
      if (hasReachedEligibility) {
        handleRenewalsAnalytics('Reached Eligibility');
        return {
          heading: 'You may be eligible for additional funds',
          text:
            "Contact your Concierge to see if you're eligible to get more funds in one lump sum and potentially lower your interest rate or repayment terms.",
          cta: 'Call to Get More Funds',
          tooltipText: `Your eligibility is dependent on you not having any missed or modified payments. Please contact your Concierge to confirm whether you're eligible for additional funds.`,
        };
      }

      // Display Not Yet Eligible UI
      handleRenewalsAnalytics('Not Yet Eligible');
      return {
        heading: 'Continue to make payments on time',
        text:
          'If you make your payments on time, we could help you get additional funds sooner and potentially lower your rate.',
      };
    }

    // Funding request type is ClosedFund, so display Paid Down and Eligible UI
    handleRenewalsAnalytics('Paid Down and Eligible');
    return {
      heading: "It's time to re-apply",
      text:
        'You may be eligible to get additional funds faster and get a better rate when you apply again.',
      cta: 'Get Started',
      tooltipText: `If you haven’t already re-applied, you may be eligible for additional funds. Missed or modified payments may affect your eligibility. Reach out to your Concierge to confirm your eligibility.`,
    };
  }, [fundingRequest?.fundingRequestType, hasReachedEligibility, handleRenewalsAnalytics]);

  useEffect(() => {
    if (fundingRequest) {
      const details = getSummaryDetails();
      setSummaryDetails(details);
    }
  }, [fundingRequest, getSummaryDetails]);

  const handleSummaryCTAClick = () => {
    if (
      fundingRequest?.fundingRequestType == FundingRequestType.ActiveFunding &&
      hasReachedEligibility
    ) {
      window.ltanalytics.track('BL Renewals Dashboard', { bl_cta_text: 'Call to Get More Funds' });
      setShowCallOrTextModal(true);
    }

    if (fundingRequest?.fundingRequestType == FundingRequestType.ClosedFund) {
      window.ltanalytics.track('BL Renewals Dashboard', { bl_cta_text: 'Get Started' });
      window.location.href = snapshot.splitterUrl;
    }
  };

  return (
    <div className="renewals">
      <div className="renewals__header">
        <div className="renewals__header--id">{heading}</div>
      </div>
      <div className="renewals__content">
        <div className="renewals__summary">
          <div className="renewals__summary-heading">
            {summaryDetails?.heading}
            {summaryDetails?.tooltipText && (
              <Tooltip content={summaryDetails.tooltipText}>
                <span className="renewals__summary-heading-icon">
                  <LtIcon name="Info-Icon" />
                </span>
              </Tooltip>
            )}
          </div>
          <div className="renewals__summary-text">{summaryDetails?.text}</div>
          {summaryDetails?.cta && (
            <div className="renewals__summary-cta" onClick={handleSummaryCTAClick}>
              {summaryDetails?.cta}
            </div>
          )}
          <CallOrTextModal
            name={userAgent?.name}
            phone={userAgent?.phone}
            photo={userAgent?.profilePhotoAsBase64String}
            show={showCallOrTextModal}
            onClickSetShowModal={(showModal) => setShowCallOrTextModal(showModal)}
          />
        </div>
        <div className="renewals__status">
          {!isSmallDown && (
            <EstimatedProgress
              shiftRight={
                fundingRequest?.fundingRequestType == FundingRequestType.ClosedFund
                  ? true
                  : eligibilityScaled < 20
              }
            />
          )}
          <EligibilityMarker
            eligibleDate={fundingRequest?.eligibilityDate}
            marker={eligibilityScaled}
            show={fundingRequest?.fundingRequestType != FundingRequestType.ClosedFund}
          />
          <div className="renewals__status-progress">
            <ProgressBar pct={progressBarScaled} termBuckets={fundingRequest?.termBuckets} />
          </div>
          {isSmallDown && <EstimatedProgress />}
          <div className="renewals__status-content">
            <div className="renewals__status-content-block">
              <div className="renewals__status-content-label">Loan Amount</div>
              {fundingRequest?.loanAmount
                ? '$' + fundingRequest?.loanAmount?.toLocaleString()
                : 'Unknown'}
            </div>
            <div className="renewals__status-content-block">
              <div className="renewals__status-content-label">Funded Date</div>
              {dateFormat({
                date: fundingRequest?.dateFunded,
                includeTime: false,
                shortYear: true,
              })}
            </div>
            <div className="renewals__status-content-block">
              <div className="renewals__status-content-label">Loan Term</div>
              {convertLoanTermToString({ term: fundingRequest?.term, shortMonth: isSmallDown })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
