import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';
import { AnimatedDots } from '../../components/AnimatedDots';
import { Button } from '../../components/Button';
import { FlexColumn, FlexRow } from '../../components/Flex';
import { LtIcon } from '../../components/Icon';
import {
  BusinessIndustrySelectInput,
  InputGroup,
  InputLabel,
  StateSelectInput,
  TextInput,
} from '../../components/Input';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { Tooltip } from '../../components/Tooltip';
import { MEDIA_QUERIES } from '../../constants';
import Account from '../../features/Profile/types/Account';
import { useLTAnalytics } from '../../hooks/useLTAnalytics';
import './AccountSignupForm.scss';
const businessIndustryText =
  'This list is made up of North American Industry Classification System (NAICS) industries.';

interface AccountSignupFormInput {
  name: string;
  city: string;
  state: string;
  street: string;
  postalCode: string;
  street2: string;
  phone: string;
  taxId: string;
  industry: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .label('Business Name')
    .required('Please enter business name.')
    .matches(/^[a-zA-Z\w]+[a-zA-Z_ ]+$/i, '${path} must be valid')
    .max(255),
  street: yup
    .string()
    .label('Business Street')
    .required('Please enter business street address.')
    .matches(/^([a-zA-Z\w]+[a-zA-Z0-9,#-.\r\n ])+$/i, '${path} must be valid')
    .max(255),
  street2: yup
    .string()
    .label('Floor/Suite')
    .max(255)
    .nullable()
    .matches(/^[a-zA-Z0-9]*([\s]{1}[a-zA-Z0-9]+)*$/i, '${path} must be valid'),
  city: yup
    .string()
    .label('City')
    .required('Please enter city.')
    .matches(/^([a-zA-Z\w]+[a-zA-Z\n\r ])+$/i, '${path} must be valid')
    .max(40),
  postalCode: yup
    .string()
    .label('Zip Code')
    .required('Please enter zip code.')
    .length(5)
    .matches(/^[0-9]+$/i, '${path} must be numeric'),
  state: yup.string().label('State').required('Please select state.'),
  phone: yup
    .string()
    .label('Business Phone Number')
    .required('Please enter business phone number.')
    .length(12)
    .matches(/^[0-9-]+$/i, '${path} must be valid'),
  taxId: yup
    .string()
    .label('Tax ID Number (EIN)')
    .matches(/^$|^[0-9-]+$/i, '${path} must be valid')
    .nullable(),
  industry: yup.string().label('Business Industry').required('Please select business industry.'),
});

interface AccountSignupFormProps {
  stepTitle?: string;
  signUpText?: string;
  progressBarText?: string;
  progressBarWidth?: number;
  isSubmitting?: boolean;
  account?: Account;
  isSignupFlow?: boolean;
  onSubmit?: (account: Account) => void;
  onStepLoad?: () => void;
}

const AccountSignupForm = ({
  stepTitle,
  signUpText,
  progressBarText,
  progressBarWidth = 0,
  isSubmitting = false,
  isSignupFlow = false,
  account = {},
  onSubmit = () => undefined,
  onStepLoad = () => undefined,
}: AccountSignupFormProps): JSX.Element => {
  const analyticsSignup = useLTAnalytics('Next Step-BusinessInformation');
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<AccountSignupFormInput>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });

  const businessIndustryDefaultValueProp = () => {
    // Remove NAICS code from account.industry string
    const naicsDescription = account?.industry?.split('|', 1);

    if (account?.industry && naicsDescription) {
      return {
        defaultValue: {
          value: naicsDescription[0],
          label: naicsDescription[0],
        },
      };
    }
    return {};
  };

  const stateDefaultValueProp = () => {
    if (account?.address?.stateCode) {
      return {
        defaultValue: {
          value: account?.address?.stateCode,
          label: account?.address?.stateCode,
        },
      };
    }

    return {};
  };

  const handleFormSubmit = handleSubmit(
    ({
      name,
      city,
      state,
      street,
      street2,
      postalCode,
      phone,
      taxId,
      industry,
    }: AccountSignupFormInput) => {
      const account: Account = {
        id: '',
        name,
        address: {
          street,
          street2,
          city,
          stateCode: state,
          postalCode,
        },
        phone,
        taxId,
        industry,
      };

      onSubmit(account);
    }
  );

  useEffect(() => {
    if (Object.keys(account).length > 0 && Object.keys(errors).length === 0) {
      setValue('name', account?.name ?? '');
      setValue('street', account?.address?.street ?? '');
      setValue('street2', account?.address?.street2 ?? '');
      setValue('city', account?.address?.city ?? '');
      setValue('postalCode', account?.address?.postalCode ?? '');
      setValue('state', account?.address?.stateCode ?? '');
      setValue('phone', account?.phone ?? '');
      setValue('taxId', account?.taxId ?? '');
      setValue('industry', account?.industry ?? '');
    }
  }, [account, errors, setValue]);

  useEffect(() => {
    onStepLoad();
  }, [onStepLoad]);
  return (
    <>
      {isSignupFlow && (
        <>
          <div className="progress-block">
            <div className="progress-block__text">{progressBarText}</div>
            <ProgressBar percent={progressBarWidth} />
          </div>
          <div className="signup__title">{stepTitle}</div>
          <h1 className="signup__header">{signUpText}</h1>
        </>
      )}
      <div className="account-signup-form">
        <form onSubmit={handleFormSubmit}>
          <FlexRow>
            <FlexColumn columnsMedium={12}>
              <InputGroup error={errors.name?.message}>
                <InputLabel htmlFor="name" size={'large'}>
                  Business Name
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="name"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'name'}
                      placeholder={'Business Name'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={12}>
              <InputGroup error={errors.industry?.message}>
                <InputLabel htmlFor="industry" size={'large'}>
                  Business Industry&nbsp;
                  <Tooltip
                    content={businessIndustryText}
                    offset={isSmallDown ? [60, 10] : [0, 10]}
                    placement={isSmallDown ? 'top' : 'right'}
                  >
                    <span>
                      <LtIcon name="Question" />
                    </span>
                  </Tooltip>
                </InputLabel>
                <Controller
                  control={control}
                  name="industry"
                  render={({ field: { onChange, ref } }) => (
                    <BusinessIndustrySelectInput
                      ref={ref}
                      size={'large'}
                      onChange={onChange}
                      {...businessIndustryDefaultValueProp()}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={8}>
              <InputGroup error={errors.street?.message}>
                <InputLabel htmlFor="street" size={'large'}>
                  Business Street Address
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="street"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'street'}
                      placeholder={'Business Street Address'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={4}>
              <InputGroup error={errors.street2?.message}>
                <InputLabel htmlFor="street2" size={'large'}>
                  Floor/Suite
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="street2"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'street2'}
                      placeholder={'Floor/Suite'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.city?.message}>
                <InputLabel htmlFor="city" size={'large'}>
                  City
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="city"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'city'}
                      placeholder={'City'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={2}>
              <InputGroup error={errors.state?.message}>
                <InputLabel htmlFor="state" size={'large'}>
                  State
                </InputLabel>
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, ref } }) => (
                    <StateSelectInput
                      ref={ref}
                      size={'large'}
                      onChange={onChange}
                      {...stateDefaultValueProp()}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.postalCode?.message}>
                <InputLabel htmlFor="postalCode" size={'large'}>
                  Zip Code
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="postalCode"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat
                      {...fieldProps}
                      ref={ref}
                      className="text-input text-input--large"
                      format="#####"
                      placeholder={'Zip Code'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={6}>
              <InputGroup error={errors.phone?.message}>
                <InputLabel htmlFor="phone" size={'large'}>
                  Business Phone Number
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="phone"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat
                      {...fieldProps}
                      ref={ref}
                      className="text-input"
                      format="###-###-####"
                      mask="X"
                      placeholder="XXX-XXX-XXXX"
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={6}>
              <InputGroup error={errors.taxId?.message}>
                <InputLabel htmlFor="taxId" size={'large'}>
                  Tax ID Number (EIN)
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="taxId"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat
                      {...fieldProps}
                      ref={ref}
                      className="text-input"
                      format="##-#######"
                      mask="X"
                      placeholder="XX-XXXXXXX"
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={12}>
              <Button
                block={true}
                className="account-signup-form__submit-button"
                disabled={isSubmitting}
                size={'large'}
                {...analyticsSignup}
              >
                {isSignupFlow ? (
                  'Next Step'
                ) : isSubmitting ? (
                  <AnimatedDots>Submitting</AnimatedDots>
                ) : (
                  'Submit'
                )}
              </Button>
            </FlexColumn>
          </FlexRow>
        </form>
      </div>
    </>
  );
};

export default AccountSignupForm;
