import { combineReducers } from '@reduxjs/toolkit';
import { businessCreditSlice } from '../features/Dashboard/BusinessCredit/businessCreditSlice';
import { businessCreditWheelSlice } from '../features/Dashboard/BusinessCredit/businessCreditWheelSlice';
import { businessCreditUserGuideSlice } from '../features/Dashboard/BusinessCredit/userGuideSlice';
import { exchangeRequestsSlice } from '../features/Dashboard/ExchangeRequests/exchangeRequestsSlice';
import { fundingRequestsSlice } from '../features/Dashboard/FundingRequests/fundingRequestsSlice';
import { documentRequestsSlice } from '../features/Documents/documentRequestsSlice';
import { expiredOffersSlice } from '../features/History/ExpiredOffers/expiredOffersSlice';
import { fundedLoansSlice } from '../features/History/FundedLoans/fundedLoansSlice';
import { offersSlice } from '../features/Offers/offersSlice';
import { accountSlice } from '../features/Profile/accountSlice';
import { businessIndustriesSlice } from '../features/Profile/businessIndustriesSlice';
import { businessTypesSlice } from '../features/Profile/businessTypesSlice';
import { consumerSlice } from '../features/Profile/consumerSlice';
import { contactSlice } from '../features/Profile/contactSlice';
import { articlesSlice } from '../features/Resources/Insurance/articlesSlice';
import { userAgentSlice } from '../features/UserAgent/userAgentSlice';
import { featureFlagsSlice, snapshotSlice } from '../slices';

export { default as auth } from '../features/Auth/authSlice';
export { default as creditCards } from '../features/Resources/CreditCards/creditCardsSlice';

export const profile = combineReducers({
  account: accountSlice.reducer,
  contact: contactSlice.reducer,
  consumer: consumerSlice.reducer,
  businessIndustries: businessIndustriesSlice.reducer,
  businessTypes: businessTypesSlice.reducer,
});

export const app = combineReducers({
  featureFlags: featureFlagsSlice.reducer,
  snapshot: snapshotSlice.reducer,
});
export const insurance = combineReducers({
  articles: articlesSlice.reducer,
});

export const history = combineReducers({
  fundedLoans: fundedLoansSlice.reducer,
  expiredOffers: expiredOffersSlice.reducer,
});

export const userAgent = combineReducers({
  userAgent: userAgentSlice.reducer,
});

export const documents = combineReducers({
  documentRequests: documentRequestsSlice.reducer,
});

export const dashboard = combineReducers({
  fundingRequests: fundingRequestsSlice.reducer,
  exchangeRequests: exchangeRequestsSlice.reducer,
  businessCreditGraphPoints: businessCreditSlice.reducer,
  businessCreditWheel: businessCreditWheelSlice.reducer,
  businessCreditUserGuide: businessCreditUserGuideSlice.reducer,
});

export const offers = combineReducers({
  offer: offersSlice.reducer,
});
