import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useScript } from 'usehooks-ts';
import { useLogLifecycle, useLogUpdate } from '~/hooks/logging';
import { useAppSelector } from '../../app/hooks';
import { useGetOfferInfoQuery } from '../../app/services/offers';
import { useCheckAuthStatus } from '../../hooks';
import { selectCurrentUser } from '../Auth/authSlice';
import { User } from '../Auth/types/User';
import { selectOffer } from './offersSlice';
import Offer from './types/Offer';

declare global {
  interface Window {
    configLtOffersUiApp: () => {
      initLtOffersUIApp: (elem: HTMLElement | null) => void;
    };
  }
}

type OffersWidgetProps = {
  paramApp: string;
  paramGuid: string;
  data: Offer | null;
  isUserLoggedIn: boolean;
  paramLtOffersUi: string | null;
  currentUser: User | null;
};

const OffersWidget = ({
  paramApp,
  data,
  paramGuid,
  isUserLoggedIn,
  paramLtOffersUi,
  currentUser,
}: OffersWidgetProps) => {
  useLogLifecycle('OffersWidget', {
    paramGuid,
    paramApp,
  });

  return (
    <div
      className="lt-offers-ui-app-holder"
      id={`${paramApp?.toLocaleLowerCase()}-app`}
      init-app={paramApp}
      init-express-data={
        data?.offersFormData !== null && data?.offersFormData !== undefined
          ? JSON.stringify(data?.offersFormData)
          : ''
      }
      init-fvoffers={data?.fvoffers}
      init-guid={paramGuid}
      init-is-user-authenticated={isUserLoggedIn ? 'true' : 'false'}
      init-logger-webtoken={data?.loggerWebToken}
      init-lt-offers-ui={paramLtOffersUi}
      init-lt-offers-ui-app={paramApp}
      init-lt-offers-ui-env={data?.buildEnv}
      init-tree-auth-id={isUserLoggedIn ? currentUser?.authId : ''}
    />
  );
};

const Offers = (): JSX.Element => {
  const { search } = useLocation();
  const data = useAppSelector(selectOffer);
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const [isUserLoggedIn, isCheckingAuthStatus] = useCheckAuthStatus();

  const { paramApp, paramLtOffersUi, paramGuid } = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const paramApp = searchParams.get('app');
    const paramLtOffersUi = searchParams.get('lt-offers-ui');
    const paramGuid = searchParams.get('guid');

    return {
      paramApp: paramApp ?? '',
      paramLtOffersUi,
      paramGuid: paramGuid ?? '',
    };
  }, [search]);

  useLogLifecycle('Offers', {
    paramApp,
    paramGuid,
  });

  useLogUpdate('Offers', 'QFormUID', paramGuid, {
    paramApp,
  });

  const { isLoading: isGetOfferInfoLoading } = useGetOfferInfoQuery(paramGuid ?? skipToken);

  const scriptSrc = `${data?.ltUiBaseUrl}lt-offers-ui/dist/js/nocache.build.js`;
  const scriptStatus = useScript(scriptSrc);

  useLogUpdate('Offers', 'scriptStatus', scriptStatus, {
    paramApp,
    paramGuid,
  });

  useEffect(() => {
    if (scriptStatus === 'ready') {
      const divElementApp = document.getElementById(`${paramApp?.toLocaleLowerCase()}-app`);

      if (divElementApp != null) window.configLtOffersUiApp().initLtOffersUIApp(divElementApp);
    }
  }, [paramApp, scriptStatus, paramGuid]);

  const navigate = useNavigate();

  useEffect(() => {
    if (paramApp === null || paramGuid === null) {
      window.newrelic.addPageAction('nullParamsRedirectToDashboardFromOffers', {
        paramApp: paramApp ?? '',
        paramGuid: paramGuid ?? '',
      });

      navigate('/dashboard', { replace: true });
    }
  }, [paramApp, paramGuid, navigate]);

  const offersWidgetProps = useMemo(() => {
    return {
      paramApp,
      paramGuid,
      currentUser,
      data,
      isUserLoggedIn,
      paramLtOffersUi,
    };
  }, [currentUser, data, isUserLoggedIn, paramApp, paramGuid, paramLtOffersUi]);

  return (
    <>
      <Helmet>
        <title>Partner Matches</title>
      </Helmet>
      {!isGetOfferInfoLoading && !isCheckingAuthStatus && data && (
        <OffersWidget {...offersWidgetProps} />
      )}
    </>
  );
};

export default Offers;
