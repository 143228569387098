import { useEffect } from 'react';
import { useAuth } from './useAuth';

const LogoutCallback = (): JSX.Element => {
  const auth = useAuth();
  const { logout } = auth;

  useEffect(() => {
    logout();
  }, [logout]);

  return <></>;
};

export default LogoutCallback;
