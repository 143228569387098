import { Block } from '../../../components/Block';
import { Button } from '../../../components/Button';
import { Container } from '../../../components/Container';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { useLTAnalytics } from '../../../hooks';
import DepositAccountsLogo from './images/deposit-accounts-logo.svg';

const bankingAccounts = [
  {
    image: DepositAccountsLogo,
    link: 'https://www.depositaccounts.com/ratetable/checking/business-checking-accounts.html',
    text:
      'Whether your company is a large corporation or a small business, managing your cash flow with a dedicated business checking account is essential to your success. Compare business checking account offers and select the one that aligns best with your company’s digital banking needs.',
    title: 'Business Checking Accounts',
  },
  {
    image: DepositAccountsLogo,
    link: 'https://www.depositaccounts.com/ratetable/checking/business-savings-accounts.html',
    text:
      'Whether your company is a large corporation or a small business, a dedicated business savings account is essential to your success. Compare business saving account offers and select the one that aligns best with your company’s digital banking needs.',
    title: 'Business Savings Accounts',
  },
];

const Banking = (): JSX.Element => {
  const analytics = useLTAnalytics('Learn More');

  return (
    <Container>
      <FlexRow rowGap={'20px'}>
        {bankingAccounts.map((bankingAccount) => (
          <FlexColumn key={bankingAccount.link} columnsLarge={4} columnsMedium={6}>
            <a href={bankingAccount.link} rel="noopener noreferrer" target="_blank" {...analytics}>
              <Block className="resource-block">
                <div className="block__title">{bankingAccount.title}</div>
                <img
                  alt={bankingAccount.title}
                  className="resource-block__image"
                  src={DepositAccountsLogo}
                />
                <div
                  className="block__text"
                  dangerouslySetInnerHTML={{
                    __html: bankingAccount.text,
                  }}
                />
                <Button block={true} className="resource-block__button" size={'large'}>
                  Learn More
                </Button>
              </Block>
            </a>
          </FlexColumn>
        ))}
      </FlexRow>
    </Container>
  );
};

export default Banking;
