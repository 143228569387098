import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useCreateSignupMutation } from '../../app/services';
import { Container } from '../../components/Container';
import { uniqueKey } from '../../utils';
import Account from '../Profile/types/Account';
import Contact from '../Profile/types/Contact';
import AccountSignupForm from './AccountSignupForm';
import './Signup.scss';
import PasswordSignupForm from './Steps/PasswordSignupForm';
import PersonalSignupForm from './Steps/PersonalSignupForm';
import ThankYouSignup from './Steps/ThankYou';
import SignupRequest from './types/SignupRequest';

const Signup = (): JSX.Element => {
  const [
    signUp,
    { data, isLoading, isError: isSignupError, isSuccess: isSignupSuccessful },
  ] = useCreateSignupMutation();
  const [contact, setContact] = useState<Contact>({});
  const [account, setAccount] = useState<Account>({});
  const [stepTitle, setStepTitle] = useState('');
  const [signUpText, setSignUpText] = useState('');
  const [signUpSubTitle, setSignUpSubTitle] = useState('');
  const [progressBarText, setProgressBarText] = useState('');
  const [progressBarWidth, setProgressBarWidth] = useState(0);

  const navigate = useNavigate();
  const handlePersonalSignupFormLoad = () => {
    setStepTitle('SMALL BUSINESS DASHBOARD SIGNUP');
    setSignUpText('Enter your personal information below');
    setProgressBarText("Let's get it started!");
    setProgressBarWidth(5);
  };
  const handleBusinessSignupFormLoad = () => {
    setStepTitle('SMALL BUSINESS DASHBOARD SIGNUP');
    setSignUpText('Enter your business information below');
    setProgressBarText('50% Complete');
    setProgressBarWidth(50);
  };
  const handlePasswordSignupFormLoad = () => {
    setStepTitle('SMALL BUSINESS DASHBOARD SIGNUP');
    setSignUpText('Create Your Password');
    setProgressBarText('99% Complete');
    setProgressBarWidth(95);
    setSignUpSubTitle('Enter and verify your password to create a secure LendingTree account.');
  };

  const handleContactFormSubmit = (contact: Contact) => {
    setContact(contact);
    navigate('/signup/business');
  };
  const handleAccountFormSubmit = (account: Account) => {
    setAccount(account);
    navigate('/signup/password');
  };
  const handlePasswordFormSubmit = (password: string) => {
    contact.password = password;
    setContact(contact);
    const signupRequest: SignupRequest = {
      signUpId: uniqueKey.generateGUID(),
      contact: contact,
      account: account,
    };
    signUp(signupRequest);
    navigate('/signup/thank-you');
  };

  useEffect(() => {
    if (isSignupSuccessful && data?.redirectUrl) {
      window.location.href = data.redirectUrl;
    }
  }, [isSignupSuccessful, data, isLoading, isSignupError]);

  return (
    <div className="signup">
      <Helmet>
        <title>Small Business Financing and Resources - Business.LendingTree.com</title>
      </Helmet>
      <Container>
        <div className="signup__content">
          <Routes>
            <Route
              element={
                <PersonalSignupForm
                  contact={contact}
                  progressBarText={progressBarText}
                  progressBarWidth={progressBarWidth}
                  signUpText={signUpText}
                  stepTitle={stepTitle}
                  onStepLoad={handlePersonalSignupFormLoad}
                  onSubmit={handleContactFormSubmit}
                />
              }
              path="personal"
            />
            <Route
              element={
                <PasswordSignupForm
                  progressBarText={progressBarText}
                  progressBarWidth={progressBarWidth}
                  signUpSubTitle={signUpSubTitle}
                  signUpText={signUpText}
                  stepTitle={stepTitle}
                  onStepLoad={handlePasswordSignupFormLoad}
                  onSubmit={handlePasswordFormSubmit}
                />
              }
              path="password"
            />
            <Route
              element={
                <AccountSignupForm
                  account={account}
                  isSignupFlow={true}
                  progressBarText={progressBarText}
                  progressBarWidth={progressBarWidth}
                  signUpText={signUpText}
                  stepTitle={stepTitle}
                  onStepLoad={handleBusinessSignupFormLoad}
                  onSubmit={handleAccountFormSubmit}
                />
              }
              path="business"
            />
            {(isLoading || isSignupError) && (
              <Route
                element={<ThankYouSignup isError={isSignupError} isLoading={isLoading} />}
                path="thank-you"
              />
            )}
          </Routes>
        </div>
      </Container>
    </div>
  );
};
export default Signup;
