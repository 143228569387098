import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { profileApi } from '../../app/services/profile';
import { RootState } from '../../app/store';
import Contact from './types/Contact';

export type ContactState = Contact;

const initialState: ContactState = {
  id: '',
  firstName: '',
  lastName: '',
  phone: '',
  street: '',
  city: '',
  stateCode: '',
  state: '',
  postalCode: '',
  emailAddress: '',
};

const reducers = {
  setContact: (state: ContactState, action: PayloadAction<Contact>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getContact.matchFulfilled, reducers.setContact);
  },
});

export default contactSlice.reducer;

// Actions
export const { setContact } = contactSlice.actions;

// Selectors
export const selectContact = (state: RootState): Contact => state.profile.contact;
