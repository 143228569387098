import { User as OidcUser } from 'oidc-client-ts';
import { ReactNode, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { AuthContext, AuthManager } from './';
import { setCredentials } from './authSlice';

export const LOGIN_REDIRECT_URI = 'LOGIN_REDIRECT_URI';

export const AuthManagerInstance = new AuthManager();

const serializeUser = (user: OidcUser | null): OidcUser | null => {
  if (!user) return null;
  return JSON.parse(user.toStorageString());
};

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { logout } = AuthManagerInstance;

  const getLoginRedirectUri = useCallback((): string => {
    return sessionStorage.getItem(LOGIN_REDIRECT_URI) ?? '';
  }, []);

  const removeLoginRedirectUri = useCallback((): void => {
    sessionStorage.removeItem(LOGIN_REDIRECT_URI);
  }, []);

  const setLoginRedirectUri = useCallback(() => {
    sessionStorage.setItem(
      LOGIN_REDIRECT_URI,
      `${location.pathname}${location.search}${location.hash}`
    );
  }, [location]);

  const getUser = useCallback(async (): Promise<OidcUser | null> => {
    const user = await AuthManagerInstance.getUser();
    dispatch(setCredentials(serializeUser(user)));
    return Promise.resolve(user);
  }, [dispatch]);

  const isLoggedIn = useCallback(async (): Promise<boolean> => {
    try {
      const user = await getUser();
      return Boolean(user && !user.expired);
    } catch {
      return false;
    }
  }, [getUser]);

  const login = useCallback(async (): Promise<void> => {
    setLoginRedirectUri();
    return AuthManagerInstance.login();
  }, [setLoginRedirectUri]);

  const loginCallback = useCallback(async (): Promise<OidcUser | null> => {
    try {
      const user = await AuthManagerInstance.loginCallback();
      dispatch(setCredentials(serializeUser(user)));
      return Promise.resolve(user);
    } catch (e) {
      return Promise.reject(e);
    }
  }, [dispatch]);

  const logoutCallback = useCallback(async (): Promise<void> => {
    try {
      await AuthManagerInstance.logoutCallback();
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const value = {
    getUser,
    getLoginRedirectUri,
    removeLoginRedirectUri,
    isLoggedIn,
    login,
    loginCallback,
    logout,
    logoutCallback,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
