import './GroveIcons.scss';
import { IconNames } from './types/IconNames';

interface GroveLtIconProps {
  name: IconNames;
  className?: string;
}

const GroveLtIconProps = ({ name, className = '' }: GroveLtIconProps): JSX.Element => {
  const classNames = [`bl-grove-icon--${name}`, ...className.split(' ')];

  return <i className={classNames.join(' ')} />;
};

export default GroveLtIconProps;
