import Stip from '../../types/Stip';
import { default as Document, default as UploadedDoc } from '../../types/UploadedDocument';
import RequiredDocuments from '../RequiredDocuments/RequiredDocuments';
import UploadedDocument from './UploadedDocument';
import './UploadedDocuments.scss';

interface UploadedDocumentProps {
  applicationId?: string;
  externalVisitorId?: string;
  hasAbilityToUploadDocument?: boolean;
  isStipsRequired?: boolean;
  opportunityId?: string;
  uploadedDocuments?: Document[];
  requiredDocuments?: Stip[];
  onDocumentDeleteConfirm?: (documentId: string) => void;
  onUploadDocuments?: (documents: UploadedDoc[]) => void;
}

const UploadedDocuments = ({
  applicationId,
  externalVisitorId,
  hasAbilityToUploadDocument,
  isStipsRequired,
  uploadedDocuments,
  opportunityId,
  requiredDocuments,
  onDocumentDeleteConfirm,
  onUploadDocuments,
}: UploadedDocumentProps): JSX.Element => {
  return (
    <>
      <table className="upload-documents-table">
        <thead className="upload-documents-table__thead">
          <tr className="upload-documents-table__thead-tr">
            <th className="upload-documents-table__thead-th">Document Type</th>
            <th className="upload-documents-table__thead-th">File Name</th>
            <th className="upload-documents-table__thead-th">Date & Time</th>
            <th className="upload-documents-table__thead-th"></th>
          </tr>
        </thead>
        <tbody className="upload-documents-table__tbody">
          {requiredDocuments != null && requiredDocuments.length > 0 && (
            <RequiredDocuments
              applicationId={applicationId}
              externalVisitorId={externalVisitorId}
              hasAbilityToUploadDocument={hasAbilityToUploadDocument}
              isStipsRequired={isStipsRequired}
              opportunityId={opportunityId}
              value={requiredDocuments}
              onUploadDocuments={onUploadDocuments}
            />
          )}
          {uploadedDocuments != null && uploadedDocuments.length > 0 && (
            <>
              {uploadedDocuments?.map((uploadedDocument) => (
                <UploadedDocument
                  key={uploadedDocument?.documentId}
                  applicationId={applicationId}
                  externalVisitorId={externalVisitorId}
                  hasAbilityToUploadDocument={hasAbilityToUploadDocument}
                  isStipsRequired={isStipsRequired}
                  stips={
                    uploadedDocuments.filter(
                      (doc) => doc.documentType === uploadedDocument.documentType
                    ).length > 1
                      ? undefined
                      : requiredDocuments
                  }
                  uploadedDocument={uploadedDocument}
                  onDocumentDeleteConfirm={(docId: string) => onDocumentDeleteConfirm?.(docId)}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default UploadedDocuments;
