import classNames from 'classnames';
import { ReactNode } from 'react';
import './Button.scss';

type ButtonColors = 'blue' | 'orange' | 'blue-outline' | 'gray' | 'lt-blue-outline' | 'nocolor';
type ButtonSizes = 'default' | 'large';

export interface ButtonProps {
  block?: boolean;
  children: ReactNode;
  className?: string;
  color?: ButtonColors;
  compact?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  size?: ButtonSizes;
  loading?: boolean;
}

const Button = ({
  block,
  children,
  className,
  color = 'blue',
  compact = false,
  disabled = false,
  onClick,
  size = 'default',
  loading = false,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={classNames(
        className,
        `button--${color}`,
        { 'button--block': block },
        { 'button--compact': compact },
        {
          [`button--${size}`]: size !== 'default',
        },
        {
          'button--loading': loading,
        }
      )}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {!loading && children}
      {loading && (
        <svg viewBox="0 0 50 50">
          <circle cx="25" cy="25" fill="none" r="20" strokeWidth="5" />
        </svg>
      )}
    </button>
  );
};

export default Button;
