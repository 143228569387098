import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { offersApi } from '../../app/services/offers';
import { RootState } from '../../app/store';
import Offer from './types/Offer';

export type OfferState = Offer;

const initialState: OfferState = {
  id: '',
  ltUiBaseUrl: '',
  uiBaseUrl: '',
  buildEnv: '',
  isAuthenticated: false,
  treeAuthId: '',
  loggerWebToken: '',
  fvoffers: '',
  offersFormData: '',
};

const reducers = {
  setOffer: (state: OfferState, action: PayloadAction<Offer>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(offersApi.endpoints.getOfferInfo.matchFulfilled, reducers.setOffer);
  },
});

export default offersSlice.reducer;

// Actions
export const { setOffer } = offersSlice.actions;

// Selectors
export const selectOffer = (state: RootState): Offer | null => state.offers.offer;
