import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import * as yup from 'yup';
import { useAppSelector } from '../../../app/hooks';
import {
  useGetAccountQuery,
  useGetBusinessTypesQuery,
  useGetContactQuery,
} from '../../../app/services';
import { AnimatedDots } from '../../../components/AnimatedDots';
import { Button } from '../../../components/Button';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { LtIcon } from '../../../components/Icon';
import {
  BusinessIndustrySelectInput,
  InputGroup,
  InputLabel,
  MonthSelectInput,
  SelectInput,
  StateSelectInput,
  TextInput,
} from '../../../components/Input';
import SelectOption from '../../../components/Input/Select/types/SelectOption';
import { Tooltip } from '../../../components/Tooltip';
import { MEDIA_QUERIES } from '../../../constants';
import { selectAccount } from '../../Profile/accountSlice';
import { selectBusinessTypes } from '../../Profile/businessTypesSlice';
import { selectContact } from '../../Profile/contactSlice';
import ProfileData from '../../Profile/types/ProfileData';
import './ProfileForm.scss';

const businessIndustryText =
  'This list is made up of North American Industry Classification System (NAICS) industries. You may choose to update or keep your current industry selection.';

interface ProfileFormInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactPhone: string;
  contactStreet: string;
  contactCity: string;
  contactStateCode: string;
  contactPostalCode: string;
  businessName: string;
  businessInceptionMonth: string;
  businessInceptionYear: string;
  businessCity: string;
  businessStateCode: string;
  businessStreet: string;
  businessPostalCode: string;
  businessPhone: string;
  businessType: string;
  businessIndustry: string;
  businessTaxId: string;
}

const schema = yup.object().shape({
  firstName: yup
    .string()
    .label('First Name')
    .required()
    .matches(/^[a-zA-Z\s]+$/i, '${path} must be valid')
    .max(255)
    .nullable(),
  lastName: yup
    .string()
    .label('Last Name')
    .required()
    .matches(/^[a-zA-Z\s]+$/i, '${path} must be valid')
    .max(255)
    .nullable(),
  contactPhone: yup
    .string()
    .label('Phone Number')
    .required()
    .matches(/^[0-9-]+$/i, '${path} must be valid')
    .nullable(),
  contactStreet: yup
    .string()
    .label('Street Address')
    .required()
    .matches(/^([a-zA-Z0-9,#-.\r\n ])+$/i, '${path} must be valid')
    .max(255)
    .nullable(),
  contactCity: yup
    .string()
    .label('City')
    .required()
    .matches(/^[a-zA-Z\s]+$/i, '${path} must be valid')
    .max(40)
    .nullable(),
  contactStateCode: yup.string().label('State').required().nullable(),
  contactPostalCode: yup
    .string()
    .label('Zip Code')
    .required()
    .length(5)
    .matches(/^[0-9]+$/i, '${path} must be numeric')
    .nullable(),
  businessName: yup
    .string()
    .label('Business Name')
    .required()
    .matches(/^[a-zA-Z\s]+$/i, '${path} must be valid')
    .max(255)
    .nullable(),
  businessStreet: yup
    .string()
    .label('Street Address')
    .required()
    .matches(/^([a-zA-Z0-9,#-.\r\n ])+$/i, '${path} must be valid')
    .max(255)
    .nullable(),
  businessCity: yup
    .string()
    .label('City')
    .required()
    .matches(/^[a-zA-Z\s]+$/i, '${path} must be valid')
    .max(40)
    .nullable(),
  businessStateCode: yup.string().label('State').required().nullable(),
  businessPostalCode: yup
    .string()
    .label('Zip Code')
    .required()
    .length(5)
    .matches(/^[0-9]+$/i, '${path} must be numeric')
    .nullable(),
  businessInceptionMonth: yup.string().label('Business Month').required().nullable(),
  businessInceptionYear: yup.string().label('Business Year').required().nullable(),
  businessType: yup.string().label('Business Type').required().nullable(),
  businessIndustry: yup.string().label('Business Industry').required().nullable(),
  businessTaxId: yup
    .string()
    .label('Tax ID Number (EIN)')
    .matches(/^$|^[0-9-]+$/i, '${path} must be valid')
    .nullable(),
});

interface ProfileFormProps {
  isSubmitting: boolean;
  onSubmit?: (profile: ProfileData) => void;
}

const ProfileForm = ({
  isSubmitting,
  onSubmit = () => undefined,
}: ProfileFormProps): JSX.Element => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ProfileFormInput>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const { dirtyFields } = useFormState({ control });

  const { isFetching: isAccountFetching } = useGetAccountQuery();
  const { isFetching: isContactFetching } = useGetContactQuery();
  const contact = useAppSelector(selectContact);
  const account = useAppSelector(selectAccount);

  useGetBusinessTypesQuery();
  const types = useAppSelector(selectBusinessTypes);

  const [yearOptions, setYearOptions] = useState<SelectOption[]>([]);

  const inceptionDate = useMemo(() => {
    if (account.inceptionDate) {
      const date = new Date(account.inceptionDate);
      return {
        month: (date.getMonth() + 1).toString(),
        year: date.getFullYear().toString(),
      };
    }
    return {
      month: '',
      year: '',
    };
  }, [account]);

  useEffect(() => {
    if (!isAccountFetching) {
      const currentYear = new Date().getFullYear();
      const options: SelectOption[] = [];
      for (let i = currentYear; i >= currentYear - 100; i--) {
        options.push({ value: `${i}`, label: `${i}` });
        setYearOptions(options);
      }
    }
  }, [isAccountFetching]);

  useEffect(() => {
    if (!isAccountFetching && !isContactFetching) {
      reset({
        firstName: contact.firstName,
        lastName: contact.lastName,
        emailAddress: contact.emailAddress,
        contactPhone: contact.phone ?? account.phone,
        contactStreet: contact.street,
        contactCity: contact.city,
        contactStateCode: contact.stateCode,
        contactPostalCode: contact.postalCode,
        businessName: account.name,
        businessInceptionMonth: inceptionDate.month,
        businessInceptionYear: inceptionDate.year,
        businessStreet: account.address?.street,
        businessCity: account.address?.city,
        businessStateCode: account.address?.stateCode,
        businessPostalCode: account.address?.postalCode,
        businessType: account.type,
        businessIndustry: account.industry,
        businessTaxId: account.taxId,
      });
    }
  }, [isContactFetching, isAccountFetching, contact, account, inceptionDate, reset]);

  const handleFormSubmit = handleSubmit(
    ({
      firstName,
      lastName,
      emailAddress,
      contactPhone,
      contactStreet,
      contactCity,
      contactStateCode,
      contactPostalCode,
      businessName,
      businessInceptionMonth,
      businessInceptionYear,
      businessStreet,
      businessCity,
      businessStateCode,
      businessPostalCode,
      businessType,
      businessIndustry,
      businessTaxId,
    }: ProfileFormInput) => {
      const profile: ProfileData = {
        contact: {
          id: '',
          firstName,
          lastName,
          phone: contactPhone.replaceAll('-', ''),
          street: contactStreet,
          city: contactCity,
          state: contact.state,
          stateCode: contactStateCode,
          postalCode: contactPostalCode,
          emailAddress,
        },
        account: {
          id: '',
          name: businessName,
          phone: account.phone,
          address: {
            street: businessStreet,
            city: businessCity,
            stateCode: businessStateCode,
            postalCode: businessPostalCode,
          },
          taxId: businessTaxId ? businessTaxId.replaceAll('-', '') : '',
          inceptionDate: `${businessInceptionMonth}/1/${businessInceptionYear}`,
          type: businessType,
          industry: businessIndustry,
        },
        dirtyFields: dirtyFields,
      };

      onSubmit(profile);
    }
  );

  return (
    <div className="profile-form__request-box">
      {!isContactFetching && !isAccountFetching && (
        <form onSubmit={handleFormSubmit}>
          <h4 className="profile-form__title-name">About You</h4>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.firstName?.message}>
                <InputLabel htmlFor="firstName">First Name</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="firstName"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} name={'firstName'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.lastName?.message}>
                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="lastName"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} name={'lastName'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <hr />
          <h4 className="profile-form__title-name">Contact Information</h4>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.emailAddress?.message}>
                <InputLabel htmlFor="emailAddress">Email Address</InputLabel>
                <Controller
                  control={control}
                  defaultValue={contact.emailAddress}
                  name="emailAddress"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} disabled={true} name={'emailAddress'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={3}>
              <InputGroup error={errors.contactPhone?.message}>
                <InputLabel htmlFor="contactPhone">Phone Number</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="contactPhone"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat
                      {...fieldProps}
                      ref={ref}
                      className="text-input"
                      format="###-###-####"
                      mask="X"
                      placeholder="XXX-XXX-XXXX"
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.contactStreet?.message}>
                <InputLabel htmlFor="contactStreet">Street Address</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="contactStreet"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} name={'contactStreet'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={3}>
              <InputGroup error={errors.contactCity?.message}>
                <InputLabel htmlFor="contactCity">City</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="contactCity"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} name={'contactCity'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={2}>
              <InputGroup error={errors.contactStateCode?.message}>
                <InputLabel htmlFor="contactStateCode">State</InputLabel>
                <Controller
                  control={control}
                  name="contactStateCode"
                  render={({ field: { onChange, ref } }) => (
                    <StateSelectInput
                      ref={ref}
                      defaultValue={{
                        value: contact.stateCode,
                        label: contact.stateCode,
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={2}>
              <InputGroup error={errors.contactPostalCode?.message}>
                <InputLabel htmlFor="contactPostalCode">Zip Code</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="contactPostalCode"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat {...fieldProps} ref={ref} className="text-input" format="#####" />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <hr />
          <h4 className="profile-form__title-name">Business Information</h4>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.businessName?.message}>
                <InputLabel htmlFor="businessName">Business Name</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="businessName"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} name={'businessName'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={3}>
              <InputGroup error={errors.businessInceptionMonth?.message}>
                <InputLabel>Business Inception</InputLabel>
                <Controller
                  control={control}
                  name="businessInceptionMonth"
                  render={({ field: { onChange, ref } }) => (
                    <MonthSelectInput
                      ref={ref}
                      defaultValue={inceptionDate.month}
                      onChange={onChange}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={2}>
              <InputGroup error={errors.businessInceptionYear?.message}>
                <label className="profile-form__label"></label>
                <Controller
                  control={control}
                  name="businessInceptionYear"
                  render={({ field: { onChange, ref } }) => (
                    <SelectInput
                      ref={ref}
                      defaultValue={{
                        value: inceptionDate.year,
                        label: inceptionDate.year,
                      }}
                      name="businessInceptionYear"
                      options={yearOptions}
                      onChange={onChange}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.businessStreet?.message}>
                <InputLabel htmlFor="businessStreet">Street Address</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="businessStreet"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} name={'businessStreet'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={3}>
              <InputGroup error={errors.businessCity?.message}>
                <InputLabel htmlFor="businessCity">City</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="businessCity"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput {...fieldProps} ref={ref} name={'businessCity'} />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={2}>
              <InputGroup error={errors.businessStateCode?.message}>
                <InputLabel htmlFor="businessStateCode">State</InputLabel>
                <Controller
                  control={control}
                  name="businessStateCode"
                  render={({ field: { onChange, ref } }) => (
                    <StateSelectInput
                      ref={ref}
                      defaultValue={{
                        value: account.address?.stateCode,
                        label: account.address?.stateCode,
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={2}>
              <InputGroup error={errors.businessPostalCode?.message}>
                <InputLabel htmlFor="businessPostalCode">Zip Code</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="businessPostalCode"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat {...fieldProps} ref={ref} className="text-input" format="#####" />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.businessType?.message}>
                <InputLabel htmlFor="businessType">Business Type</InputLabel>
                <Controller
                  control={control}
                  name="businessType"
                  render={({ field: { onChange, ref } }) => (
                    <SelectInput
                      ref={ref}
                      defaultValue={{
                        value: account.type,
                        label: account.type,
                      }}
                      options={Object.values(types).map((o) => ({ value: o, label: o }))}
                      onChange={onChange}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.businessIndustry?.message}>
                <InputLabel htmlFor="businessIndustry">
                  Business Industry&nbsp;
                  <Tooltip
                    content={businessIndustryText}
                    offset={isSmallDown ? [60, 10] : [0, 10]}
                    placement={isSmallDown ? 'top' : 'right'}
                  >
                    <span>
                      <LtIcon name="Question" />
                    </span>
                  </Tooltip>
                </InputLabel>
                <Controller
                  control={control}
                  name="businessIndustry"
                  render={({ field: { onChange, ref } }) => (
                    <BusinessIndustrySelectInput
                      ref={ref}
                      defaultValue={{
                        value: account.industry,
                        label: account.industry,
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.businessTaxId?.message}>
                <InputLabel htmlFor="businessTaxId">Business Tax ID</InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="businessTaxId"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat
                      {...fieldProps}
                      ref={ref}
                      className="text-input"
                      format="##-#######"
                      mask="X"
                      placeholder="XX-XXXXXXX"
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={12}>
              <Button block={true} disabled={isSubmitting}>
                {isSubmitting ? <AnimatedDots>Saving</AnimatedDots> : 'Save Changes'}
              </Button>
            </FlexColumn>
          </FlexRow>
        </form>
      )}
    </div>
  );
};

export default ProfileForm;
