import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../../constants';
import TermBucket from '../FundingRequests/types/TermBucket';
import './ProgressBar.scss';

type ProgressBarSectionProps = {
  label: string;
};

type ProgressBarProps = {
  pct: number;
  termBuckets: Array<TermBucket>;
};

const ProgressBarSection: React.FC<ProgressBarSectionProps> = ({ label }) => {
  return (
    <div className="renewals-progress__section">
      <div className="renewals-progress__section-label">{label}</div>
    </div>
  );
};

export default function ProgressBar(props: ProgressBarProps): JSX.Element {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });

  const getBucketLabel = (index: number) => {
    let sum = 0;
    for (let i = index; i >= 0; i--) {
      sum += props.termBuckets[i].duration;
    }

    return isSmallDown ? `${sum}` : `${sum} mo`;
  };

  return (
    <div className="renewals-progress">
      <div className="renewals-progress__bar">
        <div className="renewals-progress__bar--pct" style={{ width: `${props.pct}%` }} />
      </div>
      <div className="renewals-progress__sections">
        {props.termBuckets.length > 0 &&
          props.termBuckets.map((_, index) => (
            <ProgressBarSection key={index} label={getBucketLabel(index)} />
          ))}
      </div>
    </div>
  );
}
