import './Photo.scss';

interface PhotoProps {
  profilePhotoAsBase64String?: string;
}

const Photo = ({ profilePhotoAsBase64String }: PhotoProps): JSX.Element => {
  return (
    <div className="photo">
      <img className="photo__image" src={profilePhotoAsBase64String ?? ''} />
    </div>
  );
};

export default Photo;
