import CreditCard from '../../features/Resources/CreditCards/types/CreditCard';
import { borrowerPortalApi } from './borrowerPortalBase';

export const creditCardsApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCards: builder.query<CreditCard[], void>({
      query: () => 'credit-cards',
    }),
  }),
  overrideExisting: false,
});

export const { useGetCardsQuery } = creditCardsApi;
