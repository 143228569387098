import Congratulation from '../../features/Congratulations/types/Congratulation';
import Offer from '../../features/Offers/types/Offer';
import { borrowerPortalApi } from './borrowerPortalBase';

export const offersApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getOfferInfo: builder.query<Offer, string>({
      query: (qFormUid) => 'offers/getOffersInfo/' + qFormUid,
    }),
    getOfferUrl: builder.query<string, string>({
      query: (qFormUid) => ({
        url: 'offers/getOfferUrl/' + qFormUid,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
    getCongratulationsData: builder.query<Congratulation, string>({
      query: (qFormUid) => 'offers/congratulations/' + qFormUid,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOfferInfoQuery,
  useGetOfferUrlQuery,
  useGetCongratulationsDataQuery,
} = offersApi;
