import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '../../../src/components/Container';
import { useAppSelector } from '../../app/hooks';
import { useGetExpiredOffersQuery, useGetFundedLoansQuery } from '../../app/services/history';
import { selectSnapshot } from '../../slices/snapshotSlice';
import HeroImage from '../HeroImage/HeroImage';
import { UserAgent } from '../UserAgent';
import ExpiredOffers from './ExpiredOffers/ExpiredOffers';
import { selectExpiredOffers } from './ExpiredOffers/expiredOffersSlice';
import FundedLoans from './FundedLoans/FundedLoans';
import { selectFundedLoans } from './FundedLoans/fundedLoansSlice';
import './History.scss';
import NewFunding from './NewFunding/NewFunding';

const History = (): JSX.Element => {
  const [hideHeroImage, setHideHeroImage] = useState(false);
  const [fundedLoansText, setFundedLoansText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { isLoading: isFundedLoansLoading } = useGetFundedLoansQuery();
  const { isLoading: isExpiredOfferLoading } = useGetExpiredOffersQuery();
  const fundedLoans = useAppSelector(selectFundedLoans);
  const expiredOffers = useAppSelector(selectExpiredOffers);
  const snapshot = useAppSelector(selectSnapshot);

  useEffect(() => {
    setHideHeroImage(
      !isFundedLoansLoading &&
        !isExpiredOfferLoading &&
        fundedLoans.length == 0 &&
        expiredOffers.length == 0
    );
    setFundedLoansText(
      !isFundedLoansLoading && !isExpiredOfferLoading
        ? fundedLoans.length != 0
          ? 'See your previous financings.'
          : 'You don’t have any funded loans.'
        : 'Loading...'
    );
    setIsLoading(isFundedLoansLoading && isExpiredOfferLoading);
  }, [fundedLoans, expiredOffers, isFundedLoansLoading, isExpiredOfferLoading]);

  return (
    <>
      <Helmet>
        <title>History</title>
      </Helmet>
      <UserAgent
        displayUserAgent={snapshot.conciergeFundingRequests > 0}
        pageTitle="HISTORY"
        subHeader={fundedLoansText}
      />
      <div className="history">
        <Container>
          {!isLoading && (
            <>
              {!hideHeroImage && (
                <>
                  <FundedLoans fundedLoans={fundedLoans} />
                  {!isFundedLoansLoading && !isExpiredOfferLoading && <NewFunding />}
                  <ExpiredOffers expiredOffers={expiredOffers} />
                </>
              )}
              {hideHeroImage && <HeroImage />}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default History;
