import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useScript } from 'usehooks-ts';
import { useAppSelector } from '~/app/hooks';
import {
  useGetAccountQuery,
  useGetContactQuery,
  useGetFundingRequestsQuery,
  useLazyGetFundingRequestsQuery,
} from '~/app/services';
import { useLazyGetFvoffersValueQuery } from '~/app/services/appSetting';
import { Container } from '~/components/Container';
import { selectLatestOpenFundingRequest } from '~/features/Dashboard/FundingRequests/fundingRequestsSlice';
import { selectAccount } from '~/features/Profile/accountSlice';
import { selectContact } from '~/features/Profile/contactSlice';
import { useToggle } from '~/hooks';
import './InsideForm.scss';
import RedirectModal from './RedirectModal';
import GlobalPhoenix from './types/GlobalPhoenix';
import OnSubmitResponse from './types/OnSubmitResponse';

const LT_BASE_DOMAIN = process.env.LT_BASE_URL;
const scriptSrc = `${LT_BASE_DOMAIN}/path/bundle.js`;
const cssHref = `${LT_BASE_DOMAIN}/forms/assets/css/standard-single-step.css`;

const REDIRECT_TIMEOUT =
  process.env.REDIRECT_TIMEOUT === undefined ? 1000 * 12 : parseInt(process.env.REDIRECT_TIMEOUT);

const businessTypes = [
  { key: 'Sole Proprietor (DBA)', value: 'SOLEPROPRIETORSHIP' },
  { key: 'Other', value: 'PARTNERSHIP' },
  { key: 'Corp', value: 'CORPORATION' },
  { key: 'S-Corp', value: 'SCORPORATION' },
];

const marketPlaceUidNonConcierge = '99020';

const InsideForm = (): JSX.Element => {
  const scriptStatus = useScript(scriptSrc);
  const [showRedirectModal, toggleShowRedirectModal] = useToggle(false);
  const { formId } = useParams();
  const navigate = useNavigate();

  const [onSubmitResponse, setOnSubmitResponse] = useState<OnSubmitResponse>();

  const { isFetching: isAccountFetching } = useGetAccountQuery();
  const { isFetching: isContactFetching } = useGetContactQuery();
  const { isFetching: isFundingRequestsFetching } = useGetFundingRequestsQuery();
  const [
    getLazyFundingRequestsQuery,
    {
      isFetching: isLazyFundingRequestsFetching,
      isUninitialized: isLazyFundingRequestsUninitialized,
    },
  ] = useLazyGetFundingRequestsQuery();
  const [
    getFvoffersValueQuery,
    {
      isFetching: isFvoffersValueFetching,
      isUninitialized: isFvoffersValueUninitialized,
      data: fvoffersValueData,
    },
  ] = useLazyGetFvoffersValueQuery();

  const contact = useAppSelector(selectContact);
  const account = useAppSelector(selectAccount);
  const openFundingRequest = useAppSelector(selectLatestOpenFundingRequest);

  let redirectTimer = 0;

  useEffect(() => {
    return () => window.clearTimeout(redirectTimer);
  }, [redirectTimer]);

  // For a concierge user, redirect to the dashboard page
  useEffect(() => {
    if (!isLazyFundingRequestsUninitialized && !isLazyFundingRequestsFetching) {
      navigate('/dashboard');
    }
  }, [isLazyFundingRequestsUninitialized, isLazyFundingRequestsFetching, navigate]);

  // For a non-concierge user, redirect to the offers page
  useEffect(() => {
    if (!isFvoffersValueUninitialized && !isFvoffersValueFetching) {
      if (onSubmitResponse?.guid != null) {
        let offersRedirectUrl = `/offers?app=business&lt-offers-ui=1&guid=${onSubmitResponse?.guid}`;

        if (fvoffersValueData !== undefined && fvoffersValueData?.fvoffersValue !== null) {
          offersRedirectUrl += `&fvoffers=${fvoffersValueData?.fvoffersValue}`;
        }

        navigate(offersRedirectUrl);
      } else {
        navigate('/dashboard');
      }
    }
  }, [
    isFvoffersValueUninitialized,
    isFvoffersValueFetching,
    onSubmitResponse,
    fvoffersValueData,
    navigate,
  ]);

  const handleOnSubmit = (response: OnSubmitResponse) => {
    setOnSubmitResponse(response);
    toggleShowRedirectModal();

    if (response?.mpid == null) {
      navigate('/dashboard');
    }

    if (response?.mpid === marketPlaceUidNonConcierge) {
      getFvoffersValueQuery();
    } else {
      // Set delay to give the data time to sync in Salesforce before executing query
      redirectTimer = window.setTimeout(() => {
        getLazyFundingRequestsQuery();
      }, REDIRECT_TIMEOUT);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);

  if (
    scriptStatus === 'ready' &&
    !isAccountFetching &&
    !isContactFetching &&
    !isFundingRequestsFetching
  ) {
    const accountType = businessTypes.find((x) => x.key == account.type)?.value;
    (window as GlobalPhoenix & typeof globalThis).ltPhoenix.init({
      formId: formId ?? '',
      formData: {
        ...(contact?.id && { SalesforceContactId: contact.id }),
        ...(contact?.firstName && { FirstName: contact.firstName }),
        ...(contact?.lastName && { LastName: contact.lastName }),
        ...(contact?.street && { StreetAddressLine1: contact.street }),
        ...(contact?.city && { City: contact.city }),
        ...(contact?.stateCode && { State: contact.stateCode }),
        ...(contact?.postalCode && { ZipCode: contact.postalCode }),
        ...(contact?.birthDate && {
          DOB: new Date(contact.birthDate).toLocaleDateString('en-us', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }),
        }),
        ...(contact?.ownershipPercentage != null && {
          OwnershipPercent: contact.ownershipPercentage.toString(),
        }),
        ...(account?.id && { SalesforceAccountId: account.id }),
        ...(account?.name && { BusinessName: account.name }),
        ...(account?.address?.street && {
          BusinessStreetAddressLine1: account.address.street,
        }),
        ...(account?.address?.city && {
          BusinessAddressCity: account.address.city,
        }),
        ...(account?.address?.stateCode && {
          BusinessAddressState: account.address.stateCode,
        }),
        ...(account?.address?.postalCode && {
          BusinessAddressZipCode: account.address.postalCode,
        }),
        ...(contact?.phone && { PhoneNumber: contact?.phone }),
        ...(account?.inceptionDate && {
          BusinessInceptionDate: account.inceptionDate,
        }),
        ...(account?.hasAdditionalManagers != null && {
          SharedManagementIndicator: account.hasAdditionalManagers ? 'true' : 'false',
        }),
        ...(account?.type && { BusinessType: accountType }),
        ...(account?.taxId && { BusinessTaxId: account.taxId }),
        ...(contact?.emailAddress && { EmailAddress: contact.emailAddress }),
        SnapCapApplicationId: urlParams.get('bl_app_id') ?? openFundingRequest?.applicationId,
      },
      config: {
        disableInternalAnalytics: true,
        preventRedirect: true,
        disableAuthenticationRedirect: true,
        disableIdleEvent: true,
      },
      callbacks: {
        onSubmit: handleOnSubmit,
      },
    });
  }

  if (!formId)
    return (
      <>
        <Helmet>
          <title>New Funding Request</title>
        </Helmet>
        <div className="inside-form inside-form--error">Form configuration cannot be loaded</div>
      </>
    );

  return (
    <div className="inside-form">
      <Helmet>
        <title>New Funding Request</title>
      </Helmet>
      <RedirectModal show={showRedirectModal} onClose={toggleShowRedirectModal} />
      <Container>
        <div className="ltFormAppContainer" data-disable-auto-init="true" id="ltPhoenixApp" />
      </Container>
      <link href={cssHref} rel="stylesheet" />
    </div>
  );
};

export default InsideForm;
