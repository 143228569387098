import classNames from 'classnames';
import Flex, { FlexProps } from './Flex';
import './FlexRow.scss';

interface FlexRowProps extends FlexProps {
  rowGap?: number | string;
}

const FlexRow = ({ children, className, rowGap, ...otherProps }: FlexRowProps): JSX.Element => (
  <Flex
    className={classNames('flex-row', className)}
    {...otherProps}
    style={{ ...(rowGap && { rowGap }) }}
  >
    {children}
  </Flex>
);

export default FlexRow;
