import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useHasTreeAuthAccountMutation } from '../../app/services/profile';
import { Button } from '../../components/Button';
import { Container } from '../../components/Container';
import { FlexColumn, FlexRow } from '../../components/Flex';
import { LtIcon } from '../../components/Icon';
import { InputGroup, InputLabel, TextInput } from '../../components/Input';
import { MEDIA_QUERIES } from '../../constants';
import { useLTAnalytics } from '../../hooks/useLTAnalytics';
import Contact from '../Profile/types/Contact';
import './EntryPage.scss';
import MobileBorrowerPortal from './images/mobile-borrower-portal.png';

interface EmailStepInput {
  emailAddress: string;
}

const schema = yup.object().shape({
  emailAddress: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Please enter email address.')
    .max(100),
});

const EntryPage = (): JSX.Element => {
  const [emailAddress, setEmailAddress] = useState('');
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });
  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
  } = useForm<EmailStepInput>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const analyticsSignup = useLTAnalytics('Sign Up');

  const [
    checkHasTreeAuthAccount,
    { isLoading, isSuccess, data: hasTreeAuthAccount },
  ] = useHasTreeAuthAccountMutation();

  const handleFormSubmit = handleSubmit(({ emailAddress }: EmailStepInput) => {
    setEmailAddress(emailAddress);

    const email: Contact = { emailAddress };
    checkHasTreeAuthAccount(email);
  });

  useEffect(() => {
    if (isSuccess) {
      if (hasTreeAuthAccount === false) {
        const path = '/signup/personal/';
        navigate(path, { replace: false, state: emailAddress });
      }

      if (!isLoading && hasTreeAuthAccount === true) {
        navigate('/signup/existing-treeauth');
      }
    }
  }, [isLoading, isSuccess, hasTreeAuthAccount, emailAddress, navigate, setError]);

  return (
    <div className="entry-page">
      <FlexRow>
        <FlexColumn className="entry-page__header" columns={12}>
          <Container>
            <div className="entry-page__header--contents">
              <div className="header-left-side">
                <h1 className="entry-page__header--page-title">
                  Find financing to fuel your business
                </h1>
                <h3 className="entry-page__header--page-sub-title">
                  Give your business the boost it’s been waiting for
                </h3>
                {!isSmallDown && (
                  <ul className="business-boost-list business-boost-list-dektop">
                    <li className="business-boost-list__li">
                      <LtIcon name="Check" />
                      Connect with lenders to find the best funding options for your business.
                    </li>
                    <li className="business-boost-list__li">
                      <LtIcon name="Check" />
                      Review all the lenders you’ve matched with and make additional funding
                      requests when you’re ready.
                    </li>
                    <li className="business-boost-list__li">
                      <LtIcon name="Check" />
                      Know that you’re making the right business decisions with the right tools at
                      your disposal.
                    </li>
                  </ul>
                )}
              </div>
              <div className="header-right-side">
                <div className="emailstep-form">
                  <h3 className="emailstep-form__title">Create Your Free Account</h3>
                  <div className="emailstep-form__email">
                    <form onSubmit={handleFormSubmit}>
                      <InputGroup error={errors.emailAddress?.message}>
                        <InputLabel className="emailstep-form__email--label" htmlFor="emailAddress">
                          Email Address
                        </InputLabel>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name="emailAddress"
                          render={({ field: { ref, ...fieldProps } }) => (
                            <TextInput
                              {...fieldProps}
                              ref={ref}
                              className="emailstep-form__email--input"
                              heightSize={'large'}
                              name={'emailAddress'}
                            />
                          )}
                        />
                      </InputGroup>
                      <Button
                        block={true}
                        className="emailstep-form__signup-cta"
                        loading={isLoading}
                        size={'large'}
                        {...analyticsSignup}
                      >
                        Sign Up
                      </Button>
                    </form>
                  </div>
                  <div className="emailstep-form__signin">
                    Already have an account?
                    <Link className="emailstep-form__signin--link" to="/home">
                      {' '}
                      Sign in
                    </Link>
                  </div>
                </div>
              </div>
              {isSmallDown && (
                <ul className="business-boost-list business-boost-list-mobile">
                  <li className="business-boost-list__li">
                    <LtIcon name="Check" />
                    Connect with lenders to find the best funding options for your business.
                  </li>
                  <li className="business-boost-list__li">
                    <LtIcon name="Check" />
                    Review all the lenders you’ve matched with and make additional funding requests
                    when you’re ready.
                  </li>
                  <li className="business-boost-list__li">
                    <LtIcon name="Check" />
                    Know that you’re making the right business decisions with the right tools at
                    your disposal.
                  </li>
                </ul>
              )}
            </div>
          </Container>
        </FlexColumn>
      </FlexRow>
      <div className="separator-div"></div>
      <FlexRow>
        <FlexColumn columns={12}>
          <div className="business-grow">
            <div className="business-grow__img-block">
              <img
                alt="concierge"
                className="business-grow__img-block--concierge"
                src={MobileBorrowerPortal}
              />
            </div>
            <div className="business-grow__content-block">
              <h2 className="business-grow__content-block--title">Ready to grow?</h2>
              <p className="business-grow__content-block--text">
                Business can always be better. Use LendingTree’s tools, resources, and insights to
                take your business in a bold new direction.
              </p>
              <div className="business-grow-wrapper">
                <div className="business-grow-wrapper__box">
                  <LtIcon name="CreditScore" />
                  <div className="business-grow-wrapper__box--label">No Impact to Your Credit</div>
                  <div className="business-grow-wrapper__box--text">
                    Creating an account won&#39;t affect your credit.
                  </div>
                </div>
                <div className="business-grow-wrapper__box">
                  <LtIcon name="Cash" />
                  <div className="business-grow-wrapper__box--label">Forever Free</div>
                  <div className="business-grow-wrapper__box--text">
                    You&#39;ll never have to pay for your membership.
                  </div>
                </div>
                <div className="business-grow-wrapper__box">
                  <LtIcon name="Lock" />
                  <div className="business-grow-wrapper__box--label">Safe and Secure</div>
                  <div className="business-grow-wrapper__box--text">
                    We use vetted security measures to protect your data.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FlexColumn>
      </FlexRow>
    </div>
  );
};

export default EntryPage;
