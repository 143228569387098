import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { historyApi } from '../../../../src/app/services/history';
import { RootState } from '../../../../src/app/store';
import ExpiredOffer from './types/ExpiredOffer';

const expiredOffersAdapter = createEntityAdapter<ExpiredOffer>();
export type ExpiredOfferState = EntityState<ExpiredOffer>;

const reducers = {
  setExpiredOffers: (state: ExpiredOfferState, action: PayloadAction<ExpiredOffer[]>) => {
    expiredOffersAdapter.setAll(state, action.payload);
  },
};

export const expiredOffersSlice = createSlice({
  name: 'fundedLoans',
  initialState: expiredOffersAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      historyApi.endpoints.getExpiredOffers.matchFulfilled,
      reducers.setExpiredOffers
    );
  },
});

export default expiredOffersSlice.reducer;

// Actions
export const { setExpiredOffers } = expiredOffersSlice.actions;

// Selectors
const expiredOffersSelectors = expiredOffersAdapter.getSelectors<RootState>(
  (state) => state.history.expiredOffers
);

export const selectExpiredOffers = (state: RootState): ExpiredOffer[] =>
  expiredOffersSelectors.selectAll(state);
