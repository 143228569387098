import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { profileApi } from '../../app/services';
import { RootState } from '../../app/store';
import Consumer from '../../features/Profile/types/Consumer';

export type ConsumerState = Consumer;

const initialState: ConsumerState = {
  firstName: '',
  lastName: '',
  street: '',
  city: '',
  state: '',
  zipCode: '',
};

// See: https://redux-toolkit.js.org/usage/immer-reducers#mutating-and-returning-state
// See: https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#writing-slices
// When setting base state, you will have to still return a copied version of state
// When modifying properties of state, you can mutate them directly

const reducers = {
  setConsumer: (state: ConsumerState, action: PayloadAction<Consumer>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const consumerSlice = createSlice({
  name: 'consumer',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.getConsumerProfile.matchFulfilled,
      reducers.setConsumer
    );
  },
});

export default consumerSlice.reducer;

// Actions
export const { setConsumer } = consumerSlice.actions;

// Selectors
export const selectConsumer = (state: RootState): Consumer => state.profile.consumer;
