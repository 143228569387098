import { useMediaQuery } from 'react-responsive';
import { Modal } from '../../../components/Modal';
import { Tooltip } from '../../../components/Tooltip';
import { MEDIA_QUERIES } from '../../../constants';
import { useToggle } from '../../../hooks';
import './CreditCardDisclosures.scss';

const compensationDisclosureTitle = 'How Does LendingTree Get Paid?';
const compensationDisclosureText =
  'LendingTree is compensated by companies on this site and this compensation may impact how and where offers appear on this site (such as the order). LendingTree does not include all lenders, savings products, or loan options available in the marketplace.';

const advertisingDisclosureTitle = 'Advertising Disclosure';
const advertisingDisclosureText =
  'LendingTree is an advertising-supported comparison service. The site features products from our partners as well as institutions which are not advertising partners. While we make an effort to include the best deals available to the general public, we make no warranty that such information represents all available products. Compensation may impact how and where products appear on this site (including, for example, the order in which they appear).';

const CreditCardDisclosures = (): JSX.Element => {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });

  const [showCompensationDisclosureModal, setShowCompensationDisclosureModal] = useToggle();

  const [showAdvertisingDisclosureModal, setShowAdvertisingDisclosureModal] = useToggle();

  return (
    <div className="credit-card-disclosures">
      {isSmallDown ? (
        <>
          <span
            className="credit-card-disclosures__link"
            onClick={() => setShowCompensationDisclosureModal()}
          >
            {compensationDisclosureTitle}
          </span>{' '}
          |{' '}
          <span
            className="credit-card-disclosures__link"
            onClick={() => setShowAdvertisingDisclosureModal()}
          >
            {advertisingDisclosureTitle}
          </span>
          <Modal
            show={showCompensationDisclosureModal}
            title={compensationDisclosureTitle}
            onClose={() => setShowCompensationDisclosureModal()}
          >
            {compensationDisclosureText}
          </Modal>
          <Modal
            show={showAdvertisingDisclosureModal}
            title={advertisingDisclosureTitle}
            onClose={() => setShowAdvertisingDisclosureModal()}
          >
            {advertisingDisclosureText}
          </Modal>
        </>
      ) : (
        <>
          <Tooltip content={compensationDisclosureText} placement={'bottom'}>
            <span className="credit-card-disclosures__link">{compensationDisclosureTitle}</span>
          </Tooltip>{' '}
          |{' '}
          <Tooltip content={advertisingDisclosureText} placement={'bottom'}>
            <span className="credit-card-disclosures__link">{advertisingDisclosureTitle}</span>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default CreditCardDisclosures;
