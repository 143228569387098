import { useAppSelector } from '../../app/hooks';
import { useGetUserAgentQuery } from '../../app/services/dashboard';
import { Container } from '../../components/Container';
import { FlexColumn, FlexRow } from '../../components/Flex';
import PageTitle from './PageTitle';
import Photo from './SalesRepInfo/Photo';
import ProfileInformation from './SalesRepInfo/ProfileInformation';
import './UserAgent.scss';
import { selectUserAgent } from './userAgentSlice';

interface UserAgentProps {
  displayUserAgent: boolean;
  pageTitle: string;
  subHeader: string;
}

const UserAgent = ({
  displayUserAgent = true,
  pageTitle,
  subHeader,
}: UserAgentProps): JSX.Element => {
  const { isFetching: isUserAgentFetching } = useGetUserAgentQuery(undefined, {
    skip: !displayUserAgent,
  });
  const userAgent = useAppSelector(selectUserAgent);
  return (
    <>
      {!isUserAgentFetching && (
        <Container>
          <FlexRow className="user-agent">
            <FlexColumn columnsLarge={8} columnsMedium={7} columnsSmall={12}>
              <PageTitle pageTitle={pageTitle} subHeader={subHeader} />
            </FlexColumn>
            <FlexColumn
              className="user-agent__content"
              columnsLarge={4}
              columnsMedium={5}
              columnsSmall={12}
            >
              <div className="user-agent__content--details">
                {displayUserAgent && (
                  <>
                    <ProfileInformation
                      calendlyC={userAgent?.calendlyC}
                      email={userAgent?.email}
                      name={userAgent?.name}
                      phone={userAgent?.phone}
                      photo={userAgent?.profilePhotoAsBase64String}
                    />
                    <Photo profilePhotoAsBase64String={userAgent?.profilePhotoAsBase64String} />
                  </>
                )}
              </div>
            </FlexColumn>
          </FlexRow>
        </Container>
      )}
    </>
  );
};

export default UserAgent;
