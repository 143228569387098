import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

type TooltipProps = TippyProps;

const Tooltip = ({ children, ...otherProps }: TooltipProps): JSX.Element => (
  <Tippy {...otherProps}>{children}</Tippy>
);

export default Tooltip;
