import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal } from '../../../components/Modal';
import { MEDIA_QUERIES } from '../../../constants';
import { useLTAnalytics, useToggle } from '../../../hooks';
import CallOrTextModal from './CallOrTextModal';
import './ProfileInformation.scss';

interface ProfileInformationProps {
  calendlyC?: string;
  email?: string;
  name?: string;
  phone?: string;
  photo?: string;
}

const ProfileInformation = ({
  calendlyC,
  email,
  name,
  phone,
  photo,
}: ProfileInformationProps): JSX.Element => {
  const analyticsPhone = useLTAnalytics('Phone');
  const analyticsEmail = useLTAnalytics('Email');
  const analyticsScheduleACall = useLTAnalytics('Schedule a Call');

  const [showScheduleACallModal, setScheduleACallModal] = useToggle();
  const [showCallOrTextModal, setShowCallOrTextModal] = useState(false);

  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });

  return (
    <div className="profile-information">
      <span className="profile-information__company-name">YOUR CONCIERGE</span>
      <br />
      <span className="profile-information__user-name">{name}</span>
      <br />
      <span
        className="profile-information__phone"
        onClick={() => setShowCallOrTextModal(true)}
        {...analyticsPhone}
      >
        {phone}
      </span>
      <CallOrTextModal
        name={name}
        phone={phone}
        photo={photo}
        show={showCallOrTextModal}
        onClickSetShowModal={(showModal) => setShowCallOrTextModal(showModal)}
      />
      <br />
      <span className="profile-information__span">
        <a className="profile-information__user-email" href={`mailto:${email}`} {...analyticsEmail}>
          Email
        </a>
        {calendlyC && (
          <>
            |
            {!isMediumDown && (
              <>
                <a
                  className="profile-information__schedule-a-call"
                  href="#"
                  onClick={() => setScheduleACallModal()}
                  {...analyticsScheduleACall}
                >
                  Schedule a Call
                </a>

                <Modal
                  center={true}
                  centerContent={true}
                  show={showScheduleACallModal}
                  size="x-large"
                  onClose={() => setScheduleACallModal()}
                >
                  <iframe
                    className="profile-information__Schedule-A-Call--Modal"
                    src={calendlyC}
                  ></iframe>
                </Modal>
              </>
            )}
            {isMediumDown && (
              <a
                className="profile-information__schedule-a-call"
                href={calendlyC}
                rel="noopener noreferrer"
                target="_blank"
                {...analyticsScheduleACall}
              >
                Schedule a Call
              </a>
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default ProfileInformation;
