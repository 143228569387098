import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../../../components/Button';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { InputGroup, InputLabel, StateSelectInput, TextInput } from '../../../components/Input';
import { ProgressBar } from '../../../components/ProgressBar';
import Contact from '../../../features/Profile/types/Contact';
import { useLTAnalytics } from '../../../hooks/useLTAnalytics';
import './PersonalSignupForm.scss';

interface PersonalSignupStepProps {
  stepTitle: string;
  signUpText: string;
  progressBarText: string;
  progressBarWidth: number;
  contact: Contact;
  onSubmit?: (contact: Contact) => void;
  onStepLoad: () => void;
}
interface PersonalInformationStepInput {
  firstName: string;
  lastName: string;
  street: string;
  unitApt: string;
  city: string;
  postalCode: string;
  stateCode: string;
}

const schema = yup.object().shape({
  firstName: yup
    .string()
    .label('Legal First Name')
    .required('Please enter legal first name.')
    .matches(/^[a-zA-Z\n\r\t\f]+$/i, '${path} must be valid')
    .max(255),
  lastName: yup
    .string()
    .label('Legal Last Name')
    .required('Please enter legal last name.')
    .matches(/^[a-zA-Z\n\r\t\f]+$/i, '${path} must be valid')
    .max(255),
  street: yup
    .string()
    .label('Home Street Address')
    .required('Please enter home street address.')
    .matches(/^([a-zA-Z\w]+[a-zA-Z0-9,#-.\r\n ])+$/i, '${path} must be valid')
    .max(255),
  unitApt: yup
    .string()
    .label('Unit/Apt #')
    .max(255)
    .nullable()
    .matches(/^[a-zA-Z0-9]*([\s]{1}[a-zA-Z0-9]+)*$/i, '${path} must be valid'),
  city: yup
    .string()
    .label('City')
    .required('Please enter city.')
    .matches(/^([a-zA-Z\w]+[a-zA-Z\n\r ])+$/i, '${path} must be valid')
    .max(40),
  postalCode: yup
    .string()
    .label('Zip Code')
    .required('Please enter zip code.')
    .length(5)
    .matches(/^[0-9]+$/i, '${path} must be numeric'),
  stateCode: yup.string().label('State').required('Please select state.').nullable(),
});

const PersonalSignupForm = ({
  stepTitle,
  signUpText,
  progressBarText,
  progressBarWidth,
  contact,
  onSubmit = () => undefined,
  onStepLoad = () => undefined,
}: PersonalSignupStepProps): JSX.Element => {
  const analyticsSignup = useLTAnalytics('Next Step-PersonalInformation');
  const location = useLocation();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<PersonalInformationStepInput>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const stateDefaultValueProp = () => {
    if (contact?.stateCode) {
      return {
        defaultValue: {
          value: contact?.stateCode,
          label: contact?.stateCode,
        },
      };
    }

    return {};
  };

  const handleFormSubmit = handleSubmit(
    ({
      firstName,
      lastName,
      street,
      city,
      stateCode,
      postalCode,
    }: PersonalInformationStepInput) => {
      const contact: Contact = {
        firstName,
        lastName,
        street,
        city,
        postalCode,
        stateCode,
        emailAddress: location.state,
      };
      onSubmit(contact);
    }
  );

  useEffect(() => {
    if (Object.keys(contact).length > 0 && Object.keys(errors).length === 0) {
      setValue('firstName', contact?.firstName ?? '');
      setValue('lastName', contact?.lastName ?? '');
      setValue('street', contact?.street ?? '');
      setValue('city', contact?.city ?? '');
      setValue('postalCode', contact?.postalCode ?? '');
      setValue('stateCode', contact?.stateCode ?? '');
    }
  }, [contact, errors, setValue]);

  useEffect(() => {
    onStepLoad();
  }, [onStepLoad]);
  return (
    <>
      <div className="progress-block">
        <div className="progress-block__text">{progressBarText}</div>
        <ProgressBar percent={progressBarWidth} />
      </div>
      <div className="signup__title">{stepTitle}</div>
      <h1 className="signup__header">{signUpText}</h1>
      <div className="personal-signup-form">
        <form onSubmit={handleFormSubmit}>
          <FlexRow>
            <FlexColumn columnsMedium={6}>
              <InputGroup error={errors.firstName?.message}>
                <InputLabel htmlFor="firstName" size={'large'}>
                  Legal First Name
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="firstName"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'firstName'}
                      placeholder={'Legal First Name'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={6}>
              <InputGroup error={errors.lastName?.message}>
                <InputLabel htmlFor="lastName" size={'large'}>
                  Legal Last Name
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="lastName"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'lastName'}
                      placeholder={'Legal Last Name'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={9}>
              <InputGroup error={errors.street?.message}>
                <InputLabel htmlFor="street" size={'large'}>
                  Home Street Address
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="street"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'street'}
                      placeholder={'Home Street Address'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={3}>
              <InputGroup error={errors.unitApt?.message}>
                <InputLabel htmlFor="unitApt" size={'large'}>
                  Unit/Apt #
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="unitApt"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'unitApt'}
                      placeholder={'Unit/Apt #'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.city?.message}>
                <InputLabel htmlFor="city" size={'large'}>
                  City
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="city"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <TextInput
                      {...fieldProps}
                      ref={ref}
                      heightSize={'large'}
                      name={'city'}
                      placeholder={'City'}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={5}>
              <InputGroup error={errors.postalCode?.message}>
                <InputLabel htmlFor="postalCode" size={'large'}>
                  Zip Code
                </InputLabel>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="postalCode"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <NumberFormat
                      {...fieldProps}
                      ref={ref}
                      className="text-input text-input--large"
                      format="#####"
                      placeholder="Zip Code"
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
            <FlexColumn columnsMedium={2}>
              <InputGroup error={errors.stateCode?.message}>
                <InputLabel htmlFor="stateCode" size={'large'}>
                  State
                </InputLabel>
                <Controller
                  control={control}
                  name="stateCode"
                  render={({ field: { onChange, ref } }) => (
                    <StateSelectInput
                      ref={ref}
                      size={'large'}
                      onChange={onChange}
                      {...stateDefaultValueProp()}
                    />
                  )}
                />
              </InputGroup>
            </FlexColumn>
          </FlexRow>
          <Button
            block={true}
            className="personal-information-step-form__next"
            color={'blue'}
            size={'large'}
            {...analyticsSignup}
          >
            Next Step
          </Button>
        </form>
      </div>
    </>
  );
};

export default PersonalSignupForm;
