import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateLoginStatisticsMutation } from '../../app/services/dashboard';
import { Loader } from '../../components/Loader';
import './LoginCallback.scss';
import { useAuth } from './useAuth';

const LoginCallback = (): JSX.Element => {
  const auth = useAuth();
  const { getLoginRedirectUri, loginCallback, removeLoginRedirectUri } = auth;
  const navigate = useNavigate();
  const [updateLoginStatistics] = useUpdateLoginStatisticsMutation();
  useEffect(() => {
    loginCallback()
      .then(() => {
        const redirectUri = getLoginRedirectUri() || '/home';
        updateLoginStatistics();
        removeLoginRedirectUri();
        navigate(redirectUri);
      })
      .catch(() => {
        // TODO:
        // Log error, e.g. to New Relic
        navigate('/');
      });
  }, [getLoginRedirectUri, loginCallback, removeLoginRedirectUri, navigate, updateLoginStatistics]);

  return (
    <div className="login-callback">
      <h2>Logging In...</h2>
      <Loader centered={true} />
    </div>
  );
};

export default LoginCallback;
