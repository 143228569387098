import './PageTitle.scss';

interface PageTitleProps {
  pageTitle?: string;
  subHeader?: string;
}

const PageTitle = ({ pageTitle, subHeader }: PageTitleProps): JSX.Element => {
  return (
    <>
      <h1>{pageTitle ?? ''}</h1>
      {subHeader && <p className="page-title__sub-header">{subHeader}</p>}
    </>
  );
};

export default PageTitle;
