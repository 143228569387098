import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { profileApi } from '../../app/services';
import { RootState } from '../../app/store';
import Account from './types/Account';

export type AccountState = Account;

const initialState: AccountState = {
  id: '',
  name: '',
  address: {
    city: '',
    state: '',
    stateCode: '',
    street: '',
    postalCode: '',
    street2: '',
  },
  phone: '',
  taxId: '',
};

const reducers = {
  setAccount: (state: AccountState, action: PayloadAction<Account>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getAccount.matchFulfilled, reducers.setAccount);
  },
});

export default accountSlice.reducer;

// Actions
export const { setAccount } = accountSlice.actions;

// Selectors
export const selectAccount = (state: RootState): Account => state.profile.account;
