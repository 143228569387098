import { HTMLAttributes } from 'react';

const DocumentsTableRow: React.FC<HTMLAttributes<HTMLTableRowElement>> = ({
  children,
  ...props
}) => {
  return (
    <tr {...props} className="upload-documents-table__tbody-tr">
      {children}
    </tr>
  );
};

const DocumentsTableCell: React.FC<HTMLAttributes<HTMLTableCellElement>> = ({
  children,
  ...props
}) => {
  return (
    <td {...props} className="upload-documents-table__td">
      {children}
    </td>
  );
};

export interface DocumentsTableProps {
  columns: string[];
  children: (Row: typeof DocumentsTableRow, Cell: typeof DocumentsTableCell) => React.ReactNode;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({ columns, children }) => {
  return (
    <div className="upload-documents-wrapper">
      <table className="upload-documents-table">
        <thead className="upload-documents-table__thead">
          <tr className="upload-documents-table__thead-tr">
            {columns.map((column, i) => (
              <th key={`${i}-${column}`} className="upload-documents-table__thead-th">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="upload-documents-table__tbody">
          {children(DocumentsTableRow, DocumentsTableCell)}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentsTable;
