const convertLoanTermToString = ({
  term,
  shortMonth,
}: {
  term?: number;
  shortMonth?: boolean;
}): string => {
  if (term) {
    return shortMonth ? `${term} Mo.` : `${term} Months`;
  }

  return 'Unknown';
};

export default convertLoanTermToString;
