import LtIcon from '../../../components/Icon/LtIcon';
import { useHandleFundingCTA, useLTAnalytics } from '../../../hooks';
import InCompleteApplicationModal from '../InCompleteApplicationModal';
import OpenOpportunityModal from '../OpenOpportunityModal/OpenOpportunityModal';
import './AdditionalFunding.scss';

const AdditionalFunding = (): JSX.Element => {
  const analytics = useLTAnalytics('Request Additional Funding');
  const [
    check,
    handleInCompleteApplicationModalClose,
    showInCompleteApplicationModal,
    setShowOpenOpportunityModal,
    showOpenOpportunityModal,
  ] = useHandleFundingCTA();

  return (
    <div className="additional-funding">
      <OpenOpportunityModal
        show={showOpenOpportunityModal}
        onClose={() => setShowOpenOpportunityModal(false)}
      />
      <div className="additional-funding__inner-box" onClick={check} {...analytics}>
        {showInCompleteApplicationModal && (
          <InCompleteApplicationModal
            show={showInCompleteApplicationModal}
            onClose={handleInCompleteApplicationModalClose}
          ></InCompleteApplicationModal>
        )}
        <LtIcon className="additional-funding__plus-icon" name="Plus" />
        <span className="additional-funding__link-text">Request Additional Funding</span>
      </div>
    </div>
  );
};

export default AdditionalFunding;
