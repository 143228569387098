import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '../../../components/Container';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import './ExistingTreeAuthAccount.scss';

export default function ExistingTreeAuthAccount(): JSX.Element {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown === 1) {
        navigate('/home');

        clearTimeout(timer);
      } else {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [countdown, navigate]);

  return (
    <Container>
      <div className="existing-acct">
        <FlexRow className="existing-acct__box">
          <FlexColumn columns={12}>
            <div className="existing-acct__container">
              <h3 className="existing-acct__title">Good News!</h3>
              <div className="existing-acct__copy">
                Looks like you already have a LendingTree account!
              </div>
              <div className="existing-acct__copy">
                We&apos;ll automatically redirect you to the sign-in page in {countdown} second(s)
                to complete the sign-up process, or <Link to="/home">you can go there now</Link>.
              </div>
            </div>
          </FlexColumn>
        </FlexRow>
      </div>
    </Container>
  );
}
