import Account from '~/features/Profile/types/Account';
import Consumer from '~/features/Profile/types/Consumer';
import Contact from '~/features/Profile/types/Contact';
import ProfileData from '~/features/Profile/types/ProfileData';
import { SignupResponse } from '~/features/Signup/types/SignupResponse';
import { borrowerPortalApi } from './borrowerPortalBase';

export const profileApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccount: builder.query<Account, void>({
      query: () => 'profile/account',
    }),
    getContact: builder.query<Contact, void>({
      query: () => 'profile/contact',
    }),
    getConsumerProfile: builder.query<Consumer, void>({
      query: () => 'profile/consumer',
    }),
    getBusinessIndustries: builder.query<string[], void>({
      query: () => 'profile/businessIndustries',
    }),
    getBusinessTypes: builder.query<string[], void>({
      query: () => 'profile/businessTypes',
    }),
    createAccount: builder.mutation<SignupResponse, Partial<Account>>({
      query: (body) => ({
        url: 'signup/business-info',
        method: 'POST',
        body,
      }),
    }),
    updateProfile: builder.mutation<boolean, ProfileData>({
      query: (body) => ({
        url: 'profile/update-profile',
        method: 'POST',
        body,
      }),
    }),
    hasBorrowerPortalAccount: builder.query<boolean, void>({
      query: () => 'profile/has-borrower-portal-account',
    }),
    hasAccountInfo: builder.query<boolean, void>({
      query: () => 'profile/has-account-info',
    }),
    hasTreeAuthAccount: builder.mutation<boolean, Partial<Contact>>({
      query: (body) => ({
        url: 'profile/has-treeauth-account',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAccountQuery,
  useGetContactQuery,
  useGetConsumerProfileQuery,
  useGetBusinessIndustriesQuery,
  useGetBusinessTypesQuery,
  useCreateAccountMutation,
  useUpdateProfileMutation,
  useHasBorrowerPortalAccountQuery,
  useHasAccountInfoQuery,
  useHasTreeAuthAccountMutation,
} = profileApi;
