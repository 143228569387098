import classNames from 'classnames';
import { HTMLAttributes, memo, MouseEvent, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

type ModalSize = 'small' | 'medium' | 'large' | 'x-large';

export enum Mode {
  // DEFAULT: Can be closed by clicking the backdrop
  Dialog = 0,
  // Cannot be closed by clicking on the backdrop
  Modal,
}

export interface ModalProps {
  center?: boolean;
  centerContent?: boolean;
  children?: ReactNode;
  closable?: boolean;
  isDisabled?: boolean;
  onClose: () => void;
  show: boolean;
  size?: ModalSize;
  title?: string;
  closeButtonAttrs?: HTMLAttributes<HTMLSpanElement>;
  mode?: Mode;
  className?: string;
}

const Modal = ({
  center,
  centerContent,
  children,
  closable = true,
  isDisabled = false,
  onClose,
  show,
  size = 'medium',
  title,
  closeButtonAttrs = {},
  mode = Mode.Dialog,
  className,
}: ModalProps): JSX.Element | null => {
  const handleOnClick = (e: MouseEvent): void => {
    // do not close modal if anything inside modal content is clicked
    e.stopPropagation();
  };

  const handleBackdropClick = (e: MouseEvent) => {
    if (isDisabled) {
      e.stopPropagation();
    } else if (mode === Mode.Dialog) {
      onClose();
    }
  };

  const container = useMemo(() => document.getElementById('modal-container') as HTMLElement, []);

  return show
    ? createPortal(
        <div
          className={classNames(
            className,
            'modal__wrapper',
            { 'modal__wrapper--show': show },
            { 'modal__wrapper--center': center }
          )}
          onClick={handleBackdropClick}
        >
          <div
            className={classNames(
              'modal__content',
              `modal__content--${size}`,
              {
                'modal__content--center': centerContent,
              },
              isDisabled ? 'pointer__events' : ''
            )}
            onClick={handleOnClick}
          >
            {closable && !isDisabled && (
              <span
                {...closeButtonAttrs}
                className="modal__close"
                onClick={isDisabled ? handleOnClick : onClose}
              >
                &times;
              </span>
            )}
            {title && <div className={'modal__title'}>{title}</div>}
            <div className={classNames('modal__body', isDisabled ? 'pointer__events' : '')}>
              {children}
            </div>
          </div>
        </div>,
        container
      )
    : null;
};

export default memo(Modal);
