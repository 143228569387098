// Infer array as a Union type
export type IconNames = typeof iconNames[number];

const iconNames = [
  'Account',
  'AirlineMiles',
  'Apple',
  'arrow2',
  'ArrowDown',
  'ArrowDownCircle',
  'ArrowUp',
  'ArrowUpCircle',
  'ATV',
  'AutoAffordability',
  'AutoBuyVsLease',
  'AutoRebateVsLowAPR',
  'AutoRefi',
  'Autos',
  'BalanceScale',
  'BalanceTransfer',
  'Balloons',
  'Bank',
  'Basketball',
  'BayArea',
  'BBBLogo',
  'Beer',
  'Binoculars',
  'Boat',
  'Bookmark',
  'Briefcase',
  'BusinessLoans',
  'Calculator',
  'Calendar',
  'Camera',
  'Care',
  'Cash',
  'CashRegister',
  'Catering',
  'Certified',
  'Charleston',
  'Charlotte',
  'Chart',
  'ChartDown',
  'ChartUp',
  'Check',
  'Check1',
  'CheckBook',
  'CheckeredFlags',
  'Checklist',
  'ChevronDown',
  'ChevronLeft',
  'ChevronRight',
  'ChevronThickDown',
  'ChevronThickLeft',
  'ChevronThickRight',
  'ChevronThickUp',
  'ChevronUp',
  'Chicago',
  'City',
  'CityHomes',
  'Clock',
  'Cloud-Security',
  'Coin',
  'ComodoLogo',
  'Computer',
  'Consolidate',
  'credit-card-single',
  'CreditCards',
  'CreditRepair',
  'CreditScore',
  'Dancing',
  'Dark-Web-Monitoring',
  'Debt',
  'DebtRelief',
  'Degree',
  'Denver',
  'Document',
  'DocumentCertified',
  'DocumentCheck',
  'Dog',
  'DollarSign',
  'donut-chart-icon',
  'Eagle',
  'EHOLogo',
  'Envelope',
  'EnvelopeMoney',
  'Ex',
  'Facebook',
  'FaceFlat',
  'FaceHappy',
  'FaceOk',
  'FaceSad',
  'Family',
  'FHALoans',
  'Filters',
  'flipflop',
  'Football',
  'FuelPump',
  'FullCoverage',
  'Funnel',
  'Globe',
  'GooglePlus',
  'graphup',
  'Guide',
  'Handshake',
  'Harp',
  'Headset',
  'Help',
  'Hiker',
  'HomeAffordability',
  'HomeCondo',
  'HomeEquity',
  'HomeImprovement',
  'HomeLocjk',
  'HomeMobile',
  'HomeMultiFamily',
  'HomeTownhome',
  'Hotel',
  'House',
  'HouseArrow',
  'HouseBlueprint',
  'HouseDouble',
  'HouseStar',
  'HouseTagMoney',
  'HouseTagPercent',
  'ID-Theft',
  'Info-Icon',
  'Instagram',
  'Insurance',
  'InsuranceAuto',
  'InsuranceHome',
  'Jumper',
  'Key',
  'Leaf',
  'leaf2',
  'Lightbulb',
  'Linkedin',
  'LoanExplorer',
  'Lock',
  'Logout',
  'MailBox',
  'MapPin',
  'Match',
  'Medal',
  'Medical',
  'Megaphone',
  'Menu-Bars',
  'Menu',
  'Minus',
  'MinusCircle',
  'MobilePhone',
  'MoneyCircle',
  'MoneyMarket',
  'MoneyWallet',
  'MoreCards',
  'Mortgage',
  'MortgageNegotiator',
  'Motorcycle',
  'MotorcycleHelmet',
  'MyLT-Academy',
  'MyLT-Accounts',
  'MyLT-Alerts',
  'MyLT-Dashboard',
  'MyLT-Line-Graph',
  'MyLT-Link',
  'MyLT-Products',
  'MyLT-Unlink',
  'NestEgg',
  'NetworkBranch',
  'NoCard',
  'NoFee',
  'NoSign',
  'OpenBox',
  'Pallette',
  'PawPrint',
  'Pen',
  'Percent',
  'PercentDown',
  'PercentUp',
  'PersonalLoans',
  'Phone',
  'PicketFence',
  'PieChart',
  'PiggyBank',
  'PillarBuilding',
  'Pine',
  'Pinterest',
  'PlayButton',
  'Plus',
  'PlusCircle',
  'PrepaidCard',
  'print-icon',
  'Profile',
  'ProfileGroup',
  'Question-font',
  'Question',
  'QuestionBubble',
  'QuestionOutline',
  'RatingReviews',
  'Refinance',
  'Refresh',
  'RentalSign',
  'RentVsBuyCalc',
  'ReverseMortgage',
  'Ribbon',
  'RibbonCertified',
  'Rocket',
  'Runner',
  'RV',
  'Sacramento',
  'Safe',
  'SailBoat',
  'Sax',
  'Search',
  'Seattle',
  'SecondHome',
  'Security-Breach',
  'Security',
  'Settings',
  'shirt',
  'shop',
  'ShoppingBag',
  'ShoppingCart',
  'SideBySide',
  'Skier',
  'Snowmobile',
  'SpeakBubbles',
  'Star',
  'StarHalf',
  'SteeringWheel',
  'StopWatch',
  'Student',
  'StudentRefi',
  'Suitcase',
  'Sun',
  'Target',
  'Thermometer',
  'ThumbsDown',
  'ThumbsUp',
  'Transit',
  'Trophy',
  'Twitter',
  'Umbrella',
  'UTV',
  'Vacation',
  'VALoans',
  'Vine',
  'Wallet',
  'Warning',
  'Watercraft',
  'Wedding',
  'weight',
  'WhaleTale',
  'WineGlass',
  'X-outline',
  'Youtube',
  'ZeroPercent',
] as const;
