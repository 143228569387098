import { FlexColumn, FlexRow } from '../../components/Flex';
import { useLTAnalytics } from '../../hooks';
import './CompleteProfile.scss';
import CoffeeShopImage from './images/bouquets-business-coffee-shop.png';

const CompleteProfile = (): JSX.Element => {
  const analytics = useLTAnalytics('Complete Your Profile');

  return (
    <div className="complete-profile">
      <FlexRow className="complete-profile__creditscore-box">
        <FlexColumn columns={12}>
          <div className="complete-profile__left-side">
            <h1 className="complete-profile__creditscore-title">
              What&#39;s your business credit score?
            </h1>
            <div className="complete-profile__creditscore-subtitle">See yours, free!</div>
            <p className="complete-profile__business-text">
              Your business credit score is a great way to measure how likely you are to get
              approved for a business loan. It is not the same as your personal credit score.
            </p>
            <p className="complete-profile__business-text">
              Complete your business profile to see and track your business credit score, and learn
              how to improve it—completely free!
            </p>
            <a
              className="complete-profile__action-cta"
              href="/app/profile"
              rel="noopener noreferrer"
              {...analytics}
            >
              Complete Your Profile
            </a>
          </div>
          <div className="complete-profile__right-imgblock">
            <img alt="bouquets-business-coffee-shop" src={CoffeeShopImage} />
          </div>
        </FlexColumn>
      </FlexRow>
    </div>
  );
};

export default CompleteProfile;
