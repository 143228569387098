const convertStringToCurrency = (amount?: string): string => {
  if (amount) {
    try {
      const amountAsInt = parseInt(amount);
      const dollarUSLocale = Intl.NumberFormat('en-US');
      return `$${dollarUSLocale.format(amountAsInt)}`;
    } catch (error) {
      // catch error if conversion fails
    }
  }

  return 'Unknown';
};

export default convertStringToCurrency;
