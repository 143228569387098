import round from 'lodash/round';
import { CREDIT_BANDS } from '../../../../constants';
import { calculatePercentInRange } from '../../../../utils';

/**
 * Given a score within a credit range (300-850), calculate its distance as a percentage between the min and max of the range
 *
 * The distance percentage must first be calculated within the credit band in which it falls (e.g. fair: 580-669)
 * Given the above percentage, divide that by 5 (multiply by 20%) since each credit band takes up 1/5 or 20% of the total width
 * Finally, offset the percentage by the number of credit bands * 20% that come before it
 *
 * @param score - the credit score to calculate distance between the credit range
 *
 * @return {number} - as a decimal percentage value, e.g. 0.2 - 20%
 */
const calculatePercentInCreditRange = (score: number): number => {
  const { personal } = CREDIT_BANDS;
  const bandPercent = 0.2;

  if (!score) return 0;

  let adjustedScore = score;
  if (score < personal.poor.min) adjustedScore = personal.poor.min;
  if (score > personal.excellent.max) adjustedScore = personal.excellent.max;

  const { min = 0, max = 0, percentOffset = 0 } =
    Object.values(personal)
      .map((band, index) => ({ ...band, percentOffset: bandPercent * index }))
      .find((band) => adjustedScore >= band.min && adjustedScore <= band.max) ?? {};

  return round(calculatePercentInRange(adjustedScore, min, max) * bandPercent + percentOffset, 4);
};

export default calculatePercentInCreditRange;
