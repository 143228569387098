export default function CircularArrows(): JSX.Element {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24">
      <circle cx="12" cy="12" fill="currentColor" r="11" stroke="white" strokeWidth="2" />
      <path
        d="M11.9091 7.09091V9.54545L8.63639 6.27273L11.9091 3V5.45455C15.5255 5.45455 18.4546 8.38364 18.4546 12C18.4546 13.2845 18.0782 14.4791 17.44 15.4855L16.2455 14.2909C16.6241 13.5868 16.8209 12.7994 16.8182 12C16.8182 9.29182 14.6173 7.09091 11.9091 7.09091ZM6.37821 8.51455L7.57276 9.70909C7.21276 10.3964 7.00003 11.1736 7.00003 12C7.00003 14.7082 9.20094 16.9091 11.9091 16.9091V14.4545L15.1818 17.7273L11.9091 21V18.5455C8.29276 18.5455 5.36366 15.6164 5.36366 12C5.36366 10.7155 5.74003 9.52091 6.37821 8.51455V8.51455Z"
        fill="white"
      />
    </svg>
  );
}
