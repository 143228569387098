import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectSnapshot } from '../../slices/snapshotSlice';

const MyLtEntryPage = (): null => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const snapshot = useAppSelector(selectSnapshot);

  useEffect(() => {
    const params = searchParams.toString();

    if (snapshot.hasBorrowerPortalAccount) {
      let redirectUrl = snapshot.splitterUrl;

      if (params != '') {
        redirectUrl += `&${params}`;
      }

      window.location.href = redirectUrl;
    } else {
      const url = `/existing-treeauth?${params}`;
      navigate(url);
    }
  });

  return null;
};

export default MyLtEntryPage;
