import { forwardRef } from 'react';
import StateManager, { GroupTypeBase } from 'react-select';
import Select from 'react-select/base';
import SelectInput, { SelectInputProps } from './SelectInput';
import SelectOption from './types/SelectOption';

const monthOptions: SelectOption[] = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

type MonthSelectInputProps = Omit<SelectInputProps, 'options'>;

const MonthSelectInput = forwardRef<
  StateManager<
    SelectOption,
    false,
    GroupTypeBase<SelectOption>,
    Select<SelectOption, false, GroupTypeBase<SelectOption>>
  >,
  MonthSelectInputProps
>(
  ({ defaultValue, ...otherProps }: MonthSelectInputProps, ref): JSX.Element => {
    const value = defaultValue ? monthOptions.find((x) => x.value == defaultValue) : '';
    return <SelectInput ref={ref} defaultValue={value} options={monthOptions} {...otherProps} />;
  }
);

MonthSelectInput.displayName = 'MonthSelectInput';

export default MonthSelectInput;
