import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { featuresFlagsApi } from '../app/services/featuresFlags';
import { RootState } from '../app/store';
import { FeatureFlag, FeatureFlagNames } from '../components/FeatureFlag';

const featureFlagsAdapter = createEntityAdapter<FeatureFlag>({
  selectId: (featureFlag) => featureFlag.name,
});
export type FeatureFlagState = EntityState<FeatureFlag>;

const reducers = {
  setFeatureFlags: (state: FeatureFlagState, action: PayloadAction<FeatureFlag[]>) => {
    featureFlagsAdapter.setAll(state, action.payload);
  },
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: featureFlagsAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      featuresFlagsApi.endpoints.getFeatureFlags.matchFulfilled,
      reducers.setFeatureFlags
    );
  },
});

export default featureFlagsSlice.reducer;

// Actions
export const { setFeatureFlags } = featureFlagsSlice.actions;

// Selectors
const featureFlagsSelectors = featureFlagsAdapter.getSelectors<RootState>(
  (state) => state.app.featureFlags
);

export const selectFeatureFlags = (state: RootState): FeatureFlag[] =>
  featureFlagsSelectors.selectAll(state);

export const selectFeatureByName = createSelector(selectFeatureFlags, (featureFlags) =>
  memoize((name: FeatureFlagNames) =>
    featureFlags.find((featureFlag) => featureFlag.name.toUpperCase() === name.toUpperCase())
  )
);
