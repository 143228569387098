import { useMediaQuery } from 'react-responsive';
import { FlexColumn, FlexRow } from '../../../../components/Flex';
import { MEDIA_QUERIES } from '../../../../constants';
import { convertStringToCurrency, dateFormat } from '../../../../utils';
import LoanFunded from '../LoanFunded/LoanFunded';
import RequestNotFound from '../RequestNotFound/RequestNotFound';
import Tracker from '../Tracker/Tracker';
import FundingRequest from '../types/FundingRequest';
import './Status.scss';

interface StatusProps {
  fundingRequest: FundingRequest;
  status: string;
  hasExchangeRequests: boolean;
}

const Status = ({ fundingRequest, status, hasExchangeRequests }: StatusProps): JSX.Element => {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const statusOfRequest = fundingRequest?.status?.replace(/ /g, '').toLowerCase();
  const showRequestNotFound = (): boolean => {
    return statusOfRequest == 'requestnotfunded' ? true : false;
  };

  const showLoanFunded = (): boolean => {
    return statusOfRequest == 'loanfunded' ? true : false;
  };
  return (
    <>
      <div className={`status ${hasExchangeRequests ? 'border-top-right-radius-zero' : ''}`}>
        <Tracker status={status} />
        {showRequestNotFound() && <RequestNotFound />}
        {showLoanFunded() && <LoanFunded fundedLoan={fundingRequest?.fundedLoans[0]} />}
        {!showRequestNotFound() && !showLoanFunded() && (
          <FlexRow>
            <FlexColumn className="status__content" columns={4}>
              <div className="status__content--label-name">
                <span>Requested</span>
                {!isSmallDown && <span> Amount</span>}
              </div>
              <div className="status__content--label-val">
                {convertStringToCurrency(fundingRequest?.requestedAmount)}
              </div>
            </FlexColumn>
            <FlexColumn className="status__content" columns={4}>
              <div className="status__content--label-name">
                <span>Date</span>
                {!isSmallDown && <span> Requested</span>}
              </div>
              <div className="status__content--label-val">
                {dateFormat({ date: fundingRequest?.dateRequested, includeTime: false })}
              </div>
            </FlexColumn>
            <FlexColumn className="status__content" columns={4}>
              <div className="status__content--label-name">
                <span>Expire On</span>
              </div>
              <div className="status__content--label-val">{fundingRequest?.expiryDate}</div>
            </FlexColumn>
          </FlexRow>
        )}
      </div>
    </>
  );
};

export default Status;
