import { Helmet } from 'react-helmet-async';
import { Container } from '../../components/Container';
import './NotFound.scss';

const NotFound = (): JSX.Element => {
  return (
    <div className="not-found">
      <Helmet>
        <title>404 - Not Found</title>
      </Helmet>
      <Container>
        <h1>Well, this is embarrassing...</h1>
        <h3>Sorry, but this page doesn’t exist.</h3>
      </Container>
    </div>
  );
};

export default NotFound;
