import DeleteDocumentRequest from '../../features/Documents/types/DeleteDocumentRequest';
import DeleteDocumentResponse from '../../features/Documents/types/DeleteDocumentResponse';
import DocumentRequest from '../../features/Documents/types/DocumentRequest';
import ExchangeOffer from '../../features/Documents/types/ExchangeOffer';
import UploadDocumentResponse from '../../features/Documents/types/UploadDocumentResponse';
import { borrowerPortalApi } from './borrowerPortalBase';

export const documentsApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentRequests: builder.query<DocumentRequest[], void>({
      query: () => 'documents/requests',
    }),
    getExchangeOffers: builder.query<ExchangeOffer, void>({
      query: () => 'documents/exchange-offers',
    }),
    uploadDocument: builder.mutation<UploadDocumentResponse, FormData>({
      query: (body) => ({
        url: 'documents/upload',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_res, _err, arg) =>
        arg.has('applicationId')
          ? [{ type: 'FundingRequestData', id: arg.get('applicationId')?.toString() }]
          : ['FundingRequestData'],
    }),
    deleteDocument: builder.mutation<DeleteDocumentResponse, DeleteDocumentRequest>({
      query: (body) => ({
        url: 'documents/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_res, _err, arg) =>
        arg.applicationId
          ? [{ type: 'FundingRequestData', id: arg.applicationId }]
          : ['FundingRequestData'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDocumentRequestsQuery,
  useGetExchangeOffersQuery,
  useUploadDocumentMutation,
  useDeleteDocumentMutation,
} = documentsApi;
