import classNames from 'classnames';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { IconNames, LtIcon } from '../Icon';
import './NavbarItem.scss';

interface NavbarItemProps {
  children?: ReactNode;
  className?: string;
  icon?: IconNames;
  isActive?: boolean;
  linkClassName?: string;
  name?: string;
  onClick: () => void;
  path?: string;
}

const NavbarItem = ({
  children,
  className,
  icon,
  isActive = false,
  linkClassName,
  name,
  onClick,
  path,
  ...props
}: NavbarItemProps): JSX.Element => {
  return (
    <li className={classNames('navbar-item', className)} onClick={onClick}>
      {path ? (
        <NavLink
          className={({ isActive }) =>
            classNames('navbar-link', { 'navbar-link--active': isActive }, linkClassName)
          }
          to={path}
          {...props}
        >
          {icon && <LtIcon className="navbar-link__icon" name={icon} />}
          {name}
        </NavLink>
      ) : (
        <span
          className={classNames('navbar-link', { 'navbar-link--active': isActive }, linkClassName)}
        >
          {icon && <LtIcon className="navbar-link__icon" name={icon} />}
          {name}
        </span>
      )}
      {children}
    </li>
  );
};

export default NavbarItem;
