import classNames from 'classnames';
import { forwardRef } from 'react';
import {
  default as ReactSelect,
  default as StateManager,
  GroupTypeBase,
  Props as ReactSelectProps,
  ValueType,
} from 'react-select';
import Select from 'react-select/base';
import './SelectInput.scss';
import SelectOption from './types/SelectOption';

type SelectInputSizes = 'default' | 'large';

export interface SelectInputProps extends Omit<ReactSelectProps, 'onChange'> {
  options: SelectOption[];
  size?: SelectInputSizes;
  onChange?: (value?: string) => void;
}

const SelectInput = forwardRef<
  StateManager<
    SelectOption,
    false,
    GroupTypeBase<SelectOption>,
    Select<SelectOption, false, GroupTypeBase<SelectOption>>
  >,
  SelectInputProps
>(
  ({ options, size = 'default', onChange, ...props }: SelectInputProps, ref): JSX.Element => {
    const handleChange = (option: ValueType<SelectOption, false>) => {
      onChange?.(option?.value);
    };

    return (
      <ReactSelect
        ref={ref}
        className={classNames('select-input__container', {
          [`select-input__container--${size}`]: size !== 'default',
        })}
        classNamePrefix={'select-input'}
        options={options}
        onChange={handleChange}
        {...props}
      />
    );
  }
);

SelectInput.displayName = 'SelectInput';

export default SelectInput;
