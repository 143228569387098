import MatcheOffer from '../MatcheOffer/MatcheOffer';
import OffersCTA from '../OffersCTA/OffersCTA';
import ExchangeRequest from '../types/ExchangeRequest';
import './MatcheContent.scss';

interface MatcheContentProps {
  exchangeRequest: ExchangeRequest;
  fvoffersValue?: string;
}
const MatcheContent = ({ exchangeRequest, fvoffersValue }: MatcheContentProps): JSX.Element => {
  return (
    <div className="matche-content">
      <div className="matche-content__info">
        <MatcheOffer exchangeRequest={exchangeRequest} />
        <OffersCTA fvoffersValue={fvoffersValue} qFormId={exchangeRequest?.qFormId} />
      </div>
    </div>
  );
};

export default MatcheContent;
