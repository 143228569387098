import classNames from 'classnames';
import React, { Fragment, HTMLAttributes, ReactElement, ReactNode, useState } from 'react';
import './Tabs.scss';

/**
 * EXAMPLE
 *  <Tabs className="col-12">
 *    <Tab id="business-profile" title="Business Credit Score">
 *     <BusinessCreditScore />
 *    </Tab>
 *    <Tab id="improve-lendability" title="Improve Lendability">
 *      <ImproveLendability />
 *    </Tab>
 *  </Tabs>
 */

type TabPanelProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};

const TabPanel: React.FC<TabPanelProps> = ({ children, ...props }) => {
  return (
    <div className="tabs__panel" role="tabpanel" {...props}>
      {children}
    </div>
  );
};

export type TabProps = {
  id: string;
  title: string;
  children: ReactNode;
};

export const Tab: React.FC<TabProps> = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

export type TabsProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactElement<TabProps>[];
  tabPanel?: string;
};

export const Tabs: React.FC<TabsProps> = ({ children, ...props }) => {
  const validChildren = React.Children.toArray(children).filter((child) => {
    return React.isValidElement(child) && child.type === Tab;
  }) as ReactElement<TabProps>[];

  const tabsList = validChildren.map((child) => {
    return {
      id: child.props.id,
      title: child.props.title,
    };
  });

  const [active, setActive] = useState(() => tabsList[0].id);

  const panels = validChildren.map((child) => {
    return {
      id: child.props.id,
      children: child.props.children,
    };
  });

  return (
    <div {...props}>
      <ul className="tabs__list" role="tablist">
        {tabsList.map(({ id, title }) => (
          <li key={id} id={`${id}-tab`}>
            <button
              aria-controls={id}
              aria-selected={'tabs__item--active' === id}
              className={classNames('tabs__item', { 'tabs__item--active': active === id })}
              role="tab"
              tabIndex={active === id ? 0 : -1}
              onClick={() => void setActive(id)}
            >
              {title}
            </button>
          </li>
        ))}
      </ul>
      <div className="tabs__panels">
        {panels
          .filter(({ id }) => id === active)
          .map(({ id, children }) => (
            <TabPanel key={id} aria-labelledby={id}>
              {children}
            </TabPanel>
          ))}
      </div>
    </div>
  );
};
