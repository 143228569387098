import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { LtIcon } from '../../../components/Icon';
import { MEDIA_QUERIES } from '../../../constants';
import './Recommendation.scss';

interface RecommendationProps {
  recommendations: {
    title: string;
    content: string;
    image?: string;
    crossLink?: string;
    header?: string;
    description?: string;
  }[];
}
const Recommendation = ({ recommendations }: RecommendationProps): JSX.Element => {
  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });
  const [activeIndex, setActiveIndex] = useState(0);
  const [collapse, setCollapse] = useState(true);
  const [activeRecommendation, setActiveRecommendation] = useState(recommendations[0]);
  const handleToggle = (currentIndex: number) => {
    if (activeIndex === currentIndex) {
      setCollapse(!collapse);
    } else {
      setCollapse(true);
    }
    setActiveIndex(currentIndex);
    setActiveRecommendation(recommendations[currentIndex]);
  };

  useEffect(() => {
    setCollapse(collapse);
  }, [collapse, setCollapse]);

  return (
    <div className="recommendation">
      <FlexRow>
        <FlexColumn columns={12} columnsLarge={4}>
          <div className="recommendation__block">
            {recommendations.map((recommendation, index) => (
              <div key={index}>
                <RecommendationTitle
                  {...recommendation}
                  activeIndex={activeIndex}
                  collapse={collapse}
                  index={index}
                  onToggle={() => handleToggle(index)}
                />
                {isMediumDown && collapse == true && index === activeIndex && (
                  <RecommendationContent
                    content={activeRecommendation.content}
                    crossLink={activeRecommendation.crossLink}
                    description={activeRecommendation.description}
                    header={activeRecommendation.header}
                    image={activeRecommendation.image}
                    title={activeRecommendation.title}
                  />
                )}
              </div>
            ))}
          </div>
        </FlexColumn>
        {!isMediumDown && (
          <FlexColumn columnsLarge={8}>
            <RecommendationContent
              content={activeRecommendation.content}
              crossLink={activeRecommendation.crossLink}
              description={activeRecommendation.description}
              header={activeRecommendation.header}
              image={activeRecommendation.image}
              title={activeRecommendation.title}
            />
          </FlexColumn>
        )}
      </FlexRow>
    </div>
  );
};

interface RecommendationTitleProps {
  activeIndex: number;
  collapse: boolean;
  index: number;
  title: string;
  onToggle: () => void;
}
const RecommendationTitle = ({
  activeIndex,
  collapse,
  index,
  title,
  onToggle,
}: RecommendationTitleProps): JSX.Element => {
  const isMediumDown = useMediaQuery({ query: MEDIA_QUERIES.mediumDown });
  const toggleActive = () => {
    onToggle();
  };

  return (
    <>
      <div
        className={`recommendation-title ${
          index === activeIndex ? 'recommendation-title__active' : ''
        }`}
        onClick={toggleActive}
      >
        {isMediumDown && (
          <span
            className={`plus-minus-icons ${
              collapse == true && index === activeIndex ? 'bl-lt-icon--Minus' : 'bl-lt-icon--Plus'
            }`}
          ></span>
        )}
        <span>{title}</span>
        {index === activeIndex && !isMediumDown && <LtIcon name="ChevronThickRight" />}
      </div>
    </>
  );
};

interface RecommendationContentProps {
  title: string;
  content: string;
  image?: string;
  crossLink?: string;
  header?: string;
  description?: string;
}

const RecommendationContent = ({
  title,
  content,
  image,
  crossLink,
  header,
  description,
}: RecommendationContentProps): JSX.Element => {
  return (
    <div className="recommendation-details">
      <div className="recommendation-details__title">{title}</div>
      <div className="recommendation-details__content">{content}</div>
      {image && crossLink && (
        <div className="recommendation-details__bottom-box">
          <FlexRow>
            <FlexColumn columns={12} columnsSmall={3}>
              <a href={crossLink} rel="noopener noreferrer" target="_blank">
                <img alt={title} className="recommendation-details__image" src={image} />
              </a>
            </FlexColumn>
            <FlexColumn columns={12} columnsSmall={9}>
              <div className="recommendation-details__header">
                <a href={crossLink} rel="noopener noreferrer" target="_blank">
                  {header}
                </a>
              </div>
              <div className="recommendation-details__description">{description}</div>
            </FlexColumn>
          </FlexRow>
        </div>
      )}
    </div>
  );
};

export default Recommendation;
