import classNames from 'classnames';
import { ReactNode } from 'react';
import './Block.scss';

interface BlockProps {
  children?: ReactNode;
  className?: string;
  text?: string;
  title?: string;
}

const Block = ({ children, className, text, title }: BlockProps): JSX.Element => {
  return (
    <div className={classNames('block', className)}>
      {title && <div className="block__title">{title}</div>}
      {text && <div className="block__text">{text}</div>}
      {children}
    </div>
  );
};

export default Block;
