import classNames from 'classnames';
import { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from 'react';
import './TextInput.scss';

type TextInputHeightSizes = 'default' | 'large';
type TextInputType = 'text' | 'password';

interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  className?: string;
  defaultValue?: string;
  inputType?: TextInputType;
  heightSize?: TextInputHeightSizes;
  onChange?: (value: string) => void;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      defaultValue = '',
      inputType = 'text',
      heightSize = 'default',
      onChange = () => undefined,
      ...otherProps
    }: TextInputProps,
    ref
  ): JSX.Element => {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      onChange(value);
    };

    return (
      <input
        ref={ref}
        className={classNames(
          'text-input',
          { [`text-input--${heightSize}`]: heightSize !== 'default' },
          className
        )}
        type={inputType}
        value={value}
        onChange={handleChange}
        {...otherProps}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
