import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FlexColumn, FlexRow } from '../../../components/Flex';
import { MEDIA_QUERIES } from '../../../constants';
import {
  convertLoanTermToString,
  convertStringToCurrency,
  dateFormat,
  uniqueKey,
} from '../../../utils';
import { LenderDetails, LenderDetailsRow } from '../MobileView';
import { ColumnProps, HeaderProps, Tabular } from '../Tabular';
import './FundedLoans.scss';
import FundedLoan from './types/FundedLoan';

interface FundedLoansProps {
  fundedLoans: FundedLoan[];
}

const FundedLoans = ({ fundedLoans }: FundedLoansProps): JSX.Element => {
  const computeHeader = (): HeaderProps => {
    return {
      data: [
        { colsize: 2, text: 'Funded Date' },
        { colsize: 3, text: 'Loan ID' },
        { colsize: 2, text: 'Loan Amount' },
        { colsize: 2, text: 'Loan Term' },
        { colsize: 3, text: 'Lender' },
      ],
    };
  };

  const formattedHeaderData = useMemo(() => computeHeader(), []);

  const formattedData: ColumnProps[] = fundedLoans.map((fundedLoan) => {
    return {
      data: [
        {
          colsize: 2,
          inputType: 'text',
          text: dateFormat({ date: fundedLoan?.dateFunded, includeTime: false }),
        },
        {
          colsize: 3,
          inputType: 'text',
          opportunityId: fundedLoan.opportunityId,
          text: fundedLoan.loanNumber,
        },
        { colsize: 2, inputType: 'text', text: convertStringToCurrency(fundedLoan.loanAmount) },
        {
          colsize: 2,
          inputType: 'text',
          text: convertLoanTermToString({ term: fundedLoan.loanTerm }),
        },
        { colsize: 3, inputType: 'text', text: fundedLoan.lender },
      ],
    };
  });

  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });

  const fundedLoansTabular = (
    <Tabular columnData={formattedData} headerData={formattedHeaderData} />
  );

  const fundedLoansMobile = fundedLoans.map((fundedLoan, index) => (
    <LenderDetails key={index} lenderName={fundedLoan.lender}>
      <LenderDetailsRow
        key={uniqueKey.generateKey('Funded_Date')}
        name="Funded Date"
        opportunityId=""
        value={dateFormat({ date: fundedLoan?.dateFunded, includeTime: false })}
      />
      <LenderDetailsRow
        key={uniqueKey.generateKey('Loan_ID')}
        name="Loan ID"
        opportunityId={fundedLoan.opportunityId}
        value={fundedLoan.loanNumber}
      />
      <LenderDetailsRow
        key={uniqueKey.generateKey('Loan_Amount')}
        name="Loan Amount"
        opportunityId=""
        value={convertStringToCurrency(fundedLoan.loanAmount)}
      />
      <LenderDetailsRow
        key={uniqueKey.generateKey('Loan_Term')}
        name="Loan Term"
        opportunityId=""
        value={convertLoanTermToString({ term: fundedLoan.loanTerm })}
      />
    </LenderDetails>
  ));

  return (
    <>
      {fundedLoans.length > 0 && (
        <div className="funded-loans">
          <FlexRow>
            <FlexColumn columns={12}>
              <h3 className="funded-loans__title">Your Funded Loans</h3>
            </FlexColumn>
          </FlexRow>
          {!isSmallDown && (
            <FlexRow>
              <FlexColumn className="funded-loans__table mb-3" columns={12}>
                {fundedLoansTabular}
              </FlexColumn>
            </FlexRow>
          )}
          {isSmallDown && (
            <FlexRow>
              <FlexColumn className="funded-loans__mobile mb-3" columns={12}>
                {fundedLoansMobile}
              </FlexColumn>
            </FlexRow>
          )}
        </div>
      )}
    </>
  );
};

export default FundedLoans;
