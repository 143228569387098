import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '../../../app/hooks';
import { useGetFundingRequestByOpportunityIdQuery } from '../../../app/services/dashboard';
import UserAgent from '../../../features/UserAgent/types/UserAgent';
import { selectSnapshot } from '../../../slices/snapshotSlice';
import OfferAgent from '../OfferAgent/OfferAgent';
import './FundingRequests.scss';
import Status from './Status/Status';
import Summary from './Summary/Summary';
import FundingRequest from './types/FundingRequest';

interface FundingRequestsProps {
  fundingRequest: FundingRequest;
  hasExchangeRequests: boolean;
  minUploadDocumentCount?: number;
  userAgent?: UserAgent;
  onScheduleACallModal: () => void;
}
const FundingRequests = ({
  fundingRequest,
  hasExchangeRequests,
  minUploadDocumentCount,
  userAgent,
  onScheduleACallModal,
}: FundingRequestsProps): JSX.Element => {
  const snapshot = useAppSelector(selectSnapshot);

  const {
    isFetching,
    isSuccess,
    data: updatedFundingRequest,
  } = useGetFundingRequestByOpportunityIdQuery(fundingRequest?.opportunityId ?? skipToken);

  const getRemainingBankStatements = () => {
    if (minUploadDocumentCount && updatedFundingRequest) {
      const diff = minUploadDocumentCount - updatedFundingRequest?.documentsCount;

      if (diff > 0) {
        return diff;
      }
    }

    return minUploadDocumentCount;
  };

  return (
    <>
      <div className="funding-request">
        {hasExchangeRequests && <OfferAgent requestType="CONCIERGE" userAgent={userAgent} />}
        {!isFetching && (
          <div className="funding-request__block">
            <Summary
              applicationId={fundingRequest?.applicationId}
              applicationUrl={snapshot?.splitterUrl}
              calendlyC={userAgent?.calendlyC}
              hasExchangeRequests={hasExchangeRequests}
              loanNumber={fundingRequest?.fundedLoans?.[0]?.loanNumber}
              minUploadDocumentCount={
                isSuccess && updatedFundingRequest?.status === 'Upload Documents'
                  ? getRemainingBankStatements()
                  : minUploadDocumentCount
              }
              opportunityId={fundingRequest?.opportunityId}
              status={isSuccess ? updatedFundingRequest?.status : fundingRequest.status}
              stipsCount={isSuccess ? updatedFundingRequest?.stipsCount : fundingRequest.stipsCount}
              onScheduleACallModal={() => onScheduleACallModal()}
            />
            <Status
              fundingRequest={fundingRequest}
              hasExchangeRequests={hasExchangeRequests}
              status={
                isSuccess
                  ? updatedFundingRequest?.status ?? fundingRequest.status
                  : fundingRequest.status
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FundingRequests;
