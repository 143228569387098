import { FlexColumn, FlexRow } from '../../../../src/components/Flex';
import InCompleteApplicationModal from '../../../../src/features/Dashboard/InCompleteApplicationModal';
import OpenOpportunityModal from '../../../../src/features/Dashboard/OpenOpportunityModal/OpenOpportunityModal';
import { useHandleFundingCTA } from '../../../../src/hooks';
import './NewFunding.scss';

const NewFunding = (): JSX.Element => {
  const [
    check,
    handleInCompleteApplicationModalClose,
    showInCompleteApplicationModal,
    setShowOpenOpportunityModal,
    showOpenOpportunityModal,
  ] = useHandleFundingCTA();

  return (
    <>
      <div className="new-funding">
        <FlexRow className="new-funding__request-box">
          <FlexColumn columnsLarge={8} columnsMedium={8}>
            <div className="new-funding__title-name">Get your funding faster with LendingTree.</div>
            <p className="new-funding__text">
              Because you&#39;ve applied with us before, you can get your funds quicker without
              having to start the funding process from scratch.
            </p>
          </FlexColumn>
          <FlexColumn columnsLarge={3} columnsMedium={4}>
            <OpenOpportunityModal
              show={showOpenOpportunityModal}
              onClose={() => setShowOpenOpportunityModal(false)}
            />
            <InCompleteApplicationModal
              show={showInCompleteApplicationModal}
              onClose={handleInCompleteApplicationModalClose}
            ></InCompleteApplicationModal>
            <div className="new-funding__cta-block">
              <a
                className="new-funding__action-cta"
                rel="noopener noreferrer"
                target="_blank"
                onClick={check}
              >
                Start a New Request
              </a>
            </div>
          </FlexColumn>
        </FlexRow>
      </div>
    </>
  );
};

export default NewFunding;
