import { useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectSnapshot } from '../slices/snapshotSlice';

export default function useHandleFundingCTA(): [
  () => void,
  () => void,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean
] {
  const snapshot = useAppSelector(selectSnapshot);
  const [showInCompleteApplicationModal, setShowInCompleteApplicationModal] = useState(false);
  const [showOpenOpportunityModal, setShowOpenOpportunityModal] = useState(false);

  const handleInCompleteApplicationModalClose = () => {
    setShowInCompleteApplicationModal(false);
  };

  const check = () => {
    if (snapshot.anyOpenFundingRequests && snapshot.anyInCompleteFundingRequests) {
      setShowInCompleteApplicationModal(true);
    } else if (snapshot.anyOpenFundingRequests) {
      setShowOpenOpportunityModal(true);
    } else {
      window.location.href = snapshot.splitterUrl;
    }
  };

  return [
    check,
    handleInCompleteApplicationModalClose,
    showInCompleteApplicationModal,
    setShowOpenOpportunityModal,
    showOpenOpportunityModal,
  ];
}
