import Article from '../../features/Resources/Articles/Article';
import { borrowerPortalApi } from './borrowerPortalBase';

export const insuranceApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<Article[], void>({
      query: () => 'resources/insurance/articles',
    }),
    transmit: builder.query<void, void>({
      query: () => 'transmit',
    }),
  }),
  overrideExisting: false,
});

export const { useGetArticlesQuery, useTransmitQuery } = insuranceApi;
