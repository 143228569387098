import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { insuranceApi } from '../../../app/services/insurance';
import { RootState } from '../../../app/store';
import Article from '../Articles/Article';

const articlesAdapter = createEntityAdapter<Article>();
export type ArticleState = EntityState<Article>;

const reducers = {
  setArticles: (state: ArticleState, action: PayloadAction<Article[]>) => {
    articlesAdapter.setAll(state, action.payload);
  },
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState: articlesAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(insuranceApi.endpoints.getArticles.matchFulfilled, reducers.setArticles);
  },
});

export default articlesSlice.reducer;

// Actions
export const { setArticles } = articlesSlice.actions;

// Selectors
const articlesSelectors = articlesAdapter.getSelectors<RootState>(
  (state) => state.insurance.articles
);

export const selectFeaturedArticle = (state: RootState, id: string): Article =>
  articlesSelectors.selectById(state, id) as Article;

export const selectOtherArticle = (state: RootState, featuredArticleId: string): Article[] =>
  articlesSelectors.selectAll(state).filter((item) => {
    return item.id !== featuredArticleId;
  });
