import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LtIcon } from '../../../../components/Icon';
import { Tooltip } from '../../../../components/Tooltip';
import { MEDIA_QUERIES } from '../../../../constants';
import { dateFormat } from '../../../../utils';
import CirclularArrows from './CircularArrows';

type EligibilityMarkerProps = {
  eligibleDate?: Date;
  marker: number;
  show?: boolean;
};

const tooltipContent = `Your eligibility is dependent on you not having any missed or modified payments. Please contact your Concierge to confirm whether you're eligible for additional funds.`;

export default function EligibilityMarker({
  eligibleDate,
  marker,
  show,
}: EligibilityMarkerProps): JSX.Element {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const [eligibilityTextPosition, setEligibilityTextPosition] = useState({});

  useEffect(() => {
    // Shift eligibility text so it is not displayed outside the funding request container
    if (marker > 96) {
      setEligibilityTextPosition({ marginRight: '105px' });
    }

    if (marker < 3) {
      setEligibilityTextPosition({ marginRight: '-45px' });
    }
  }, [marker]);

  return (
    <>
      {show && (
        <>
          {isSmallDown ? (
            <>
              <div className="renewals__status--em-icon-container">
                <span className="renewals__status--em-icon-label">
                  Eligible for Additional Funds&nbsp;
                  <Tooltip content={tooltipContent} offset={[60, 10]}>
                    <span className="renewals__status--em-info-icon">
                      <LtIcon name="Info-Icon" />
                    </span>
                  </Tooltip>
                </span>
              </div>
              <div
                className="renewals__status--em-icon"
                style={{ transform: `translateX(${marker - 50}%)` }}
              >
                <Tooltip
                  content={dateFormat({ date: eligibleDate, includeTime: false })}
                  offset={[0, 10]}
                  placement={'bottom'}
                >
                  <span>
                    <CirclularArrows />
                  </span>
                </Tooltip>
              </div>
            </>
          ) : (
            <div
              className="renewals__status--em-icon"
              style={{ transform: `translateX(${marker - 50}%)` }}
            >
              <Tooltip content={tooltipContent}>
                <span className="renewals__status--em-info-icon" style={eligibilityTextPosition}>
                  <LtIcon name="Info-Icon" />
                </span>
              </Tooltip>
              <span className="renewals__status--em-icon-label" style={eligibilityTextPosition}>
                Eligible for Additional Funds
              </span>
              <Tooltip
                content={dateFormat({ date: eligibleDate, includeTime: false })}
                offset={[0, 10]}
                placement={'bottom'}
              >
                <span>
                  <CirclularArrows />
                </span>
              </Tooltip>
            </div>
          )}
        </>
      )}
      {!show && <div className="renewals__status--em-container" />}
    </>
  );
}
