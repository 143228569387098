import { ReactNode } from 'react';
import './LenderDetails.scss';

interface LenderDetailsProps {
  children: ReactNode;
  lenderName?: string;
}

const LenderDetails = ({ children, lenderName }: LenderDetailsProps): JSX.Element => {
  return (
    <div className="lender-details">
      {lenderName != '' && <div className="lender-details__name">{lenderName}</div>}
      {children}
    </div>
  );
};

export default LenderDetails;
