import BusinessCheckingAccountsImage from './images/business-checking-accounts.png';
import CompareCreditcardsImage from './images/compare-creditcards.png';
import './Lendability.scss';
import Recommendation from './Recommendation';

const ImproveLendability = (): JSX.Element => {
  const recommendations = [
    {
      title: 'Get your business federal tax ID',
      content: `A business tax ID, or Employer Identification Number (EIN), is a nine-digit number that identifies your business for tax reasons.
      You need an EIN to open business bank accounts, get business loans, apply for licenses and file your tax returns.`,
    },
    {
      title: 'Establish an official business address',
      content: `A business address helps give your business credibility. It may also make it easier to work with banks and merchant services, which typically don't accept residential or PO Box addresses as business addresses.`,
    },
    {
      title: 'Open a business banking account',
      content: `Pay your company's bills and accept payments with a business banking account that's separate from your personal checking or savings accounts. That will help lenders and vendors feel like your business is more established.`,
      image: BusinessCheckingAccountsImage,
      crossLink:
        'https://www.depositaccounts.com/ratetable/checking/business-checking-accounts.html',
      header: 'Compare the Best Business Checking Accounts',
      description: `Compare business checking account offers and select the one that aligns with your company's digital needs.`,
    },
    {
      title: 'Get a business phone number',
      content: `A business phone number that's separate from your personal one shows lenders that your business operates as its own entity.`,
    },
    {
      title: 'Take out a business credit card',
      content: `Lenders want to know that your business has its own line of credit. Getting a business credit card (in your business's name) will help you establish a business credit history, which influences your eligibility for loans.`,
      image: CompareCreditcardsImage,
      crossLink:
        'https://creditcards.lendingtree.com/business?source=blsignedin&campaign=lendabilitychecklist',
      header: 'Compare the Best Business Credit Cards',
      description: `Need a business credit card? Check out the best business credit cards sorted by rewards and benefits. Get a business credit card today.`,
    },
    {
      title: 'Register your business with your Secretary of State',
      content: `Registering with your Secretary of State proves that your business is a distinct, legal entity. This shows lenders that any money you borrow is for the business, not personal use.`,
    },
    {
      title: 'Create a company website',
      content: `Like your phone number and address, a website for your business shows that your company is a distinct, fully-operational entity that's legitimate.`,
    },
    {
      title: 'See if you have a business credit score',
      content: `Discover whether you have a business credit score with Equifax, Experian or Dun & Bradstreet, the three main business credit reporting bureaus. Your business credit score measures your creditworthiness and helps you qualify for loans, lines of credit and credit cards.`,
    },
    {
      title: 'Manage your personal credit score',
      content: `Many lenders consider your personal credit score as one of the data points when reviewing a business loan application. In order to improve the odds of an approval, it is important for business owners to be aware of, improve, and/or maintain their personal credit score.`,
    },
  ];
  return (
    <div className="lendability">
      <div className="lendability__header">
        <h5 className="lendability__header--title">Boost Your Lendability</h5>
        <p className="lendability__header--text">
          Follow these recommendations to improve your chances of getting a business loan and your
          business credit score.
        </p>
      </div>
      <div className="lendability__content">
        <Recommendation recommendations={recommendations} />
      </div>
    </div>
  );
};

export default ImproveLendability;
