import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERIES } from '../../constants';
import { IconNames } from '../Icon';
import './NavbarDropdown.scss';
import NavbarItem from './NavbarItem';

interface NavbarAccountItemProps {
  children: ReactNode;
  icon: IconNames;
  isActive: boolean;
  forceClose: boolean;
}

const NavbarDropdown = ({
  children,
  icon,
  isActive,
  forceClose,
}: NavbarAccountItemProps): JSX.Element => {
  const [showDropdown, toggleDropdown] = useState(false);
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });
  const dropdDownMenuRef = useRef<HTMLUListElement>(null);

  const outerClick = useCallback(
    (event: Event) => {
      if (!dropdDownMenuRef?.current?.contains((event?.target as Node) || null)) {
        showDropdown && toggleDropdown(false);
      }
    },
    [showDropdown, toggleDropdown]
  );

  useEffect(() => {
    document.addEventListener('mousedown', outerClick);
    document.addEventListener('touchstart', outerClick);

    return () => {
      document.removeEventListener('mousedown', outerClick);
      document.removeEventListener('touchstart', outerClick);
    };
  }, [outerClick]);

  useEffect(() => {
    if (forceClose) toggleDropdown(false);
  }, [forceClose, toggleDropdown]);

  const handleToggleDropdown = () => {
    toggleDropdown(!showDropdown);
  };

  return (
    <NavbarItem
      className="navbar-dropdown"
      icon={icon}
      isActive={isActive}
      linkClassName="navbar-dropdown__navbar-link"
      name={'My Account'}
      onClick={handleToggleDropdown}
    >
      <ul
        ref={dropdDownMenuRef}
        className={classNames('navbar-dropdown__menu', {
          'navbar-dropdown__menu--show': showDropdown,
        })}
      >
        {isSmallDown && (
          <NavbarItem
            icon={'ChevronLeft'}
            linkClassName={'navbar-dropdown__navbar-link--close'}
            name={'Main Menu'}
            onClick={handleToggleDropdown}
          />
        )}
        {children}
      </ul>
    </NavbarItem>
  );
};

export default NavbarDropdown;
