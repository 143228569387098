import { useEffect, useState } from 'react';
import GroveLtIcon from '../../components/Icon/GroveLtIcons';
import { useLTAnalytics } from '../../hooks/useLTAnalytics';
import CallOrTextModal from '../UserAgent/SalesRepInfo/CallOrTextModal';
import Photo from '../UserAgent/SalesRepInfo/Photo';

interface AgentDetailProps {
  agentName?: string;
  merchantFirstName?: string;
  businessName?: string;
  loanRequestAmount?: string;
  hasRequiredDocuments?: boolean;
  uploadDocumentUrl?: string;
  calendlyUrl?: string;
  agentPhotoAsBase64String?: string;
  agentEmail?: string;
  agentPhoneNumber?: string;
}

const AgentDetails = ({
  agentName,
  merchantFirstName,
  businessName,
  agentEmail,
  agentPhoneNumber,
  agentPhotoAsBase64String,
  calendlyUrl,
}: AgentDetailProps): JSX.Element => {
  const phoneAnalyticsCall = useLTAnalytics('Phone', 'CTA Clicked');
  const emailAnalyticsCall = useLTAnalytics('Email', 'CTA Clicked');
  const scheduleAnalyticsCall = useLTAnalytics('ScheduleCall', 'CTA Clicked');
  const [showCallOrTextModal, setShowCallOrTextModal] = useState(false);

  useEffect(() => {
    if (agentName && agentName.indexOf('SnapCap Concierge') > -1) {
      window.ltanalytics.track('Default Agent Displayed', { bl_cta_text: 'DefaultAgent' });
    }
  }, [agentName]);

  const getTrimmedBusinessName = (name: string | undefined) => {
    if (!name) {
      return 'your business';
    }
    return name?.replace(/\.$/, '');
  };

  return (
    <div className="funding-expert">
      <div className="funding-expert__header">Your Funding Expert</div>
      <div className="funding-expert__subheader">
        <div className="funding-expert__agent-image">
          <Photo profilePhotoAsBase64String={agentPhotoAsBase64String} />
        </div>
        <div className="funding-expert__agent-details">
          <div className="funding-expert__agent-name">{agentName}</div>
          <div
            className="funding-expert__agent-phone"
            onClick={() => setShowCallOrTextModal(true)}
            {...phoneAnalyticsCall}
          >
            <span className="funding-expert__phone-icon">
              <GroveLtIcon name="MobilePhone" />
              <CallOrTextModal
                name={agentName}
                phone={agentPhoneNumber}
                photo={agentPhotoAsBase64String}
                show={showCallOrTextModal}
                onClickSetShowModal={(showModal) => setShowCallOrTextModal(showModal)}
              />
            </span>
            <div>{agentPhoneNumber}</div>
          </div>
          <div className="funding-expert__agent-hours-label">HOURS</div>
          <div className="funding-expert__agent-hours">
            <p>Mon - Fri,</p>
            <p>9AM - 6PM EST</p>
          </div>
        </div>
      </div>
      <div className="funding-expert__content">
        <p>
          {merchantFirstName}, thank you for the opportunity to find funding for{' '}
          {getTrimmedBusinessName(businessName)}. I&apos;m reviewing your information now.
        </p>
        <p>
          I&apos;ll give you a call within 24 business hours to better understand your
          company&apos;s needs and set a course for next steps!
        </p>
      </div>
      <div className="funding-expert__block">
        <a
          className="funding-expert__cta"
          href={`mailto:${agentEmail}`}
          onClick={() => setShowCallOrTextModal(false)}
          {...emailAnalyticsCall}
        >
          <GroveLtIcon name="Envelope" />
          Email
        </a>
        <a
          className="funding-expert__cta"
          href={calendlyUrl}
          rel="noreferrer"
          target="_blank"
          {...scheduleAnalyticsCall}
        >
          <GroveLtIcon name="Calendar" />
          Schedule Call
        </a>
      </div>
    </div>
  );
};

export default AgentDetails;
