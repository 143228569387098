import { useMediaQuery } from 'react-responsive';
import { LtIcon } from '../../../../components/Icon';
import { MEDIA_QUERIES } from '../../../../constants';
import './Tracker.scss';

interface TrackerProps {
  status?: string;
}

const Tracker = ({ status }: TrackerProps): JSX.Element => {
  const isXsDown = useMediaQuery({ query: MEDIA_QUERIES.xsDown });
  const statusOfRequest = status?.replace(/ /g, '').toLowerCase();

  const progressBarDisabledClass = () => {
    return statusOfRequest == 'requestnotfunded' ? 'tracker-block-disabled' : '';
  };

  const progressBarClass = () => {
    switch (statusOfRequest) {
      case 'completeprofile':
      case 'completeapplication':
        return 'custom-tracker__box--complete-profile';
      case 'uploaddocuments':
        return 'custom-tracker__box--upload-documents';
      case 'curatingoffers':
        return 'custom-tracker__box--curating-offers';
      case 'reviewoffers':
        return 'custom-tracker__box--review-offers';
      case 'funding':
        return 'custom-tracker__box--funding';
      case 'loanfunded':
        return 'custom-tracker__box--loanfunded';
      case 'requestnotfunded':
      default:
        return '';
    }
  };
  const stageActionClass = (): string => {
    switch (statusOfRequest) {
      case 'completeprofile':
      case 'completeapplication':
        return 'incomplete-alert__stage-first';
      case 'uploaddocuments':
        return 'incomplete-alert__stage-second';
      case 'curatingoffers':
        return 'incomplete-alert__stage-third';
      case 'reviewoffers':
        return 'incomplete-alert__stage-fourth';
      case 'funding':
        return 'incomplete-alert__stage-fifth';
      case 'loanfunded':
        return 'incomplete-alert__stage-six bl-lt-icon--Check';
      case 'requestnotfunded':
      default:
        return '';
    }
  };

  const incompleteAlertText = (): string => {
    return statusOfRequest !== 'loanfunded' ? '!' : '';
  };

  const showRequestNotFound = (): boolean => {
    return statusOfRequest === 'requestnotfunded' ? true : false;
  };

  const showLoanFunded = (): boolean => {
    return statusOfRequest === 'loanfunded' ? true : false;
  };

  const activeTextClass = (inputStatus: string): string => {
    if (
      inputStatus?.replace(' ', '').toLowerCase() == 'funding' &&
      statusOfRequest?.replace(' ', '').toLowerCase() == 'loanfunded'
    ) {
      return 'tracker-block__stage--text-active';
    } else {
      return statusOfRequest === inputStatus?.replace(' ', '').toLowerCase()
        ? 'tracker-block__stage--text-active'
        : '';
    }
  };
  return (
    <>
      <div className="tracker">
        {isXsDown && !showRequestNotFound() && (
          <div className="tracker__title">
            {showLoanFunded() ? <span className="tracker__title--label">{status}</span> : status}
          </div>
        )}
        <div className={`tracker-block ${progressBarDisabledClass()}`}>
          <div className="custom-tracker">
            <div className={`custom-tracker__box ${progressBarClass()}`}></div>
          </div>
          {!isXsDown && (
            <div className="tracker-block__stage">
              <div className={`tracker-block__stage--text ${activeTextClass('completeprofile')}`}>
                Complete {statusOfRequest === 'completeapplication' ? 'Application' : 'Profile'}
              </div>
              <div className={`tracker-block__stage--text ${activeTextClass('uploaddocuments')}`}>
                Upload Documents
              </div>
              <div className={`tracker-block__stage--text ${activeTextClass('curatingoffers')}`}>
                Curating Offers
              </div>
              <div className={`tracker-block__stage--text ${activeTextClass('reviewoffers')}`}>
                Review Offers
              </div>
              <div className={`tracker-block__stage--text ${activeTextClass('funding')}`}>
                Funding
              </div>
            </div>
          )}
          <div className="stage-divider">
            <LtIcon className="stage-divider__stage-one" name="ChevronThickRight" />
            <LtIcon className="stage-divider__stage-two" name="ChevronThickRight" />
            <LtIcon className="stage-divider__stage-three" name="ChevronThickRight" />
            <LtIcon className="stage-divider__stage-four" name="ChevronThickRight" />
          </div>
          <div className={`incomplete-alert ${stageActionClass()}`}>{incompleteAlertText()}</div>
        </div>
      </div>
    </>
  );
};

export default Tracker;
