import round from 'lodash/round';

/**
 * Calculate the distance, in terms of a percentage, of a number between two numbers in a range
 *
 * @param input - the number to calculate distance between the range
 * @param min - the min value in the range
 * @param max - the max value in the range
 *
 * @returns {number} - as a decimal percentage value, e.g. 0.2 = 20%
 * @throws {RangeError} - when input is out of range
 */

const calculatePercentInRange = (input: number, min: number, max: number): number => {
  if (!(input >= min) || !(input <= max))
    throw new RangeError(`input ${input} must be in range of ${min}-${max}`);

  if (min >= max) throw new RangeError(`min ${min} must be less than max ${max}`);

  return round(((input - min) * 100) / (max - min) / 100, 4);
};

export default calculatePercentInRange;
