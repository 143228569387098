import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { creditCardsApi } from '../../../app/services/creditCards';
import { RootState } from '../../../app/store';
import CreditCard from './types/CreditCard';

const creditCardsAdapter = createEntityAdapter<CreditCard>();
export type CreditCardState = EntityState<CreditCard>;

const reducers = {
  setCreditCards: (state: CreditCardState, action: PayloadAction<CreditCard[]>) => {
    creditCardsAdapter.setAll(state, action.payload);
  },
};

export const creditCardsSlice = createSlice({
  name: 'creditCards',
  initialState: creditCardsAdapter.getInitialState(),
  reducers,
  extraReducers: (builder) => {
    builder.addMatcher(creditCardsApi.endpoints.getCards.matchFulfilled, reducers.setCreditCards);
  },
});

export default creditCardsSlice.reducer;

// Actions
export const { setCreditCards } = creditCardsSlice.actions;

// Selectors
const creditCardsSelectors = creditCardsAdapter.getSelectors<RootState>(
  (state) => state.creditCards
);

export const selectCreditCards = (state: RootState): CreditCard[] =>
  creditCardsSelectors.selectAll(state);
