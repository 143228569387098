import { ReactNode, useEffect } from 'react';
import './ErrorMessage.scss';

interface ErrorMessageProps {
  children: ReactNode;
  scrollIntoView?: boolean;
}

const ErrorMessage = ({ children, scrollIntoView = false }: ErrorMessageProps): JSX.Element => {
  useEffect(() => {
    if (scrollIntoView) {
      const errorMessage = document.getElementsByClassName('error-message');
      errorMessage[0].scrollIntoView({ behavior: 'smooth' });
    }
  });

  return <span className="error-message">{children}</span>;
};

export default ErrorMessage;
