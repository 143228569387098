const MEDIA_QUERIES: { [key: string]: string } = {
  xsUp: '(min-width: 576px)',
  smallUp: '(min-width: 768px)',
  mediumUp: '(min-width: 992px)',
  largeUp: '(min-width: 1200px)',
  largeDown: '(max-width: 1199px)',
  mediumDown: '(max-width: 991px)',
  smallDown: '(max-width: 767px)',
  xsDown: '(max-width: 575px)',
};

export default MEDIA_QUERIES;
