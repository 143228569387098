import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LtIcon } from '../../../../components/Icon';
import { MEDIA_QUERIES } from '../../../../constants';
import { useLTAnalytics } from '../../../../hooks';
import { dateFormat } from '../../../../utils';
import Stip from '../../types/Stip';
import Document from '../../types/UploadedDocument';
import DeleteModal from '../DeleteModal/DeleteModal';
import './UploadedDocument.scss';

interface UploadedDocumentProps {
  applicationId?: string;
  externalVisitorId?: string;
  hasAbilityToUploadDocument?: boolean;
  uploadedDocument?: Document;
  isStipsRequired?: boolean;
  stips?: Stip[];
  onDocumentDeleteConfirm?: (documentId: string) => void;
}

const UploadedDocument = ({
  applicationId,
  hasAbilityToUploadDocument,
  uploadedDocument,
  isStipsRequired,
  stips,
  onDocumentDeleteConfirm,
}: UploadedDocumentProps): JSX.Element => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });

  const analyticsDeleteFile = useLTAnalytics('Delete File');

  const getDocumentType = () => {
    // Documents uploaded via Phoenix form have type == "BankStatement",
    // we need to display this document type with a space as "Bank Statement"
    if (
      uploadedDocument?.documentType &&
      uploadedDocument?.documentType.replace(' ', '').toUpperCase() === 'BANKSTATEMENT'
    )
      return 'Bank Statement';

    return uploadedDocument?.documentType;
  };

  const handleOnConfirmDeleteDocument = (docId: string, isDocumentDelete: boolean) => {
    if (isDocumentDelete) {
      onDocumentDeleteConfirm?.(docId);
    }
    setShowDeleteModal(isDocumentDelete);
  };

  return (
    <>
      {uploadedDocument != null && (
        <tr key={uploadedDocument?.documentId} className="upload-document__tbody-tr">
          <td className="upload-document__td">
            <LtIcon name="Check" />
            {getDocumentType()}
            {isSmallDown && hasAbilityToUploadDocument && (
              <span
                className="upload-document__td-delete-icon"
                onClick={() => setShowDeleteModal(true)}
                {...analyticsDeleteFile}
              ></span>
            )}
          </td>
          <td className="upload-document__td">{uploadedDocument?.documentName}</td>
          <td className="upload-document__td">
            {!isSmallDown ? (
              <span>
                {dateFormat({ date: uploadedDocument?.uploadedDateTime, includeTime: true })}
              </span>
            ) : (
              <span>
                Uploaded on{' '}
                {dateFormat({ date: uploadedDocument?.uploadedDateTime, includeTime: false })}
              </span>
            )}
          </td>
          <td className="upload-document__td">
            {!isSmallDown && hasAbilityToUploadDocument && (
              <span
                className="upload-document__td-delete-icon"
                onClick={() => setShowDeleteModal(true)}
                {...analyticsDeleteFile}
              ></span>
            )}
            <DeleteModal
              applicationId={applicationId ?? ''}
              documentId={uploadedDocument?.documentId ?? ''}
              externalDocumentName={uploadedDocument?.externalDocumentName ?? ''}
              show={showDeleteModal}
              stipId={
                isStipsRequired && stips
                  ? stips?.find((s) => s.name == uploadedDocument?.documentType)?.id
                  : undefined
              }
              onDeleteConfirm={(docId, isDocumentDelete) =>
                handleOnConfirmDeleteDocument(docId, isDocumentDelete)
              }
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default UploadedDocument;
