import { AnimatedDotsButton } from '../../components/Button';
import { Modal } from '../../components/Modal';
import './RedirectModal.scss';

interface RedirectModalProps {
  show: boolean;
  onClose: () => void;
}

const RedirectModal = ({ show, onClose }: RedirectModalProps): JSX.Element => {
  return (
    <>
      <Modal
        centerContent={true}
        closable={false}
        show={show}
        size="medium"
        title="Thank You!"
        onClose={onClose}
      >
        <div>
          We are submitting your application and notifying your sales representative. Once complete,
          you will be redirected to your Small Business dashboard.
        </div>
        <div className="redirect-modal__button">
          <AnimatedDotsButton disabled={true}>Redirecting</AnimatedDotsButton>
        </div>
      </Modal>
    </>
  );
};

export default RedirectModal;
