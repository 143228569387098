import { useMediaQuery } from 'react-responsive';
import { Container } from '../../../components/Container';
import { MEDIA_QUERIES } from '../../../constants';
import { useLTAnalytics } from '../../../hooks';
import CreditCardDisclosures from '../CreditCards/CreditCardDisclosures';
import './ResourcesMenu.scss';
import ResourcesMenuLink from './ResourcesMenuLink';
import ResourcesMenuMobile, { ResourcesMenuOptionType } from './ResourcesMenuMobile';

const ResourceFilter = (link: ResourcesMenuOptionType) => (
  <li key={link.label}>
    <ResourcesMenuLink iconName={link.icon} to={link.path} {...useLTAnalytics(link.label)}>
      {link.label}
    </ResourcesMenuLink>
  </li>
);

const ResourcesMenu = (): JSX.Element => {
  const isSmallDown = useMediaQuery({ query: MEDIA_QUERIES.smallDown });

  const menuLinks: ResourcesMenuOptionType[] = [
    { label: 'All Resources', icon: 'Mortgage', path: '/resources/all' },
    { label: 'Business Banking', icon: 'PillarBuilding', path: '/resources/banking' },
    { label: 'Business Credit Cards', icon: 'CreditCards', path: '/resources/credit-cards' },
    { label: 'Marketing', icon: 'Funnel', path: '/resources/marketing' },
  ];

  return (
    <>
      <div className="resources-menu">
        <Container>
          <h1>Business Resources</h1>
          <p>If you are looking for additional business solutions, we have you covered.</p>
          {isSmallDown && <ResourcesMenuMobile options={menuLinks} />}
        </Container>
      </div>
      <Container>
        <CreditCardDisclosures />
        {!isSmallDown && (
          <>
            <h2 className="resources-menu__title">Select a Resource</h2>
            <ul className="resources-menu__links">
              {menuLinks.map((link) => (
                <ResourceFilter key={link.label} {...link} />
              ))}
            </ul>
          </>
        )}
      </Container>
    </>
  );
};

export default ResourcesMenu;
