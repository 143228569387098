import SignupRequest from '../../features/Signup/types/SignupRequest';
import { SignupResponse } from '../../features/Signup/types/SignupResponse';
import { borrowerPortalApi } from './borrowerPortalBase';

export const signUpApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    createSignup: builder.mutation<SignupResponse, SignupRequest>({
      query: (body) => ({
        url: 'signup',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useCreateSignupMutation } = signUpApi;
